import React, { FC } from 'react';
import {
  MenuItem,
  Grid,
  Typography,
  Select,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { BiArrowToBottom } from 'react-icons/bi';
import { useParams, Link } from 'react-router-dom';



interface Props {
  days: number;
  setDays: any;
  loadWellnessChats: boolean;
  loadPHQ29Chats: boolean;

}

const FilterChartsReport: FC = ({ days, setDays, loadWellnessChats, loadPHQ29Chats }: Props): JSX.Element => {

  const { userId } = useParams();



  const handleChange = (event) => {
    setDays(event.target.value);
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Select
          className="btn-last-week"
          onChange={handleChange}
          value={days}
        >
          <MenuItem value={7}>
            <Typography align="right">LAST WEEK</Typography>
          </MenuItem>
          <MenuItem value={30}>
            <Typography>LAST MONTH</Typography>
          </MenuItem>
          <MenuItem value={90}>
            <Typography>LAST 3 MONTHS</Typography>
          </MenuItem>
          <MenuItem value={180}>
            <Typography>LAST 6 MONTHS</Typography>
          </MenuItem>
          <MenuItem value={270}>
            <Typography>LAST 9 MONTHS</Typography>
          </MenuItem>
          <MenuItem value={365}>
            <Typography>LAST 12 MONTHS</Typography>
          </MenuItem>
        </Select>
        {loadWellnessChats && loadPHQ29Chats ? <CircularProgress size={25}/> : (
          <Link to={`/qactual/admin/clinician/${userId}/report/${days}`} target='_blank'>
          <IconButton
            color="primary"
            aria-label="Download report pdf"
            component="span"

          >
            <BiArrowToBottom />
            </IconButton></Link>)}

      </Grid>

    </Grid>

  );
}

export default FilterChartsReport;