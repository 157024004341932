import React from 'react';
import {
  CssBaseline,
  Container,
  Grid,
  Typography,
  Button,
  Box,
} from '@material-ui/core';

import swal from 'sweetalert';

import Logo from '../../assets/images/logoTitleGreen.svg';
import { RouteComponentProps } from "react-router-dom";

import API from '../../services/api';

interface Props extends RouteComponentProps {}

interface State {
  email: string;
  submited: boolean;
}

class ComplaintEmail extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      email: props.match.params.email,
      submited: false,
    }
  }

  private submit = async () => {
    const {
      email,
    } = this.state;

    this.setState({ submited: true });

    const response = await API.get(`/email/complaint/${email}`);
    if (response.data.status === 'success') {
      swal("OK", "Registered successfully", "success");
    } else if (response.data.status === 'error') {
      swal("ERROR", "Failed to register", "error");
    }
  };

  render() {
    const {
      email,
      submited,
    } = this.state;

    return (
      <>
        <CssBaseline />
        <Container component="main" maxWidth="xs" style={{paddingBottom: 24}}>
          <img src={Logo} alt="Logo" className="logo-login" />
            <Box mt={6}>
              <Typography variant="h4" component="h4" gutterBottom>
                {email}
              </Typography>
            </Box>
            <Box mt={6}>
              <p>
                Please, confirm that you don't want to receive emails from the QActual systems anymore.
                <br></br><br></br>
                Please, note that this will cause an impact on the system funcionality.
              </p>
            </Box>
            <Box mt={6}>
              <Grid container justify="center">
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={submited}
                    size="large"
                    onClick={() => this.submit()}
                  >
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </Box>
        </Container>
      </>
    );
  }
};

export default ComplaintEmail;
