import React from "react";
import { RouteComponentProps } from "react-router-dom";
import swal from "sweetalert";
import {
  CssBaseline,
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import Table from "../../../Components/Table";
import { Edit, RemoveCircle, ArrowBack } from "@material-ui/icons";
import LocalDrawer from "../../../Components/QActualAdminLocalDrawer";
import AppBar from "../../../Components/AppBar";
import RemoveModal from "../../../Components/RemoveModal";
import * as FormApi from "../../../services/mobile/wellnessForm";
import * as QuestionApi from "../../../services/mobile/wellnessFormQuestionAnswers";

// CALL TO API
import { logout } from "../../../services/auth";
import API from "../../../services/api";
import { tr } from "date-fns/locale";

interface Props extends RouteComponentProps {}

interface State {
  isLoading: boolean;
  toolbarOpen: boolean;
  organizationId: string;
  formId: string;
  modalRemoveIsOpen: boolean;
  form: {
    formName: string;
    active: boolean;
    deletedAt?: string;
    createdAt: string;
    updatedAt: string;
  };
  questions: {
    id: string;
    questionTitle: string;
    questionType: string;
    defaultValue?: string;
    active: string;
    deletedAt?: string;
    createdAt: string;
    updatedAt: string;
  }[];
  questionsAnswers: {
    id: string;
    questionTitle: string;
    questionType: string;
    defaultValue?: string;
    active: string;
    deletedAt?: string;
    createdAt: string;
    updatedAt: string;
    WellnessCheckFormAnswers?: {
      id: string;
      answer: string;
      createdAt: string;
      updatedAt: string;
      User: {
        name: string;
      };
    };
  }[];
}

class Form extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      toolbarOpen:
        localStorage.getItem("toolbarOpen") === "true" ? true : false,
      organizationId: props.match.params.organizationId,
      formId: props.match.params.formId,
      isLoading: false,
      modalRemoveIsOpen: false,
      form: {
        formName: "",
        active: true,
        deletedAt: "",
        createdAt: "",
        updatedAt: "",
      },
      questions: [],
      questionsAnswers: [],
    };
  }

  componentDidMount() {
    this.getFormData();
    this.getFormQuestions();
    this.getQuestionsAnswers();
  }

  private getFormData = async () => {
    const response: any = await FormApi.getFormById(this.state.formId);
    const form = response.data;
    form.active = form.active ? "Yes" : "No";
    form.deletedAt = form.deletedAt ? form.deletedAt : "N/A";
    this.setState({ form });
  };

  private getFormQuestions = async () => {
    const { formId, organizationId } = this.state;
    const response: any = await FormApi.getFormById(formId);

    const questions = (response?.data?.questions || []).map(
      (question, index) => {
        return {
          ...question,
          index: index + 1,
          active: question.active ? "Yes" : "No",
          deletedAt: question.deletedAt ? question.deletedAt : "N/A",
          defaultValue: question.defaultValue ? question.defaultValue : "N/A",
          href: `/qactual/admin/organizations/${organizationId}/form/${formId}/question/${question.id}`,
        };
      }
    );
    this.setState({ questions });
  };

  private getQuestionsAnswers = async () => {
    const response = await QuestionApi.getQuestionsAnswers(this.state.formId);
    console.log(response);
    const questionsAnswers = response?.data || [];
    console.log(questionsAnswers);
    this.setState({ questionsAnswers });
  };

  private handleDrawerOpen = () => {
    localStorage.setItem("toolbarOpen", "true");
    this.setState({ toolbarOpen: true });
  };

  private handleDrawerClose = () => {
    localStorage.setItem("toolbarOpen", "false");
    this.setState({ toolbarOpen: false });
  };

  private handleOpenRemoveModal = () => {
    this.setState({ modalRemoveIsOpen: true });
  };

  private confirmRemove = async () => {
    const { formId, organizationId } = this.state;

    this.setState({ modalRemoveIsOpen: false, isLoading: true });

    try {
      const response = await FormApi.deleteForm(formId);
      if (response.status === 200) {
        this.setState({ isLoading: false });
        swal("OK", "Record deleted successfully", "success");
        this.props.history.push(
          `/qactual/admin/organizations/${organizationId}/form`
        );
      }
    } catch (error) {
      console.log(error);
      this.setState({ modalRemoveIsOpen: false, isLoading: false });
      swal("Error", "Error loading organization", "error");
    }
  };

  private dismissRemove = () => {
    this.setState({ modalRemoveIsOpen: false });
  };

  private handleEditClick = () => {
    const { organizationId, formId } = this.state;

    this.props.history.push(
      `/qactual/admin/organizations/${organizationId}/form/${formId}/edit`
    );
  };

  private handleBackClick = () => {
    this.props.history.push(
      `/qactual/admin/organizations/${this.state.organizationId}/form`
    );
  };

  render() {
    const { form, questions, questionsAnswers, organizationId, formId } =
      this.state;
    const answerColumns = [
      {
        name: "Question",
        key: "questionTitle",
        type: "string",
      },
      {
        name: "Answer",
        key: "answer",
        type: "string",
      },
      {
        name: "User",
        key: "name",
        type: "string",
      },
    ];
    const questionColumns = [
      {
        name: "#",
        key: "index",
        type: "link",
      },
      {
        name: "Title",
        key: "questionTitle",
        type: "string",
      },
      {
        name: "Question Type",
        key: "questionType",
        type: "string",
      },
      {
        name: "Default Answer",
        key: "defaultValue",
        type: "string",
      },
      {
        name: "Active",
        key: "active",
        type: "string",
      },
      {
        name: "Created At",
        key: "createdAt",
        type: "string",
      },
      {
        name: "Deactivated At",
        key: "deletedAt",
        type: "string",
      },
    ];

    return (
      <div className="root">
        <CssBaseline />
        <AppBar
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerOpen={this.handleDrawerOpen}
          breadcrumbs={[]}
        />
        <LocalDrawer
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerClose={this.handleDrawerClose}
          logout={logout}
        />
        <main className="content">
          <div className="toolbarIcon" />
          <Container maxWidth="lg" className="container">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div style={{ float: "left" }}>
                  <Typography variant="h4" display="block" gutterBottom>
                    {`Form - ${form.formName}`}
                  </Typography>
                </div>
                <ButtonGroup
                  color="primary"
                  aria-label="outlined primary button group"
                  style={{ float: "right" }}
                >
                  <Button onClick={() => this.handleBackClick()}>
                    <ArrowBack fontSize="small" />
                    &nbsp;Back
                  </Button>
                  <Button onClick={() => this.handleEditClick()}>
                    <Edit fontSize="small" />
                    &nbsp;Edit
                  </Button>
                  <Button onClick={() => this.handleOpenRemoveModal()}>
                    <RemoveCircle
                      fontSize="small"
                      style={{ color: "#900707" }}
                    />
                    &nbsp;Remove
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className="paper" elevation={3}>
                  <Typography variant="overline" display="block" gutterBottom>
                    Name
                  </Typography>
                  <Typography variant="h6" display="block" gutterBottom>
                    {form.formName}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth="lg" className="container">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className="paper" elevation={3}>
                  <Table
                    title="Questions - Max 5 Questions per Form"
                    columns={questionColumns}
                    data={questions}
                    disableButton={questions.length === 5}
                    addHref={`/qactual/admin/organizations/${organizationId}/form/${formId}/question/add`}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Container>

          <Container maxWidth="lg" className="container">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className="paper" elevation={3}>
                  <Table
                    title="Answers"
                    columns={answerColumns}
                    data={questionsAnswers}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </main>
        <RemoveModal
          modalRemoveIsOpen={this.state.modalRemoveIsOpen}
          handleModalRemoveClose={this.handleDrawerClose}
          confirmRemove={this.confirmRemove}
          dismissRemove={this.dismissRemove}
        />
      </div>
    );
  }
}

export default Form;
