import React from 'react';
import { RouteComponentProps } from "react-router-dom";
import {
  CssBaseline,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  ButtonGroup,
  Paper,
  CircularProgress,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import AppBar from '../../../Components/AppBar';
import LocalDrawer from '../../../Components/QActualAdminLocalDrawer';
import { Formik } from "formik"
import * as yup from 'yup';
import swal from 'sweetalert';
import SaveIcon from '@material-ui/icons/Save';

// CALL TO API
import { logout, createUser } from '../../../services/auth';

import * as SanitizeString from '../../../services/sanitize_strings';

require('yup-phone');

interface Props extends RouteComponentProps {}

interface State {
  isLoading: boolean;
  toolbarOpen: boolean;
  validationError: string;
  organizationId: string;
}

class AddUserOrganization extends React.Component<Props, State> {

  constructor(props) {
    super(props);

    this.state = {
      toolbarOpen: localStorage.getItem('toolbarOpen') === 'true' ? true : false,
      validationError: '',
      isLoading: false,
      organizationId: props.match.params.organizationId,
    }
  }

  componentDidMount () {
    this.setState({ isLoading: false });
  }

  handleDrawerOpen = () => {
    localStorage.setItem('toolbarOpen', 'true');
    this.setState({ toolbarOpen: true });
  };

  handleDrawerClose = () => {
    localStorage.setItem('toolbarOpen', 'false');
    this.setState({ toolbarOpen: false });
  };

  handleSubmit = async (values) => {
    const obj = {
      name: values.name,
      email: SanitizeString.email(values.username),
      phone: SanitizeString.phoneNumber(values.phone),
      organizationId: this.state.organizationId,
      group: 'org_manager'
    }

    this.setState({ isLoading: true });

    try {
      let user = await createUser(obj);
      if (user?.data?.status === 'success') {
        this.setState({ isLoading: false });
        swal('Success', 'Record saved', 'success');
        this.props.history.push(`/qactual/admin/organizations/${this.state.organizationId}`);
      }
    } catch (error) {
      //console.log(error?.response?.data?.message);
      swal('Error', `Something went wrong: ${error?.response?.data?.message}`, 'error');
      this.setState({ isLoading: false });
    }
  }

  private handleBackClick = (organizationId: String) => {
    this.props.history.push(`/qactual/admin/organizations/${organizationId}`);
  }

  render () {
    return (
      <div className='root'>
        <CssBaseline />
        <AppBar
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerOpen={this.handleDrawerOpen}
          breadcrumbs={[]}
        />
        <LocalDrawer
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerClose={this.handleDrawerClose}
          logout={logout}
        />
        <main className='content'>
          <div className='toolbarIcon' />
          <Container maxWidth="lg" className='container'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div style={{ float: 'left' }}>
                  <Typography variant="h4" display="block" gutterBottom>
                    Add user manager
                  </Typography>
                </div>
                <ButtonGroup
                  color="primary"
                  aria-label='outlined primary button group'
                  style={{float: 'right'}}
                >
                  <Button
                    onClick={() => this.handleBackClick(this.state.organizationId)}>
                    <ArrowBack fontSize="small" />
                    &nbsp;Back
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
            <Paper className="paper" style={{ paddingTop: 40 }}>
              <Formik initialValues={{
                name: '',
                username: '',
                phone: '',
              }}
                validationSchema={yup.object().shape({
                  name: yup.string().required("Must enter a name for the user"),
                  username: yup.string().required("Must enter an email for the user"),
                  phone: yup.string().phone().matches(/^\+[0-9]*$/, 'For phone numbers please add the "+" at the front followed by just numbers').required("Must enter a phone for the user"),
                })}
                onSubmit = {(values) => {
                  this.handleSubmit(values);
                }}
              >
              {({values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit}) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          id="name"
                          label="Name"
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          fullWidth
                          error={errors.name && touched.name}
                          helperText={touched.name && errors.name}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="username"
                          label="Email"
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.username}
                          fullWidth
                          error={errors.username && touched.username}
                          helperText={touched.username && errors.username}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="phone"
                          type="tel"
                          label="Phone"
                          variant="outlined"
                          placeholder="Example +12025550185"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone}
                          fullWidth
                          error={errors.phone && touched.phone}
                          helperText={(touched.phone && errors.phone) || `Use the international standard (Example +12025550185)`}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          style={{ float: 'right' }}
                          disabled={isSubmitting}
                          startIcon={this.state.isLoading ? <CircularProgress /> : <SaveIcon />}
                          size="large"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
              {this.state.validationError}
            </Paper>
          </Container>
        </main>
      </div>
    );
  }
}

export default AddUserOrganization;
