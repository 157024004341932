import { CircularProgress } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Bar, Line } from "react-chartjs-2";
import { getCountries } from "../../../../../services/appCenterData";

interface Props {
  dataStart: Date;
  dataEnd: Date;
  top: number;
  system: "all" | "android" | "iOS";
}

export default function CountriesChart({
  dataStart,
  dataEnd,
  top,
  system = "all",
}: Props): JSX.Element {
  const [dataCountries, setDataCountries] = useState<Object>();
  const [state, setState] = useState<Object>();
  const [loading, setLoading] = useState<boolean>();

  const getDataCoutries = async () => {
    let defineData = {};

    try {
      setLoading(true);
      const { data } = await getCountries({
        dataStart,
        dataEnd,
        top,
      });

      if (system === "all" || system === "android") {
        if (data?.data?.android) {
          data?.data.android.forEach((countries) => {
            if (countries.code !== "NONE") {
              defineData[countries.code] =
                countries.count + (defineData[countries.code] || 0);
            }
          });
        }
      }
      if (system === "all" || system === "iOS") {
        if (data?.data?.iOS) {
          data?.data.iOS.forEach((countries) => {
            console.log(countries.code);

            if (countries.code !== "NONE") {
              defineData[countries.code] =
                countries.count + (defineData[countries.code] || 0);
            }
          });
        }
      }

      setDataCountries(defineData);

      // setCoutries(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDataCoutries();
  }, [dataStart, dataEnd, top, system]);

  useEffect(() => {
    if (dataCountries) {
      setState({
        labels: Object.keys(dataCountries) || [],
        datasets: [
          {
            label: "Visits",
            backgroundColor: "#1FAECE",
            borderColor: "rgba(0,0,0,0.5)",
            borderWidth: 1,
            data: Object.values(dataCountries) || [],
          },
        ],
      });
    }
  }, [dataCountries]);

  return (
    <div style={{ width: "100%" }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <Bar
          data={state}
          options={{
            title: {
              display: false,
            },
            legend: {
              display: true,
              position: "right",
            },
          }}
        />
      )}
    </div>
  );
}
