import {
  Button,
  ButtonGroup,
  Container,
  CssBaseline,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AppBar from '../../../Components/AppBar';
import LocalDrawer from '../../../Components/QActualAdminLocalDrawer';
import { logout } from '../../../services/auth';
import Table from '../../../Components/Table';
import { getOrganization, getSeatBatches } from '../../../services/organization';

interface Props extends RouteComponentProps {}

interface State {
  isLoading: boolean;
  toolbarOpen: boolean;
  organizationId: string;
  organizationData: {};
  organizationSeatsBatches: {}[];
}

class OrganizationTokensBatches extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      toolbarOpen: localStorage.getItem('toolbarOpen') === 'true' ? true : false,
      isLoading: false,
      organizationId: props.match.params.organizationId,
      organizationData: {},
      organizationSeatsBatches: [],
    };
  }

  componentDidMount() {
    this.getOrganization();
    this.getSeatsBatches();
  }

  private getOrganization = async () => {
    const { organizationId } = this.state;

    try {
      const response = await getOrganization(organizationId);
      const organizationData = response.data.organization;

      this.setState({ organizationData });
    } catch (error) {
      console.log(error);
      swal('Error', 'Error loading organization', 'error');
    }
  };

  private getSeatsBatches = async () => {
    const { organizationId } = this.state;

    try {
      const response = await getSeatBatches(organizationId);
      const organizationSeatsBatches = response.data.list;

      const seatsBatchesData = organizationSeatsBatches.map((item) => {
        return { ...item, href: '', remainingSeats: item.numberOfTokens-item.seatsOccupied }
      });

      this.setState({ organizationSeatsBatches: seatsBatchesData });
    } catch (error) {
      console.log(error);
      swal('Error', 'Error loading organization', 'error');
    }
  };

  private handleDrawerOpen = () => {
    localStorage.setItem('toolbarOpen', 'true');
    this.setState({ toolbarOpen: true });
  };

  private handleDrawerClose = () => {
    localStorage.setItem('toolbarOpen', 'false');
    this.setState({ toolbarOpen: false });
  };

  private handleBackClick = () => {
    const {
      organizationId
    } = this.state;

    this.props.history.push(`/qactual/admin/organizations/${organizationId}`);
  }

  render () {
    const {
      organizationId,
      organizationData,
      organizationSeatsBatches,
    } = this.state;

    const columns = [
      {
        name: 'Ammount',
        key: 'numberOfTokens',
        type: 'string',
      },
      {
        name: 'Expiring Date',
        key: 'expiringDate',
        type: 'string',
      },
      {
        name: 'Seats Occupied',
        key: 'seatsOccupied',
        type: 'string',
      },
      {
        name: 'Remaining Seats',
        key: 'remainingSeats',
        type: 'string',
      },
    ];

    return (
      <div className='root'>
        <CssBaseline />
        <AppBar
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerOpen={this.handleDrawerOpen}
          breadcrumbs={[]}
        />
        <LocalDrawer
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerClose={this.handleDrawerClose}
          logout={logout}
        />
        <main className='content'>
          <div className='toolbarIcon' />
          <Container maxWidth="lg" className='container'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div
                  style={{
                    float: 'left',
                  }}
                >
                  <Typography variant="h4" display="block" gutterBottom>
                    {`Seat Batches for ${organizationData.name}`}
                  </Typography>
                </div>
                <ButtonGroup
                  color="primary"
                  aria-label='outlined primary button group'
                  style={{float: 'right'}}
                >
                  <Button
                    onClick={() => this.handleBackClick()}>
                    <ArrowBack fontSize="small" />
                    &nbsp;Back
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
            <Paper className='paper' elevation={3}>
              <Table
                title='Batches'
                columns={columns}
                data={organizationSeatsBatches}
                addHref={`/qactual/admin/organizations/${organizationId}/addSeatsBatch`}
              />
            </Paper>
          </Container>
        </main>
      </div>
    );
  }
}

export default OrganizationTokensBatches;
