import React, { useEffect, useState } from 'react';


import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image, PDFViewer } from '@react-pdf/renderer'

import logo from '../../assets/images/logoTitleGreen.png';

import { getWellnesschecks, getPHQ29s } from '../../services/careProvider';

import { useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { getAuthUser, getUser } from "../../services/auth";
import swal from 'sweetalert';


const styles = StyleSheet.create({
    preview: {
        width: '100%',

        height: '90vh'
    },
    btnDownload: {
        width: '100%',
        textAlign: 'center',
        border: '1px solid #717171',
        fontSize: 20,
        padding: 10,
    },
    page: {
        padding: 20,
    },
    header: {
        display: 'flex',
        flexDirection: "row",
        marginBottom: 5,
    },
    boxLogo: {
        flex: 0.2
    },
    boxPatientName: {
        flex: 0.8,
    },
    nameText: {
        fontFamily: "Courier",
        fontSize: 15,
        fontWeight: 'bold',
    },
    nameText2: {
        fontFamily: "Courier",
        fontSize: 12,
        fontWeight: 'bold',
    },
    logoTop: {
        width: 100,
        border: '1px solid #000',
    },
    textWeek: {
        fontFamily: "Courier",
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 10,
    },
    lineTitle: {
        height: 1,
        backgroundColor: '#000',
        marginBottom: 10,
        marginTop: 5
    },
    section: {
        display: 'flex',
        flexDirection: 'row',
    },
    labelColumn: {
        flex: 0.2,
    },
    table: { display: "table", width: "auto", borderRightWidth: 0, borderBottomWidth: 0, flex: 0.8 },
    tableRow: { margin: "auto", flexDirection: "row" },
    tableCol: { width: "20%" },
    tableColHeader: { width: "20%", marginTop: 5 },
    tableColHeaderPhq: { width: "8%", marginTop: 5 },
    tableCell: { margin: "auto", marginTop: 5, fontSize: 10, fontWeight: 'bold', marginBottom: 5 },
    tableCellPHPHeader: { margin: "auto", fontSize: 8, fontWeight: 'bold', transformOrigin: '0 0', transform: 'rotate(320deg)', textAlign: 'center', marginTop: 10,},
    tableColNoData: { width: "20%", backgroundColor: '#717171', borderStyle: 'solid', borderWidth: 1, borderColor: '#d7d7d7' },
    tableColLevelZero: { width: "20%", backgroundColor: '#FFFFFF', borderStyle: 'solid', borderWidth: 1, borderColor: '#d7d7d7' },
    tableColLevelPHQ: { width: "8%", backgroundColor: '#FFFFFF', borderStyle: 'solid', borderWidth: 1, borderColor: '#d7d7d7' },
    tableColSum: { width: "10%", backgroundColor: '#FFFFFF', borderStyle: 'solid', borderWidth: 1, borderColor: '#d7d7d7', textAlign: 'center', fontSize: 10, paddingVertical: 2 },
    tableColLevelOne: { width: "20%", backgroundColor: '#FFFF00', borderStyle: 'solid', borderWidth: 1, borderColor: '#d7d7d7' },
    tableColLevelTwo: { width: "20%", backgroundColor: '#FFCC02', borderStyle: 'solid', borderWidth: 1, borderColor: '#d7d7d7' },
    tableColLevelThree: { width: "20%", backgroundColor: '#FF6C01', borderStyle: 'solid', borderWidth: 1, borderColor: '#d7d7d7' },
    tableColLevelFour: { width: "20%", backgroundColor: '#f84100', borderStyle: 'solid', borderWidth: 1, borderColor: '#d7d7d7' },
    tableColLevelFive: { width: "20%", backgroundColor: '#DC0000', borderStyle: 'solid', borderWidth: 1, borderColor: '#d7d7d7' },
    textLabels: {
        fontSize: 10,
        marginLeft: 2,
    },
    noDataLabel: {
        backgroundColor: '#717171',
        width: 10,
        height: 10,
        padding: '10px 10px 10px 10px',
        borderStyle: 'solid', borderWidth: 1, borderColor: '#d7d7d7',
    },
    levelZeroLabel: {
        backgroundColor: '#FFFFFF',
        width: 10,
        height: 10,
        padding: '10px 10px',
        borderStyle: 'solid', borderWidth: 1, borderColor: '#d7d7d7',
    },
    levelOneLabel: {
        backgroundColor: '#FFFF00',
        width: 10,
        height: 10,
        padding: '10px 10px',
        borderStyle: 'solid', borderWidth: 1, borderColor: '#d7d7d7',
    },
    levelTwoLabel: {
        backgroundColor: '#FFCC02',
        width: 10,
        height: 10,
        padding: '10px 10px',
        borderStyle: 'solid', borderWidth: 1, borderColor: '#d7d7d7',
    },
    levelThreeLabel: {
        backgroundColor: '#FF6C01',
        width: 10,
        height: 10,
        padding: '10px 10px',
        borderStyle: 'solid', borderWidth: 1, borderColor: '#d7d7d7',
    },
    levelFourLabel: {
        backgroundColor: '#f84100',
        width: 10,
        height: 10,
        padding: '10px 10px',
        borderStyle: 'solid', borderWidth: 1, borderColor: '#d7d7d7',
    },
    levelFiveLabel: {
        backgroundColor: '#DC0000',
        width: 10,
        height: 10,
        padding: '10px 10px',
        borderStyle: 'solid', borderWidth: 1, borderColor: '#d7d7d7',
    },
    boxLabel: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 5,
    },
    textPaginate: {
        fontSize: 10,
        textAlign: 'center',
        position: 'absolute',
        bottom: 3,
        color: '#bcbaba'
    }
});

interface Props {
    wellnesschecks: any;
    phq29s: any;
    numberDays?: string;
    careProvider: {
        id: '',
        name: '',
        username: '',
    };
}

const MyDoc = ({ wellnesschecks, phq29s, numberDays, careProvider }: Props) => {

    const getLevelColorWellnesscheck = (level: number): string => {


        const avgLevel = parseFloat(level);

        if (avgLevel <= 0) {
            return '#FFFFFF'
        }

        if (avgLevel > 0 && avgLevel <= 1) {
            return '#FFFF00'
        }

        if (avgLevel > 1 && avgLevel <= 2) {
            return '#FFCC02'
        }
        if (avgLevel > 2 && avgLevel <= 3) {
            return '#FF6C01'
        }
        if (avgLevel > 3 && avgLevel <= 4) {
            return '#f84100'
        }
        if (avgLevel > 4 && avgLevel <= 5) {
            return '#DC0000'
        }
        return '#717171'
    }
    const getLevelColorPhq = (level: number): string => {

        const avgLevel = parseFloat(level);

        if (avgLevel <= 0) {
            return '#FFFFFF'
        }

        if (avgLevel > 0 && avgLevel <= 1) {
            return '#FFFF00'
        }

        if (avgLevel > 1 && avgLevel <= 2) {
            return '#FF6C01'
        }
        if (avgLevel > 2 && avgLevel <= 3) {
            return '#DC0000'
        }
        return '#717171'

    }
    return (

        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.header} fixed>
                    <View style={styles.boxLogo}>
                        <Image src={logo} style={styles.logoTop} />
                    </View>
                    <View style={styles.boxPatientName}>
                        <Text style={styles.nameText}>AVERAGE OF PATIENTS</Text>
                        <Text style={styles.nameText2}>Care Provider: {careProvider?.name}</Text>
                        <Text style={styles.nameText2}>Email: {careProvider?.username}</Text>
                    </View>
                </View>
                <Text style={styles.textWeek}>{numberDays}</Text>
                <View style={styles.lineTitle} fixed></View>

                <Text style={styles.textWeek}>WELLNESS CHECK</Text>
                <View style={styles.section}>
                    <View style={styles.table}>
                        <View style={styles.tableRow} fixed>
                            <View style={styles.tableColHeader}>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCell}>Depression</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCell}>Anxiety</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCell}>Anger</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCell}>Total</Text>
                            </View>

                        </View>

                        {wellnesschecks && wellnesschecks.length ? wellnesschecks.map((wellnesscheck) => {
                            let month = '';
                            let day = '';
                            let year = '';
                            let sum: any = '-';


                            if (wellnesscheck.date) {
                                wellnesscheck.date = new Date(wellnesscheck.date);
                                month = `${wellnesscheck.date.getUTCMonth() + 1}`;
                                day = `${wellnesscheck.date.getUTCDate()}`;
                                year = `${wellnesscheck.date.getUTCFullYear()}`;
                            } else {
                                wellnesscheck.CreatedAt = new Date(wellnesscheck.CreatedAt);
                                month = `${wellnesscheck.CreatedAt.getUTCMonth() + 1}`;
                                day = `${wellnesscheck.CreatedAt.getUTCDate()}`;
                                year = `${wellnesscheck.CreatedAt.getUTCFullYear()}`;
                            }

                            month = month.length === 1 ? `0${month}` : month;

                            day = day.length === 1 ? `0${day}` : day;

                            if (wellnesscheck?.Id) {
                                sum = wellnesscheck.LevelDepression + wellnesscheck.LevelAnxiety + wellnesscheck.LevelAnger
                            }




                            return (<View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{`${month}/${day}/${year}`}</Text>
                            </View>
                                <View style={{ ...styles.tableColLevelZero, backgroundColor: (wellnesscheck?.LevelDepression) ? getLevelColorWellnesscheck(wellnesscheck.LevelDepression) : '#717171'}}>
                            </View>
                                <View style={{ ...styles.tableColLevelZero, backgroundColor: (wellnesscheck?.LevelAnxiety) ? getLevelColorWellnesscheck(wellnesscheck.LevelAnxiety) : '#717171'}}>
                            </View>
                                <View style={{ ...styles.tableColLevelZero, backgroundColor: (wellnesscheck?.LevelAnger) ? getLevelColorWellnesscheck(wellnesscheck.LevelAnger) : '#717171' }}>

                            </View>
                            <View style={styles.tableColSum}>
                                    <Text>{sum}</Text>
                            </View>
                        </View>)}): (<Text>loading content chart try again</Text>)}


                    </View>

                    <View style={styles.labelColumn}>
                        <View style={styles.boxLabel}>
                            <View style={styles.noDataLabel}></View><Text style={styles.textLabels}>{" No data "}</Text>
                        </View>
                        <View style={styles.boxLabel}>
                            <View style={styles.levelZeroLabel}></View><Text style={styles.textLabels}>{" Level 0 "}</Text>
                        </View>
                        <View style={styles.boxLabel}>
                            <View style={styles.levelOneLabel}></View><Text style={styles.textLabels}>{" Level 1 "}</Text>
                        </View>
                        <View style={styles.boxLabel}>
                            <View style={styles.levelTwoLabel}></View><Text style={styles.textLabels}>{" Level 2 "}</Text>
                        </View>
                        <View style={styles.boxLabel}>
                            <View style={styles.levelThreeLabel}></View><Text style={styles.textLabels}>{" Level 3 "}</Text>
                        </View>
                        <View style={styles.boxLabel}>
                            <View style={styles.levelFourLabel}></View><Text style={styles.textLabels}>{" Level 4 "}</Text>
                        </View>
                        <View style={styles.boxLabel}>
                            <View style={styles.levelFiveLabel}></View><Text style={styles.textLabels}>{" Level 5 "}</Text>
                        </View>
                    </View>
                </View>

                <View style={styles.lineTitle}></View>

                <Text style={styles.textWeek}>PHQ 2-9 QUESTIONS</Text>


                <View style={styles.section}>

                    <View style={styles.table}>
                        <View style={styles.tableRow} fixed>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}></Text>
                            </View>
                            <View style={styles.tableColHeaderPhq}>
                                <Text style={styles.tableCellPHPHeader}>Sleeping Disturbance</Text>
                            </View>
                            <View style={styles.tableColHeaderPhq}>
                                <Text style={styles.tableCellPHPHeader}>Moving/ Speaking Trouble</Text>
                            </View>
                            <View style={styles.tableColHeaderPhq}>
                                <Text style={styles.tableCellPHPHeader}>Interest on Doing Things</Text>
                            </View>

                            <View style={styles.tableColHeaderPhq}>
                                <Text style={styles.tableCellPHPHeader}>Hurting yourself</Text>
                            </View>

                            <View style={styles.tableColHeaderPhq}>
                                <Text style={styles.tableCellPHPHeader}>Feeling Tired</Text>
                            </View>

                            <View style={styles.tableColHeaderPhq}>
                                <Text style={styles.tableCellPHPHeader}>Feeling Down</Text>
                            </View>

                            <View style={styles.tableColHeaderPhq}>
                                <Text style={styles.tableCellPHPHeader}>Feeling Bad</Text>
                            </View>

                            <View style={styles.tableColHeaderPhq}>
                                <Text style={styles.tableCellPHPHeader}>Concentration Trouble</Text>
                            </View>

                            <View style={styles.tableColHeaderPhq}>
                                <Text style={styles.tableCellPHPHeader}>Appetite Disturbance</Text>
                            </View>
                            <View style={styles.tableColHeaderPhq}>
                                <Text style={styles.tableCellPHPHeader}>Total</Text>
                            </View>

                        </View>
                        {phq29s && phq29s.length ? phq29s.map((phq29) => {

                            let month = '';
                            let day = '';
                            let year = '';
                            let sum: any = '-';


                            if (phq29.date) {
                                phq29.date = new Date(phq29.date);
                                month = `${phq29.date.getUTCMonth() + 1}`;
                                day = `${phq29.date.getUTCDate()}`;
                                year = `${phq29.date.getUTCFullYear()}`;
                            } else {
                                phq29.createdAt = new Date(phq29.createdAt);
                                month = `${phq29.createdAt.getUTCMonth() + 1}`;
                                day = `${phq29.createdAt.getUTCDate()}`;
                                year = `${phq29.createdAt.getUTCFullYear()}`;
                            }

                            month = month.length === 1 ? `0${month}` : month;

                            day = day.length === 1 ? `0${day}` : day;

                            if (phq29?.date) {
                                sum =
                                parseFloat(phq29.sleepingToMuch) +
                                parseFloat(phq29.movingSlow) +
                                parseFloat(phq29.interestOnDoingThings) +
                                parseFloat(phq29.betterDead) +
                                parseFloat(phq29.feelingTired) +
                                parseFloat(phq29.feelingDown) +
                                parseFloat(phq29.feelingBad) +
                                parseFloat(phq29.concentrationTrouble) +
                                parseFloat(phq29.appetiteDisturbance);
                            }



                            return (
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{`${month}/${day}/${year}`}</Text>
                                    </View>
                                    <View style={{ ...styles.tableColLevelPHQ, backgroundColor: (phq29?.sleepingToMuch) ? getLevelColorPhq(phq29.sleepingToMuch) : '#717171' }}>
                                    </View>
                                    <View style={{ ...styles.tableColLevelPHQ, backgroundColor: (phq29?.movingSlow) ? getLevelColorPhq(phq29.movingSlow) : '#717171'  }}>
                                    </View>
                                    <View style={{ ...styles.tableColLevelPHQ, backgroundColor: (phq29?.interestOnDoingThings) ? getLevelColorPhq(phq29.interestOnDoingThings) : '#717171'  }}>
                                    </View>
                                    <View style={{ ...styles.tableColLevelPHQ, backgroundColor: (phq29?.betterDead) ? getLevelColorPhq(phq29.betterDead) : '#717171'  }}>
                                    </View>
                                    <View style={{ ...styles.tableColLevelPHQ, backgroundColor: (phq29?.feelingTired) ? getLevelColorPhq(phq29.feelingTired) : '#717171'  }}>
                                    </View>
                                    <View style={{ ...styles.tableColLevelPHQ, backgroundColor: (phq29?.feelingDown) ? getLevelColorPhq(phq29.feelingDown) : '#717171'  }}>
                                    </View>
                                    <View style={{ ...styles.tableColLevelPHQ, backgroundColor: (phq29?.feelingBad) ? getLevelColorPhq(phq29.feelingBad) : '#717171'  }}>
                                    </View>
                                    <View style={{ ...styles.tableColLevelPHQ, backgroundColor: (phq29?.concentrationTrouble) ? getLevelColorPhq(phq29.concentrationTrouble) : '#717171'  }}>
                                    </View>
                                    <View style={{ ...styles.tableColLevelPHQ, backgroundColor: (phq29?.appetiteDisturbance) ? getLevelColorPhq(phq29.appetiteDisturbance) : '#717171'  }}>
                                    </View>
                                    <View style={styles.tableColSum}>
                                        <Text>{sum}</Text>
                                    </View>
                                </View>
                            )
                        }) : (<Text>loading content chart try again</Text>)}

                    </View>

                    <View style={styles.labelColumn}>
                        <View style={styles.boxLabel}>
                            <View style={styles.noDataLabel}></View><Text style={styles.textLabels}>{" No data "}</Text>
                        </View>
                        <View style={styles.boxLabel}>
                            <View style={styles.levelZeroLabel}></View><Text style={styles.textLabels}>{" Level 0 "}</Text>
                        </View>
                        <View style={styles.boxLabel}>
                            <View style={styles.levelOneLabel}></View><Text style={styles.textLabels}>{" Level 1 "}</Text>
                        </View>
                        <View style={styles.boxLabel}>
                            <View style={styles.levelThreeLabel}></View><Text style={styles.textLabels}>{" Level 2 "}</Text>
                        </View>
                        <View style={styles.boxLabel}>
                            <View style={styles.levelFiveLabel}></View><Text style={styles.textLabels}>{" Level 3 "}</Text>
                        </View>
                    </View>
                </View>

                <Text style={styles.textPaginate} render={({ pageNumber, totalPages }) => (
                `Page ${pageNumber} / ${totalPages}`
            )} fixed />
            </Page >
        </Document >
    )
}

const ReportCareProviderPrint = () => {
    const [wellnesschecks, setWellnesschecks] = useState([]);
    const [phq29s, setPhq29s] = useState([]);
    const [careProvider, setCareProvider] = useState();
    const [show, setHidden] = useState(false);

    const { careProviderId, days } = useParams();


    const getDataCareProvinder = async () => {

        if (!careProviderId) {
            const resp = await getAuthUser();
            return resp?.data?.user;
        }

        const resp = await getUser(careProviderId);
        return resp?.data?.user;
    }


    const getWellnessChecksAndPHQ = async (days = 7) => {

        try {
            const answerWell = await getWellnesschecks(days, careProviderId);
            const {
                wellnesschecks,
            } = answerWell.data;

            setWellnesschecks(wellnesschecks);
            console.log(wellnesschecks)
            const answerPHQ = await getPHQ29s(days, careProviderId);
            const { phq29s } = answerPHQ.data;
            setPhq29s(phq29s);
            console.log(phq29s)

            const care = await getDataCareProvinder();
            setCareProvider(care);
            console.log(care)

            setHidden(true);

        } catch (error) {

            setHidden(false);
            swal('Error', 'Something went wrong while loading reports', 'error');
        }
    };

    const getStrWeek = (week?: number) => {

        let strWeek = 'LAST WEEK(LAST 7 DAYS)';

        switch (parseInt(week)) {
            case 7:
                strWeek = 'LAST WEEK(LAST 7 DAYS)';
                break;
            case 30:
                strWeek = 'LAST MONTH(LAST 30 DAYS)';
                break;
            case 90:
                strWeek = 'LAST 3 MONTHS(LAST 90 DAYS)';
                break;
            case 180:
                strWeek = 'LAST 6 MONTHS(LAST 180 DAYS)';
                break;
            case 270:
                strWeek = 'LAST 9 MONTHS(LAST 270 DAYS)';
                break;
            case 365:
                strWeek = 'LAST 12 MONTHS(LAST 365 DAYS)';
                break;
            default:
                strWeek = 'LAST WEEK(LAST 7 DAYS)';
                break;
        }

        return strWeek;
    };

    useEffect(() => {
        if (days) {
            getWellnessChecksAndPHQ(days);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>

            {show ? (
            <div>
                <div>
                    <PDFViewer style={styles.preview}>
                        <MyDoc wellnesschecks={wellnesschecks} phq29s={phq29s} numberDays={getStrWeek(days)} careProvider={careProvider}/>
                    </PDFViewer>
                </div>

                <Box style={styles.btnDownload}>

                <PDFDownloadLink  document={<MyDoc wellnesschecks={wellnesschecks} phq29s={phq29s} numberDays={getStrWeek(days)} careProvider={careProvider}/>} fileName="Care_Provider_Report.pdf">
                    {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
                </PDFDownloadLink>
                    </Box>
            </div>) : 'Loading document...'}

        </div>

    )
}



export default ReportCareProviderPrint;

