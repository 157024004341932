import { CircularProgress } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { getLanguages } from "../../../../../services/appCenterData";

interface Props {
  dataStart: Date;
  dataEnd: Date;
  top: number;
  system: "all" | "android" | "iOS";
}

export default function LanguagesChart({
  dataStart,
  dataEnd,
  top,
  system = "all",
}: Props): JSX.Element {
  const [dataLanguage, setDataLanguage] = useState<Object>();
  const [state, setState] = useState<Object>();
  const [loading, setLoading] = useState<boolean>();

  const getDataLanguages = async () => {
    let defineData = {};

    try {
      setLoading(true);
      const { data } = await getLanguages({
        dataStart,
        dataEnd,
        top,
      });
      if (system === "all" || system === "android") {
        if (data?.data?.android) {
          data?.data.android.forEach((language) => {
            defineData[language.languageName] =
              language.count + (defineData[language.languageName] || 0);
          });
        }
      }
      if (system === "all" || system === "iOS") {
        if (data?.data?.iOS) {
          data?.data.iOS.forEach((language) => {
            defineData[language.languageName] =
              language.count + (defineData[language.languageName] || 0);
          });
        }
      }

      setDataLanguage(defineData);

      // setLanguages(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDataLanguages();
  }, [dataStart, dataEnd, top, system]);

  useEffect(() => {
    if (dataLanguage) {
      setState({
        labels: Object.keys(dataLanguage) || [],
        datasets: [
          {
            label: "Language",
            backgroundColor: "#1FAECE",
            borderColor: "rgba(0,0,0,0.5)",
            borderWidth: 1,
            data: Object.values(dataLanguage) || [],
          },
        ],
      });
    }
  }, [dataLanguage]);

  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : (
        <Bar
          data={state}
          options={{
            title: {
              display: false,
            },
            legend: {
              display: true,
              position: "right",
            },
          }}
        />
      )}
    </div>
  );
}
