import API from './api';
import swal from 'sweetalert';
import jwtDecode  from "jwt-decode";

export const TOKEN_KEY = "qactual_clinician_token";
export const REFRESH_TOKEN_KEY = "qactual_clinician_refresh_token";
export const GROUP_KEY = "qactual_clinician_group";

// Valid
export const isAuthenticatedAsGeneral = async () => {
  if (getToken()) {
    return true;
  };
  logout();
  return false;
}

export const isAuthenticatedAsGeneralAdmin =  (group:  string) => {
  if (!getToken())  {
    logout();
    return false;
  } else if  (getGroupUser() !== group) {
    swal("ERROR", "You do not have permission for this action", "error")
    logout();
    return false;
  };
  return true;
}

// AUTH
export const login = async (params: any) => await API.post(`/auth/login`, params);

export const twofa = async (email: string) => await API.get(`twofa/generate/${email}`);

export const logout = async (href?: string) => {
  await API.post(`/auth/logout`);
  clearLocalStorage();

  if(href) {
    window.location.href = href;
  }
}

export const clearLocalStorage = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(GROUP_KEY);
}

export const forgotPassword = async (params: any) => await API.post(`/auth/forgotPassword`, params);

export const confirmPassword = async (params: any) => await API.post(`/auth/confirmForgotPassword`, params);

// GETs, SETs
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const setToken = (token: string) => localStorage.setItem(TOKEN_KEY, token);

export const setRefreshToken = (refreshToken: string) => localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_KEY);

export const setGroupKey = (group: string) => localStorage.setItem(GROUP_KEY, group);
export const getGroupKey = () => localStorage.getItem(GROUP_KEY);

export const getAuthUser = async () => await API.get(`/auth/user`);

export const getGroupUser = () => Object(jwtDecode<Object>(getToken() || ''))["cognito:groups"][0];

export const getEmailSession = () => Object(jwtDecode<Object>(getToken() || ''))?.username;

export const getCognitoIdUserLogged = () => Object(jwtDecode<Object>(getToken() || ''))?.sub;

// CRUD
export const getUser = async (id: string) => await API.get(`/users/${id}`);
export const getUserWithoutObsolete = async (id: string) => await API.get(`/users/${id}/withoutObsolete`);

export const createUser = async (user: any) => await API.post(`/auth/createuser`, user);

export const editUser = async (user: any, id: string) => await API.put(`/auth/users/${id}`, user);
export const activateUser = async (user: any) => await API.post(`auth/activateUser`, user);

export const resetPassword = async (fields: any) => await API.post('/auth/reset', fields);

// HELPERS

export const refreshTokenUser = async () => await API.post('auth/refreshToken',
  {
    email: getEmailSession(),
    access_token: getToken(),
    refresh_token: getRefreshToken(),
  });
