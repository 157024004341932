import React, { FC, useState, useEffect } from 'react';
import swal from 'sweetalert';
import {
  Modal,
  Grid,
  TextField,
  Button,
  createStyles,
  makeStyles,
  Box,
  CircularProgress,
} from '@material-ui/core';
import { Formik } from 'formik';
import SaveIcon from '@material-ui/icons/Save';
import SendIcon from '@material-ui/icons/Send';
// APIs
import { updatePatient, getById } from '../../../../services/patient';
import { request } from '../../../../services/token';
import * as yup from 'yup';
import * as SanitizeString from '../../../../services/sanitize_strings';
require('yup-phone');

interface Props {
  patientId: string;
  open: boolean;
  handleClose: Function;
  handleOpen: Function;
  handleReload?: Function;
}

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 600,
      backgroundColor: "#EAEAEA",
      border: '2px solid #000',
      padding: 20,
      paddingTop: '3%',
      paddingBottom: '3%',
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
    },
  }),
);

const AddPatient: FC<Props> = (props): JSX.Element => {

  const [patient, setPatient] = useState({
    id: '',
    name: '',
    email: '',
    phoneNumber: '',
  });

  const [isLoading, setIsLoading] = useState < boolean > (false);
  const [isLoadingInvite, setIsLoadingInvite] = useState < boolean > (false);
  const classes = useStyles();

  useEffect(() => {
    if (props.patientId) {
      getPatientGetById(props.patientId);
    }
  }, [props.patientId]);

  const getPatientGetById = async (id: string) => {
    try {
      const resp = await getById(id);
      if (resp?.data?.patient) {
        setPatient(resp.data.patient);
      }
    } catch (error) {
      swal('Error', 'Something went wrong when load patient', 'error');
    }
  }

  const handleSubmit = async (values) => {
    setIsLoading(true);

    const data = {
      patient: {
        name: values.name,
        email: SanitizeString.email(values.email),
        phoneNumber: SanitizeString.phoneNumber(values.phoneNumber),
      }
    };

    await updatePatient(props.patientId, data).then(resp => {
      if (resp.data.status === 'success') {
        swal("OK", "Successfully updated", "success");
        handleConclusion();
      } else {
        swal("ERROR", "Failed to update", "error");
      }
      setIsLoading(false);
    });
  }

  const handleConclusion = () => {
    props.handleClose();
    props.handleReload();
    getPatientGetById(props.patientId);
  }

  // RequestToken to patient
  const handleConfirmToken= async () => {
    swal({
      title: "Warning!",
      text: "This action will send an invitation to the patient. Do you want to confirm?",
      icon: "warning",
      buttons: ["Cancel", "Send"],
    }).then((willDelete) => {
      if (willDelete) {
        handleRequestToken();
      }
    });
  }

  const handleRequestToken = async () => {
    setIsLoadingInvite(true);
    const data = {
      email: patient.email,
      phoneNumber: patient.phoneNumber,
    }
    await request(data).then(resp => {
      if (resp.data.status === 'error') {
        swal("ERROR", "Failed to send the invitation", "error");
      } else {
        swal("OK", "Invitation sent", "success");
        handleConclusion();
      }
      setIsLoadingInvite(false);
    });
  }

  // Component Functions
  const formBody = () => (
    <div className={classes.paper}>
    <Formik initialValues={{
        name: patient.name,
        email: patient.email,
        phoneNumber: patient.phoneNumber,
      }}
      validationSchema={yup.object().shape({
        name: yup.string().required("Must enter a email for the user"),
        // email: yup.string().required("Must enter a email for the user"),
        phoneNumber: yup.string().phone().matches(/^\+[0-9]*$/, 'For phone numbers please add the "+" at the front followed by just numbers').required("Must enter a phone for the user")
      })}
      onSubmit = {(values) => {
        handleSubmit(values);
      }}
    >
      {({values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit}) => (
      <form onSubmit={handleSubmit}>
        <Grid container justify="center" spacing={3}>
          <Grid item xs={8}>
            <TextField
              id="name"
              label="Name"
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              fullWidth
              error={errors.name && touched.name}
              helperText={touched.name && errors.name}
            />
          </Grid>
            <Grid item xs={8}>
              <TextField
                id="phoneNumber"
                type="tel"
                label="Phone"
                variant="outlined"
                placeholder="Example +12025550185"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phoneNumber}
                fullWidth
                error={errors.phoneNumber && touched.phoneNumber}
                helperText={(touched.phoneNumber && errors.phoneNumber) || `Use the international standard (Example +12025550185)`}
                />
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="email"
                label="Email"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                fullWidth
                error={errors.email && touched.email}
                helperText={touched.email && errors.email}
              />
            </Grid>
        </Grid>
        <Box mt={3}>
          <Grid container justify="center" spacing={3}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
                size="large"
                startIcon={isLoading ? <CircularProgress size={24} /> : <SaveIcon />}
                style={{
                  backgroundColor: '#000',
                  color: '#fff',
                }}
              >
                UPDATE
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="button"
                size="large"
                onClick={() => handleConfirmToken()}
                startIcon={isLoadingInvite ? <CircularProgress size={24} /> : <SendIcon />}
              >
                RESEND INVITE
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
      )}
    </Formik>
    </div>
  );

  return (
    <Modal
      open={props.open}
      onClose={() => props.handleClose()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {formBody()}
    </Modal>
  );
};

export default AddPatient;
