import React, { FC, useEffect, useState, } from "react";
import {
  Container,
  CssBaseline,
  Grid,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Input,
  ButtonGroup,
  Button,
  Box,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  CircularProgress
} from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import { format } from 'date-fns'
// Images
import Logo from "./../../../assets/images/logoTitleGreen.svg";
import { Formik } from "formik";
import { ArrowBack } from "@material-ui/icons";
import swal from 'sweetalert';

// CALL TO API
import { createFormPHQ29, phq29Logs } from "./../../../services/patient";
import { getAuthUser } from './../../../services/auth';

const AddPHQ29: FC = () => {

  const { patientId } = useParams< any >();
  const history = useHistory();

  const [initialValues, setInitialValues] = useState({
    interestOnDoingThings: 0,
    feelingDown: 0,
    sleepingToMuch: 0,
    feelingTired: 0,
    appetiteDisturbance: 0,
    feelingBad: 0,
    concentrationTrouble: 0,
    movingSlow: 0,
    betterDead: 0,
  });
  const [statePHQ2, setAddPHQ2] = useState(0);
  const [statePHQ9, setAddPHQ9] = useState(0);
  const [userId, setUserId] = useState(0);
  const [allPHQ29, setAllPHQ29] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setAddPHQ2(Number(initialValues.interestOnDoingThings) + Number(initialValues.feelingDown));
    setAddPHQ9(Number(initialValues.sleepingToMuch) + Number(initialValues.feelingTired) + Number(initialValues.appetiteDisturbance) + Number(initialValues.feelingBad) + Number(initialValues.concentrationTrouble) + Number(initialValues.movingSlow) + Number(initialValues.betterDead));
  }, [initialValues]);

  useEffect(() => {
    (async () => {
      const response = await getAuthUser();
      setUserId(response?.data?.user?.id);
    })();
    (async () => {
      const { data } = await phq29Logs(patientId);
      setAllPHQ29(data?.patientWithAllPHQ29?.PHQ29s);
      if (data?.patientWithAllPHQ29?.PHQ29s) {
        setIsLoading(false);
      }
    })();
  }, [patientId]);

  const handleSubmit = async () => {
    const obj = {
      interestOnDoingThings: initialValues.interestOnDoingThings,
      feelingDown: initialValues.feelingDown,
      sleepingToMuch: initialValues.sleepingToMuch,
      feelingTired: initialValues.feelingTired,
      appetiteDisturbance: initialValues.appetiteDisturbance,
      feelingBad: initialValues.feelingBad,
      concentrationTrouble: initialValues.concentrationTrouble,
      movingSlow: initialValues.movingSlow,
      betterDead: initialValues.betterDead,
      PatientId: patientId,
      UserId: userId
    }


    try {
      await createFormPHQ29(patientId, obj);
      swal('Success', 'PHQ2-9 done', 'success');
      history.push(`/cliniciandashboard/patient/${patientId}`);
    } catch (error) {
      swal('Error', 'There was an error making PHQ2-9', 'error');
      console.log(error);
    }
  }

  const handleBackClick = () => {
    history.push(`/cliniciandashboard/patient/${patientId}`);
  }

  const handleChange = (event) => {
    setInitialValues({ ...initialValues, [event.target.name]: event.target.value });
  }

  return (
    <>
      <CssBaseline />
      <Container className="main-container">
        <Grid container>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <a href="/cliniciandashboard" title="Go to home">
              <img src={Logo} alt="Logo" style={{ width: "90%" }} />
            </a>

          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <Grid container>
              <Grid item xs={12}>
                <div style={{ float: 'left' }}>
                  <Typography variant="h4" display="block" gutterBottom>
                    PHQ Depression Screen
                  </Typography>
                </div>
                <ButtonGroup
                  color="primary"
                  aria-label='outlined primary button group'
                  style={{ float: 'right' }}
                >
                  <Button onClick={() => handleBackClick()}>
                    <ArrowBack fontSize="small" /> &nbsp;Back
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {isLoading ?
          (<Grid container justify="center">
            <CircularProgress />
          </Grid>)
        :
          (<Grid container>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Box
                style={{
                  width: '90%',
                  border: '1px solid  #878787',
                  maxHeight: 400,
                  overflow: 'auto',

                }}>
                <List subheader={<li />}>
                  <ListSubheader>*-No  Contact V-  Contact made</ListSubheader>
                  {allPHQ29 && allPHQ29?.length && allPHQ29.map((phq29) => {
                    if (phq29.User) {
                      return (
                        <ListItem key={phq29.id}>
                          <ListItemText>
                            <Typography style={{ fontWeight: 600 }}>{phq29?.User?.username}</Typography>
                            <Typography style={{ fontSize: 12 }} >{format(new Date(phq29?.createdAt), 'yyyy-MM-dd KK:mm:ss  aaa	')}</Typography>
                          </ListItemText>
                        </ListItem>
                      )
                    }
                    return (<></>)
                  })}
                </List>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <Formik initialValues={initialValues} onSubmit={() => { handleSubmit(); }} >
                {({ isSubmitting, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <FormLabel component="legend">Little interest or pleasure in doing things</FormLabel>
                        <RadioGroup aria-label="interestOnDoingThings" name="interestOnDoingThings" value={initialValues.interestOnDoingThings} onChange={handleChange} row>
                          <FormControlLabel value="0" control={<Radio />} label="0 = Not at all" />
                          <FormControlLabel value="1" control={<Radio />} label="1 = Several days" />
                          <FormControlLabel value="2" control={<Radio />} label="2 = More than half the days" />
                          <FormControlLabel value="3" control={<Radio />} label="3 = Nearly every day" />
                        </RadioGroup>
                      </Grid>

                      <Grid item xs={12}>
                        <FormLabel component="legend">Feeling down, depressed, or hopeless (PHQ Adolescent also includes...irritable)</FormLabel>
                        <RadioGroup aria-label="feelingDown" name="feelingDown" value={initialValues.feelingDown} onChange={handleChange} row>
                          <FormControlLabel value="0" control={<Radio />} label="0 = Not at all" />
                          <FormControlLabel value="1" control={<Radio />} label="1 = Several days" />
                          <FormControlLabel value="2" control={<Radio />} label="2 = More than half the days" />
                          <FormControlLabel value="3" control={<Radio />} label="3 = Nearly every day" />
                        </RadioGroup>
                      </Grid>

                      <Grid item xs={12}>
                        <InputLabel>PHQ-2 Total Score</InputLabel>
                        <Input id="phq2" value={statePHQ2} />
                      </Grid>

                      <Grid item xs={12}>
                        <FormLabel component="legend">Trouble falling or staying asleep, or sleeping too much</FormLabel>
                        <RadioGroup aria-label="sleepingToMuch" name="sleepingToMuch" value={initialValues.sleepingToMuch} onChange={handleChange} row>
                          <FormControlLabel value="0" control={<Radio />} label="0 = Not at all" />
                          <FormControlLabel value="1" control={<Radio />} label="1 = Several days" />
                          <FormControlLabel value="2" control={<Radio />} label="2 = More than half the days" />
                          <FormControlLabel value="3" control={<Radio />} label="3 = Nearly every day" />
                        </RadioGroup>
                      </Grid>

                      <Grid item xs={12}>
                        <FormLabel component="legend">Feeling tired or having little energy</FormLabel>
                        <RadioGroup aria-label="feelingTired" name="feelingTired" value={initialValues.feelingTired} onChange={handleChange} row>
                          <FormControlLabel value="0" control={<Radio />} label="0 = Not at all" />
                          <FormControlLabel value="1" control={<Radio />} label="1 = Several days" />
                          <FormControlLabel value="2" control={<Radio />} label="2 = More than half the days" />
                          <FormControlLabel value="3" control={<Radio />} label="3 = Nearly every day" />
                        </RadioGroup>
                      </Grid>

                      <Grid item xs={12}>
                        <FormLabel component="legend">Poor appetite or overeating (PHQ Adolescent also includes...weight loss)</FormLabel>
                        <RadioGroup aria-label="appetiteDisturbance" name="appetiteDisturbance" value={initialValues.appetiteDisturbance} onChange={handleChange} row>
                          <FormControlLabel value="0" control={<Radio />} label="0 = Not at all" />
                          <FormControlLabel value="1" control={<Radio />} label="1 = Several days" />
                          <FormControlLabel value="2" control={<Radio />} label="2 = More than half the days" />
                          <FormControlLabel value="3" control={<Radio />} label="3 = Nearly every day" />
                        </RadioGroup>
                      </Grid>

                      <Grid item xs={12}>
                        <FormLabel component="legend">Feeling bad about yourself - or that you are a failure or have let yourself or your family down</FormLabel>
                        <RadioGroup aria-label="feelingBad" name="feelingBad" value={initialValues.feelingBad} onChange={handleChange} row>
                          <FormControlLabel value="0" control={<Radio />} label="0 = Not at all" />
                          <FormControlLabel value="1" control={<Radio />} label="1 = Several days" />
                          <FormControlLabel value="2" control={<Radio />} label="2 = More than half the days" />
                          <FormControlLabel value="3" control={<Radio />} label="3 = Nearly every day" />
                        </RadioGroup>
                      </Grid>

                      <Grid item xs={12}>
                        <FormLabel component="legend">Trouble concentrating on things, such as reading the newspaper or watching television (PHP Adolescent also includes...like school work)</FormLabel>
                        <RadioGroup aria-label="concentrationTrouble" name="concentrationTrouble" value={initialValues.concentrationTrouble} onChange={handleChange} row>
                          <FormControlLabel value="0" control={<Radio />} label="0 = Not at all" />
                          <FormControlLabel value="1" control={<Radio />} label="1 = Several days" />
                          <FormControlLabel value="2" control={<Radio />} label="2 = More than half the days" />
                          <FormControlLabel value="3" control={<Radio />} label="3 = Nearly every day" />
                        </RadioGroup>
                      </Grid>

                      <Grid item xs={12}>
                        <FormLabel component="legend">Moving or speaking so slowly that other people could have noticed. Or the opposite - being so fidgety or restless that you have been moving more than usual</FormLabel>
                        <RadioGroup aria-label="movingSlow" name="movingSlow" value={initialValues.movingSlow} onChange={handleChange} row>
                          <FormControlLabel value="0" control={<Radio />} label="0 = Not at all" />
                          <FormControlLabel value="1" control={<Radio />} label="1 = Several days" />
                          <FormControlLabel value="2" control={<Radio />} label="2 = More than half the days" />
                          <FormControlLabel value="3" control={<Radio />} label="3 = Nearly every day" />
                        </RadioGroup>
                      </Grid>

                      <Grid item xs={12}>
                        <FormLabel component="legend">Thoughts that you would be better off dead, or of hurting yourself in some way</FormLabel>
                        <RadioGroup aria-label="betterDead" name="betterDead" value={initialValues.betterDead} onChange={handleChange} row>
                          <FormControlLabel value="0" control={<Radio />} label="0 = Not at all" />
                          <FormControlLabel value="1" control={<Radio />} label="1 = Several days" />
                          <FormControlLabel value="2" control={<Radio />} label="2 = More than half the days" />
                          <FormControlLabel value="3" control={<Radio />} label="3 = Nearly every day" />
                        </RadioGroup>
                      </Grid>

                      <Grid item xs={12}>
                        <InputLabel>PHQ-9 Total Score</InputLabel>
                        <Input id="phq9" value={statePHQ9} />
                      </Grid>

                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          style={{ float: 'right' }}
                          disabled={isSubmitting}
                        >
                          Submit
                                      </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </Grid>
          </Grid>)
        }
      </Container>
    </>
  );
};

export default AddPHQ29;
