import React, { FC, useState } from 'react';
import {
    MenuItem,
    Grid,
    Typography,
    Select,
    IconButton,
    CircularProgress,
} from '@material-ui/core';

import { BiArrowToBottom } from 'react-icons/bi';

// API
// import { getWellnesschecks, getPHQ29s } from '../../../../services/careProvider';
// import { PDF } from '../../../../helpers/pacientUtils';
// import swal from 'sweetalert';
import { Link } from 'react-router-dom';

const FilterChartsReport: FC = (): JSX.Element => {

    const [loading, setLoading] = useState < boolean > (false);
    const [days, setDays] = useState < number > (7);
    // const [phq29s, setPhq29s] = useState ([]);
    // const [wellnesschecks, setWellnesschecks] = useState ([]);
    // const [didMount, setDidMount] = useState(false);

    // useEffect(() => {
    //     setDidMount(true);
    //     handleChartsData(days);
    //     return () => setDidMount(false);
    // }, [days]);



    const handleChange = (event) => {
        setLoading(true);
        setDays(event.target.value);
        setLoading(false)

        // handleChartsData(event.target.value);
    }

    // const handleChartsData = async (period: number) => {
    //     setLoading(true);
    //     // wellnesschecks
    //     try {
    //         const resp = await getWellnesschecks(period);
    //         if(resp?.status === 200) {
    //             setWellnesschecks(resp?.data?.wellnesschecks);
    //             setLoading(false);
    //         }
    //         // PHQ29s
    //        const  respPHQ29s = await getPHQ29s(period);
    //         if (respPHQ29s?.status === 200) {
    //             setPhq29s(respPHQ29s?.data?.phq29s);
    //             setLoading(false);
    //         }
    //     } catch (error) {
    //         swal('Error', 'Something went wrong while loading reports', 'error');
    //         setLoading(false);
    //     }
    // }

    // if(!didMount) return null;

    return (
        <>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <Grid container>
                    <Grid item xs={12}>
                        <Select
                            className="btn-last-week-filter"
                            onChange={handleChange}
                            value={days}
                        >
                            <MenuItem value={7}>
                                <Typography>LAST WEEK</Typography>
                            </MenuItem>
                            <MenuItem value={30}>
                                <Typography>LAST MONTH</Typography>
                            </MenuItem>
                            <MenuItem value={90}>
                                <Typography>LAST 3 MONTHS</Typography>
                            </MenuItem>
                            <MenuItem value={180}>
                                <Typography>LAST 6 MONTHS</Typography>
                            </MenuItem>
                            <MenuItem value={270}>
                                <Typography>LAST 9 MONTHS</Typography>
                            </MenuItem>
                            <MenuItem value={365}>
                                <Typography>LAST 12 MONTHS</Typography>
                            </MenuItem>
                        </Select>
                        {!loading ? <Link to={`/cliniciandashboard/report/${days}`} target='_blank'>
                            <IconButton
                                color="primary"
                                aria-label="Download report pdf"
                                component="span"

                                disabled={loading}
                            >
                                {!loading ? <BiArrowToBottom /> : <CircularProgress size={24} />}
                            </IconButton>
                        </Link>: <CircularProgress size={24} />}

                    </Grid>

                </Grid>
            </Grid>
        </>
    );
}

export default FilterChartsReport;