import React from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  CssBaseline,
  Container,
  Grid,
  TextField,
  Typography,
  Button,
  CircularProgress,
  ButtonGroup,
  Paper,
  MenuItem
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import LocalDrawer from "../../../Components/QActualAdminLocalDrawer";
import AppBar from "../../../Components/AppBar";
import { Formik, Field } from "formik";
import * as yup from "yup";
import swal from "sweetalert";
import SaveIcon from "@material-ui/icons/Save";
import * as QuestionApi from '../../../services/mobile/wellnessFormQuestionAnswers';
// CALL TO API
import { logout } from "../../../services/auth";

interface Props extends RouteComponentProps {}

interface State {
  isLoading: boolean;
  toolbarOpen: boolean;
  organizationId: string;
  questionId: string;
  formId: string;
  modalRemoveIsOpen: boolean;
  question: {
    id: string,
    questionTitle: string,
    questionType: string,
    defaultValue?: string,
    active: string,
    deletedAt?: string,
    createdAt: string,
    updatedAt: string
  }
}

class EditFormQuestion extends React.Component<Props, State> {

  constructor(props) {
    super(props);

    this.state = {
      toolbarOpen: localStorage.getItem("toolbarOpen") === "true" ? true : false,
      organizationId: props.match.params.organizationId,
      questionId: props.match.params.questionId,
      formId: props.match.params.formId,
      isLoading: false,
      modalRemoveIsOpen: false,
      question: {
        id: '',
        questionTitle: '',
        questionType: '',
        defaultValue: '',
        active: '',
        deletedAt: '',
        createdAt: '',
        updatedAt: ''
      },
    };
  }

  componentDidMount() {
    this.getQuestion();
  }

  private getQuestion = async () => {
    
    const response: any = await QuestionApi.getQuestion(this.state.questionId);
    const question = response.data
    
    this.setState({ question });
  }

  private handleDrawerOpen = () => {
    localStorage.setItem("toolbarOpen", "true");
    this.setState({ toolbarOpen: true });
  };

  private handleDrawerClose = () => {
    localStorage.setItem("toolbarOpen", "false");
    this.setState({ toolbarOpen: false });
  };

  handleSubmit = async (params: any) => {
    try {
      this.setState({ isLoading: true });

      const { questionId, organizationId, formId } = this.state;

      const payload = {        
        questionTitle: params.questionTitle,
        questionType: params.questionType,
        defaultValue: params.defaultValue
      };

      const response = await QuestionApi.updateQuestion(questionId, payload);

      if (response.status === 200) {
        this.setState({ isLoading: false });
        swal("Success", "Record updated", "success");
        this.props.history.push(          
          `/qactual/admin/organizations/${organizationId}/form/${formId}/question/${questionId}`
        );
      }
    } catch (error) {
      this.setState({ isLoading: false });
      swal("Error", "Something went wrong", "error");
    }
  };

  private handleBackClick = () => {
    const { organizationId, formId, questionId } = this.state;

    this.props.history.push(
      `/qactual/admin/organizations/${organizationId}/form/${formId}/question/${questionId}`
    );
  };

  CustomizedSelectForFormik = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;
  
    return (
      <TextField
        name={name}
        value={value}
        select
        label="Question Type"
        variant="outlined"
        fullWidth
        onChange={e => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </TextField>
    );
  };

  render() {
    const { isLoading, organizationId, question } = this.state;

    // console.log('resourceData:', resourceData);

    return (
      <div className="root">
        <CssBaseline />
        <AppBar
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerOpen={this.handleDrawerOpen}
          breadcrumbs={[]}
        />
        <LocalDrawer
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerClose={this.handleDrawerClose}
          logout={logout}
        />
        <main className="content">
          <div className="toolbarIcon" />
          <Container maxWidth="lg" className="container">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div style={{ float: "left" }}>
                  <Typography variant="h4" display="block" gutterBottom>
                    Edit Question
                  </Typography>
                </div>
                <ButtonGroup
                  color="primary"
                  aria-label="outlined primary button group"
                  style={{ float: "right" }}
                >
                  <Button onClick={() => this.handleBackClick()}>
                    <ArrowBack fontSize="small" />
                    &nbsp;Back
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
            <Paper className="paper" style={{ paddingTop: 40 }}>
              <Formik
                enableReinitialize
                initialValues={{ ...question }}
                validationSchema={yup.object().shape({
                  questionTitle: yup
                    .string()
                    .required("You must provide a title to your question"),
                  questionType: yup
                    .string()
                    .required(
                      "You must provide a type to your question"
                    ),
                  defaultValue: yup.string()                
                })}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  this.handleSubmit({
                    questionTitle: values.questionTitle,
                    questionType: values.questionType,
                    defaultValue: values.defaultValue,
                  });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          id="questionTitle"
                          label="Title"
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.questionTitle}
                          fullWidth
                          error={errors.questionTitle && touched.questionTitle}
                          helperText={touched.questionTitle && errors.questionTitle}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                      <Field
                          id="questionType"
                          name="questionType"
                          value={values.questionType} 
                          component={this.CustomizedSelectForFormik}
                        >
                          <MenuItem key='questionType.text' value="text">Text</MenuItem>
                          <MenuItem key='questionType.check' value="check">Check</MenuItem>
                          <MenuItem key='questionType.numeric' value="numeric">Numeric</MenuItem>
                        </Field>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          id="defaultValue"
                          label="Default Answer"
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.defaultValue}
                          fullWidth
                          error={errors.defaultValue && touched.defaultValue}
                          helperText={touched.defaultValue && errors.defaultValue}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          style={{ float: "right" }}
                          disabled={isSubmitting}
                          startIcon={
                            isLoading ? (
                              <CircularProgress size={24} />
                            ) : (
                              <SaveIcon />
                            )
                          }
                          size="large"
                        >
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </Paper>
          </Container>
        </main>
      </div>
    );
  }
}

export default EditFormQuestion;
