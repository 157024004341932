import React from 'react';

import Pagination from '@material-ui/lab/Pagination';
import SearchIcon from '@material-ui/icons/Search';
import {
  InputBase,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Button,
  Paper,
  IconButton,
} from '@material-ui/core';

interface Props {
  title: string;
  addHref: string;
  disableButton?: boolean;
  pagination?: number;
  onChangePage?: Function;
  search?: boolean;
  handleSearchText?: Function;
  onSearchClick?: Function;
  columns: {
    key: string;
    name: string;
    type?: 'link' | 'image' | string;
    imagePath?: string;
    imageOnError?: string;
    imageOnErrorStyle?: any;
  }[];
  data: {
    [key: string]: string;
  }[];
}

class Table_ extends React.Component<Props> {

  private renderAddButton = () => {
    const { addHref, disableButton } = this.props;

    if ( addHref ) {
      return (
        <Button
            variant="contained"
            component="a"
            disabled={disableButton}
            href={addHref}
            color="primary"
            className="TableAddButton"
            style={{ float: 'right', height: '48px' }} >
          Add +
        </Button>
      );
    }
  }

  renderTableTitle = () => {
    const { title } = this.props;

    if (title) {
      return (
        <Typography
            component="h2"
            variant="h6"
            color="primary"
            style={{ float: 'left' }}
            gutterBottom >
          {title}
        </Typography>
      );
    }
  }

  renderPagination = () => {
    const { pagination, onChangePage } = this.props;

    if ( pagination ) {
      return (
        <Pagination
            style={{ float: 'right' }}
            count={pagination}
            onChange={() => onChangePage()} />
      );
    }
  }

  renderSearch = () => {
    const { search } = this.props;

    if ( search ) {
      return (
        <div style={{ float: 'left', paddingRight: '10px' }}>
          <Paper component='form'>
            <InputBase
              placeholder="Search"
              inputProps={{ 'aria-label': 'search' }}
              style={{ paddingLeft: '5px' }}
              onChange={(event) => this.handleSearchText(event.target.value)}
              onKeyPress={(event) => this.handleKeyPress(event)}
            />
            <IconButton onClick={() => this.handleSearchClick()}>
              <SearchIcon  />
            </IconButton>
          </Paper>
        </div>
      );
    }
  }

  handleSearchText = (value) => {
    this.props.handleSearchText(value);
  }

  handleSearchClick = () => {
    this.props.onSearchClick();
  }

  handleKeyPress = (event) => {
    if ( event.key === 'Enter' ) {
      this.props.onSearchClick();
      event.preventDefault();
    }
  }

  render () {
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {this.renderTableTitle()}
            <div style={{ float: 'right' }}>
              {this.renderSearch()}
              {this.renderAddButton()}
            </div>
          </Grid>
        </Grid>
        <Table size="small">
          <TableHead>
            <TableRow>
              {this.props.columns.map(column => (
                <TableCell key={column.name}>{column.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.data.map((row, idx) => (
              <TableRow key={idx}>
                {this.props.columns.map((column, idx) => {
                  if ( column.type === 'link' ) {
                    return (
                      <TableCell key={idx}>
                        <a href={row.href}>
                          {row[column.key]}
                        </a>
                      </TableCell>
                    );
                  } else if ( column.type === 'image' ) {
                    if ( row[column.key] !== '' ) {
                      return (
                        <TableCell key={idx}>
                          <img src={`${column.imagePath}${row[column.key]}`} alt=""/>
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell key={idx}>
                          <img src={column.imageOnError} style={{ ...column.imageOnErrorStyle }} alt=""/>
                        </TableCell>
                      );
                    }
                  } else {
                    return (
                      <TableCell key={idx}>
                        {row[column.key]}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {this.renderPagination()}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default Table_;
