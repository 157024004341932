import API from './api';

export const getWellnesschecks = async (numberOfDays: number, userId?: string) => await API.get(`/care_providers/wellnesschecks/${numberOfDays}`, {params: {userId} });

export const getPHQ29s = async (numberOfDays: number, userId?: string) => await API.get(`/care_providers/phq29s/${numberOfDays}`, { params: { userId } });

export const getAllCareProviders = async () => await API.get('/care_providers');

export const getClinician = async (userId: string) => await API.get(`/care_providers/${userId}`);

export const getStatusPatients = async (userId: string) => await API.get(`/care_providers/status_patients/${userId}`);

export const getAverageTimeToRespond = async (userId: string, days: number) => await API.post(`/care_providers/responseTime`, {
    careProviderId: userId,
    days
});