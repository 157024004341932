import API from "./api";

export const getQuestionsAnswers = async (formId: string) => {
  return await API.get(`/wellnessCheckFormQuestion/form/${formId}/answers`);
};

export const getQuestion = async (questionId: string) => {
  return await API.get(`/wellnessCheckFormQuestion/${questionId}`);
};

export const createQuestion = async (params: any) => {
  return await API.post(`/wellnessCheckFormQuestion/create`, params);
};

export const deleteQuestion = async (questionId: string) => {
  return await API.delete(`/wellnessCheckFormQuestion/${questionId}`);
};

export const updateQuestion = async (questionId: string, params: any) => {
  return await API.put(`/wellnessCheckFormQuestion/${questionId}`, params);
};
