import API from "./api";

export const getAcknowledgeEvent = async (eventId: string) =>
  await API.post("/events/acknowledgeEvent", { eventId });

export const getEventsWithPaginate = async (
  patientId: string,
  page: number,
  records: any
) => await API.get(`/events/${patientId}/page/${page}/records/${records}`);
