const phoneNumber = (input: string): string => {
  return input.replace(/[^0-9+]/g, "");
}

const email = (input: string): string => {
  return input.toLowerCase().trim();
}

export {
  phoneNumber,
  email,
}
