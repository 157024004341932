import React from 'react';
import { RouteComponentProps } from "react-router-dom";
import swal from 'sweetalert';
import {
  CssBaseline,
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  ButtonGroup,
} from '@material-ui/core';
import { Edit, RemoveCircle, ArrowBack } from '@material-ui/icons';
import LocalDrawer from '../../../Components/QActualAdminLocalDrawer';
import AppBar from '../../../Components/AppBar';
import Table from '../../../Components/Table';
import RemoveModal from '../../../Components/RemoveModal';

// CALL TO API
import { logout } from '../../../services/auth';
import { listAllUserOrgAdminsActivateDeactivate, deleteResourceOrganization } from  '../../../services/organization';
import * as FormApi from '../../../services/mobile/wellnessForm';

interface Props extends RouteComponentProps {}

interface State {
  isLoading: boolean;
  toolbarOpen: boolean;
  organizationId: string;
  modalRemoveIsOpen: boolean;
  organizationData: {
    name: string;
    shortName: string;
    category: string;
    appVersion: string;
    theme: string;
    logo: string;
    ddlURL: string;
  };
  forms: {
    id: string;
    formName: string;
    active: string;
    deletedAt: string;
    createdAt: string;
  }[];
}

class ListOrganizationWellnessForm extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      toolbarOpen: localStorage.getItem('toolbarOpen') === 'true' ? true : false,
      organizationId: props.match.params.organizationId,
      isLoading: false,
      modalRemoveIsOpen: false,
      organizationData: {
        name: '',
        shortName: '',
        category: '',
        appVersion: '',
        theme: '',
        logo: '',
        ddlURL: '',
      },
      forms: [],
    };
  }

  componentDidMount() {
    this.getOrganization();
  }

  private getOrganization = async () => {
    const { organizationId } = this.state;

    try {
      const response = await listAllUserOrgAdminsActivateDeactivate(organizationId);
      const organizationData = response.data.organization;
      this.setState({ organizationData });

      this.getFormsByOrganization();
    } catch (error) {
      console.log(error);
      swal('Error', 'Error loading organization', 'error');
    }
  };

  private getFormsByOrganization = async () => {
    const {
      organizationId,
    } = this.state;

    const response: any = await FormApi.getFormsByOrganizationId(organizationId)
  
    const forms = response.data.map((form, index) => {
      return {
        index: index + 1,
        ...form,
        href: `/qactual/admin/organizations/${organizationId}/form/${form.id}`,
        active: form.active ? 'Yes' : 'No',
        deletedAt: form.deletedAt ? form.deletedAt : 'N/A',
      }
    })
    this.setState({ forms });
  };

  private handleDrawerOpen = () => {
    localStorage.setItem('toolbarOpen', 'true');
    this.setState({ toolbarOpen: true });
  };

  private handleDrawerClose = () => {
    localStorage.setItem('toolbarOpen', 'false');
    this.setState({ toolbarOpen: false });
  };

  private handleBackClick = () => {
    this.props.history.push(`/qactual/admin/organizations/${this.state.organizationId}`);
  }

  render () {
    const {
      organizationId,
      organizationData,
      forms,
    } = this.state;

    const columns = [
      {
        name: '#',
        key: 'index',
        type: 'link',
        cellStyle: {
          width: '40%',
        }
      },
      {
        name: 'Name',
        key: 'formName',
        type: 'string',
      },
      {
        name: 'Active',
        key: 'active',
        type: 'string',
      },
      {
        name: 'Deactivated At',
        key: 'deletedAt',
        type: 'string',
      },
    ];

    return (
      <div className='root'>
        <CssBaseline />
        <AppBar
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerOpen={this.handleDrawerOpen}
          breadcrumbs={[]}
        />
        <LocalDrawer
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerClose={this.handleDrawerClose}
          logout={logout}
        />
        <main className='content'>
          <div className='toolbarIcon' />
          <Container maxWidth="lg" className='container'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div style={{ float: 'left' }}>
                  <Typography variant="h4" display="block" gutterBottom>
                    {`Organization - ${organizationData.name}`}
                  </Typography>
                </div>
                <ButtonGroup
                  color="primary"
                  aria-label='outlined primary button group'
                  style={{float: 'right'}} >
                  <Button
                    onClick={() => this.handleBackClick()}>
                    <ArrowBack fontSize="small" />
                    &nbsp;Back
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className='paper' elevation={3}>
                  <Table
                    title='Forms'
                    columns={columns}
                    data={forms}
                    addHref={`/qactual/admin/organizations/${organizationId}/form/add`}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </main>
      </div>
    );
  }
}

export default ListOrganizationWellnessForm;
