import React from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  CssBaseline,
  Container,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { UserInfo, BoxPatient } from "../../Components";
import { Link } from "react-router-dom";

// API
import { getAllMyPatients, getById } from "../../services/patient";
import AddPatient from "../Patient/AddPatient";
import FilterChartsReport from "./Components/FilterChartsReport";
//Websocket
import { wsClient } from "../../helpers/websockets";
import { getAuthUser } from "../../services/auth";
import { getSeatBatches } from "../../services/organization";
import swal from "sweetalert";

interface Props extends RouteComponentProps {}

interface State {
  openAdd: boolean;
  patients: any;
  user: any;
  loadingAdd: boolean;
}

class CareProvider extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      openAdd: false,
      patients: [],
      user: {
        id: "",
        name: "",
        username: "",
        OrganizationId: "",
      },
      loadingAdd: false,
    };
  }

  componentDidMount() {
    this.getPatients();
    this.getUser();

    wsClient.onmessage = (data: any) => {
      data = JSON.parse(data.data);
      console.log(data);
      switch (data.op) {
        case "updateStatusPatient": {
          this.getPatient(data.patientId);
          break;
        }
        default:
          break;
      }
    };
  }

  private setOpenAdd = (openAdd: boolean) => this.setState({ openAdd });

  private setPatients = (patients: any) => this.setState({ patients });

  private setUser = (user: any) => this.setState({ user });

  private setLoadingAdd = (loadingAdd: boolean) =>
    this.setState({ loadingAdd });

  private getUser = async () => {
    try {
      await getAuthUser().then((resp) => {
        if (resp.data.user) {
          this.setUser(resp.data.user);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  private getRemainingSeatsBatches = async () => {
    let remainingSeatsTotal = 0;

    try {
      const { OrganizationId } = this.state.user;
      const response = await getSeatBatches(OrganizationId || "");
      const organizationSeatsBatches = response.data.list;

      organizationSeatsBatches.forEach((item) => {
        remainingSeatsTotal += item.numberOfTokens - item.seatsOccupied;
      });
    } catch (error) {
      console.log(error);
    }
    return remainingSeatsTotal;
  };

  private getPatient = (patientId: string) => {
    getById(patientId).then((resp) => {
      const patient = resp.data.patient;
      if (resp.data.patient) {
        const { patients } = this.state;

        let latestEvent = "";
        if (patient.Events[0].acknowledged) {
          latestEvent = "gray";
        } else {
          switch (patient.Events[0].EventCategory.name) {
            case "redAlert": {
              latestEvent = "red";
              break;
            }
            case "whiteAlert": {
              latestEvent = "white";
              break;
            }

            case "blackEvent": {
              latestEvent = "black";
              break;
            }

            case "yellowEvent": {
              latestEvent = "yellow";
              break;
            }

            default: {
              latestEvent = "gray";
              break;
            }
          }
        }

        // Replace the patient on the patients list
        for (let i = 0; i < patients.length; i += 1) {
          if (patients[i].id === patient.id) {
            patients[i].PHQ29s = patient.PHQ29s;
            patients[i].name = patient.name;
            patients[i].latestEvent = latestEvent;
          }
        }
        this.setPatients(patients);
      }
    });
  };

  private getPatients = () => {
    getAllMyPatients().then((resp) => {
      if (resp?.data?.patients) {
        const processedPatients = [];
        resp.data.patients.forEach((patient) => {
          let latestEvent = "";
          if (patient.Events.length) {
            if (patient.Events[0].acknowledged) {
              latestEvent = "gray";
            } else {
              switch (patient.Events[0].EventCategory.name) {
                case "redAlert": {
                  latestEvent = "red";
                  break;
                }

                case "whiteAlert": {
                  latestEvent = "white";
                  break;
                }

                case "blackEvent": {
                  latestEvent = "black";
                  break;
                }

                case "yellowEvent": {
                  latestEvent = "yellow";
                  break;
                }

                default: {
                  latestEvent = "gray";
                  break;
                }
              }
            }
          }

          processedPatients.push({
            PHQ29s: patient.PHQ29s,
            name: patient.name,
            latestEvent,
            id: patient.id,
          });
        });

        this.setPatients(processedPatients);
      }
    });
  };

  private handleOpenAdd = () => this.setOpenAdd(true);

  private handleCloseAdd = () => this.setOpenAdd(false);

  private handleAddPatient = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    this.setLoadingAdd(true);
    const totalRemainingSeatsBatches = await this.getRemainingSeatsBatches();
    if (totalRemainingSeatsBatches) {
      this.handleOpenAdd();
    } else {
      swal("Error", "There are no free seats available", "error");
    }
    this.setLoadingAdd(false);
  };

  render() {
    const { openAdd, patients, user, loadingAdd } = this.state;

    return (
      <>
        <CssBaseline />
        {user && <UserInfo user={user} />}
        <Container>
          <AddPatient
            open={openAdd}
            handleClose={this.handleCloseAdd}
            handleOpen={this.handleOpenAdd}
            handleReload={() => this.getPatients()}
            patients={patients}
          />
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            wrap="wrap"
            style={{
              marginTop: "50px",
            }}
          >
            <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
              {loadingAdd ? (
                <CircularProgress />
              ) : (
                <Link
                  to="#"
                  onClick={this.handleAddPatient}
                  className="btn-add-patient"
                >
                  ADD PATIENT
                </Link>
              )}
            </Grid>
            {patients.map(({ name, id, PHQ29s, latestEvent }, index) => {
              return (
                <Grid item xs={12} sm={6} md={3} lg={2} xl={2} key={index}>
                  <BoxPatient
                    name={name}
                    status_alert={latestEvent}
                    PHQ29s={PHQ29s}
                    uuid={id}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Grid container style={{ marginTop: "20px", fontWeight: "bold" }}>
            <FilterChartsReport />
            <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
              <Grid container spacing={4} alignItems="center">
                <Grid item>
                  <span className="box-label-status-patient-gray"></span>{" "}
                  Neutral
                </Grid>
                <Grid item>
                  <span className="box-label-status-patient-yellow"></span>{" "}
                  Elevated
                </Grid>
                <Grid item>
                  <span className="box-label-status-patient-white"></span> Needs
                  Assistance
                </Grid>
                <Grid item>
                  <span className="box-label-status-patient-red"></span>{" "}
                  <span> Emergency </span>
                </Grid>
                <Grid item>
                  <span className="box-label-status-patient-black"></span> No
                  Assistance from Team
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}

export default CareProvider;
