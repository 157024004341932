import React, { FC, useEffect, useState } from 'react';
import { forgotPassword, } from '../../services/auth';
import { RouteComponentProps, useHistory } from 'react-router';
import swal from 'sweetalert';
import {
  CssBaseline,
  Container,
  Grid,
  TextField,
  Button,
  Box,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../../assets/images/logoTitleGreen.svg';
import { useLocation } from 'react-router-dom';
import * as SanitizeString from '../../services/sanitize_strings';
import { Formik } from 'formik';
import * as yup from 'yup';
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
    '&$focused': {
      backgroundColor: '#fff',
    },
    '& label.Mui-focused': {
      color: '#000',
      fontWeight: 'bold',
      fontSize: '150%',
      marginTop: -10,
    },
    '& .MuiInputLabel-asterisk': {
      color: '#9e3636',
    }
  }
});

const ForgotPassword: FC<RouteComponentProps> = (props) => {

  let { pathname } = useLocation();

  const [email, setEmail] = useState < string > ('');
  const [isLoading, setIsLoading] = useState < boolean > (false);
  const [destination, setDestination] = useState < string > ('/');
  const [backDestination, setBackDestination] = useState < string > ('/');

  const history = useHistory();

  useEffect(() => {
    switch (pathname) {
      case ('/qactual/admin/forgotPassword'):
        setDestination('/qactual/admin');
        setBackDestination('/qactual/admin/login');
        break;

      case ('/organizationManager/forgotPassword'):
        setDestination('/organizationManager');
        setBackDestination('/organizationManager/login');
        break;

      case ('/cliniciandashboard/forgotPassword'):
        setDestination('/cliniciandashboard');
        setBackDestination('/cliniciandashboard/login');
        break;

      default:
        break;
    }
  },[pathname]);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      const result = await forgotPassword({ email: values.email });

      if (result?.data?.status === 'error') {
        swal("Error", result?.data?.err?.message, "error");
        setIsLoading(false);
      } else {
        localStorage.setItem('forgotPassword', email);
        setIsLoading(false);
        history.push(`${destination}/confirmForgotPassword`);
      }

    } catch (error) {
      console.log()
      swal("Error", "Something went wrong!!", "error");
      setIsLoading(false);
    }

  }

  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <div>
          <a href={backDestination}>
            <img src={Logo} alt="Logo" className="logo-login" />
          </a>
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={yup.object().shape({
              email: yup.string().email().required("You must enter a valid email"),
            })}
            onSubmit = {(values) => handleSubmit(values)}
          >
            {({values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit}) => (
            <form onSubmit={handleSubmit}>
              <Box mt={3}>
                <TextField
                  className={classes.root}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setEmail(SanitizeString.email(e.target.value));
                  }}
                  value={values.email}
                  error={errors.email && touched.email}
                  helperText={touched.email && errors.email}
                  autoComplete="email"
                  autoFocus
                />
              </Box>
              <Box mt={3}>
                <Grid container justify="center">
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      size="large"
                      endIcon={isLoading ? <CircularProgress size={24} /> : <SendIcon />}
                    >
                      Receive code to renew password
                  </Button>
                  </Grid>
                </Grid>
              </Box>
            </form>
            )}
          </Formik>
        </div>
      </Container>
    </>
  );
};

export default ForgotPassword;
