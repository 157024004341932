import { CircularProgress } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { getActiveUserVersion } from "../../../../../services/appCenterData";

interface Props {
  dataStart: Date;
  dataEnd: Date;
  top: number;
  system: "all" | "android" | "iOS";
}

export default function ActiveUserVersionChart({
  dataStart,
  dataEnd,
  top,
  system = "all",
}: Props): JSX.Element {
  const [dataActiveUserVersion, setDataActiveUserVersion] = useState<Object>();
  const [state, setState] = useState<Object>();
  const [loading, setLoading] = useState<boolean>();

  const getDataActiveUserVersions = async () => {
    let defineData = {};

    try {
      setLoading(true);
      const { data } = await getActiveUserVersion({
        dataStart,
        dataEnd,
        top,
      });

      if (system === "all" || system === "android") {
        if (data?.data?.android) {
          data?.data.android.forEach((activeUserVersion) => {
            defineData[activeUserVersion.version] =
              activeUserVersion.count +
              (defineData[activeUserVersion.version] || 0);
          });
        }
      }
      if (system === "all" || system === "iOS") {
        if (data?.data?.iOS) {
          data?.data.iOS.forEach((activeUserVersion) => {
            defineData[activeUserVersion.version] =
              activeUserVersion.count +
              (defineData[activeUserVersion.version] || 0);
          });
        }
      }

      setDataActiveUserVersion(defineData);

      // setActiveUserVersions(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDataActiveUserVersions();
  }, [dataStart, dataEnd, top, system]);

  useEffect(() => {
    if (dataActiveUserVersion) {
      setState({
        labels: Object.keys(dataActiveUserVersion) || [],
        datasets: [
          {
            label: "Active User per Version",
            backgroundColor: "#1FAECE",
            borderColor: "rgba(0,0,0,0.5)",
            borderWidth: 1,
            data: Object.values(dataActiveUserVersion) || [],
          },
        ],
      });
    }
  }, [dataActiveUserVersion]);

  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : (
        <Bar
          data={state}
          options={{
            title: {
              display: false,
            },
            legend: {
              display: true,
              position: "right",
            },
          }}
        />
      )}
    </div>
  );
}
