import React, { FC } from 'react';

import {
  Modal,
  Grid,
  Button,
  Box,
  Typography,
} from '@material-ui/core';

import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import {getAcknowledgeEvent} from '../../../../services/event';

interface Props {
  open: boolean;
  handleClose: Function;
  takeAknowledgment: Function;
  selectedCheckbox: string;
}

const ConfirmAcknowlegdementModal: FC<Props> = (props): JSX.Element => {
  let modalLeft = `${(window.innerWidth/2)-200}px`;
  let lodalTop = `${(window.innerHeight/2)}px`;
  const handleAcknowledgeEvent = async (selectedCheckbox) => {
    try {
      await getAcknowledgeEvent(selectedCheckbox);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      aria-labelledby='modal-remove-title'
      aria-describedby='modal-remove-description'
      open={props.open}
      onClose={() => props.handleClose()}
      style={{
        position: 'absolute',
      }}
    >
      <div
        style={{
          width: '400px',
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '10px',
          position: 'fixed',
          left: modalLeft,
          top: lodalTop,
        }}
      >
        <Grid container justify="center" spacing={3}>
          <Grid item xs={8}>
            <Typography>
              Are you sure you want to take aknowledgement of the event?
            </Typography>
          </Grid>
        </Grid>
        <Box mt={3}>
          <Grid container justify="center" spacing={3}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="button"
                disabled={false}
                size="large"
                startIcon={<CancelIcon />}
                style={{
                  backgroundColor: '#000',
                  color: '#fff',
                }}
                onClick={() => props.handleClose(props.selectedCheckbox)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="button"
                size="large"
                onClick={() => {
                  handleAcknowledgeEvent(props.selectedCheckbox);
                  props.takeAknowledgment();
                }}
                startIcon={<CheckCircleOutlineIcon />}
              >
                Acknowledge
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    </Modal>
  );
};

export default ConfirmAcknowlegdementModal;
