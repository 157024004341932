import React, { FC, useState, useEffect } from 'react';
import {
    Container, Grid, Box, Button, CircularProgress, LinearProgress, Typography,
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// Images
import Logo from '../../assets/images/logoTitleGreen.svg';
// API
import { logout, getAuthUser, clearLocalStorage } from '../../services/auth';


interface Props {
  user: {
    id: string;
    name: string;
    username: string;
  };
}

const UserInfo = ({user}: Props): JSX.Element => {

    const [loading, setLoading] = useState(false);

    const handleLogout = async () => {
        setLoading(true);
        try {
            await logout('/');
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    const handleClearLocalStorage = () => {
        clearLocalStorage();
        window.location.href = '/';
    }

    return (
        <Container className="main-container">
            <Grid container>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <img src={Logo} alt="Logo" style={{ width: "90%" }} />
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                    <Box className="box-access-user" style={{ float: 'right' }}>
                        {user.id ? (
                            <>
                                <h2>{user.name}</h2>
                                <h2>{user.username}</h2>
                                <Button
                                    variant="contained"
                                    onClick={handleLogout}
                                    disabled={loading}
                                    startIcon={loading ? <CircularProgress size={24} /> : <ExitToAppIcon />}
                                >
                                    Logout
                                </Button>
                            </>
                        ) : (
                            <>
                                <Typography align="center">
                                    LOADING...
                                </Typography>
                                <LinearProgress color="secondary" />
                                <br/>
                                <Button
                                    variant="contained"
                                    onClick={handleClearLocalStorage}
                                >
                                    Go to login
                                </Button>
                            </>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}


export default UserInfo;