import { Badge } from '@material-ui/core';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  name: string,
  status_alert: string,
  uuid: number,
  PHQ29s: boolean
}
class BoxPatient extends Component<Props> {

  render() {
    const getStatusAlertClass = (status: string) => {
      switch (status) {
        case 'gray':
          return 'card-patient-gray';
        case 'yellow':
          return 'card-patient-yellow';
        case 'red':
          return 'card-patient-red';
        case 'white':
          return 'card-patient-white';
        case 'black':
          return 'card-patient-black';
        default:
          return 'card-patient-gray';
      }
    }

    const Box = () => {
      const { PHQ29s } = this.props;

      const to = PHQ29s
        ? `/cliniciandashboard/patient/${this.props.uuid}/addPHQ29`
        : `/cliniciandashboard/patient/${this.props.uuid}`;

      return (
        <Link
          to={to}
          className={getStatusAlertClass(this.props.status_alert)}
        >
          {this.props.name}
        </Link>
      );
    }

    return (
      <>
        { this.props.PHQ29s ? (
        <Badge color="secondary" badgeContent="PHQ29" overlap="circle" className="badge-phq29">
          <Box />
        </Badge>) : (<Box />)}

      </>
    );
  }
}
export default BoxPatient;