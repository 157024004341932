import React from 'react';
import { RouteComponentProps } from "react-router-dom";
import swal from 'sweetalert';
import {
  CssBaseline,
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  ButtonGroup,
} from '@material-ui/core';
import { Edit, RemoveCircle, ArrowBack } from '@material-ui/icons';
import LocalDrawer from '../../../Components/QActualAdminLocalDrawer';
import AppBar from '../../../Components/AppBar';
import Table from '../../../Components/Table';
import RemoveModal from '../../../Components/RemoveModal';

// CALL TO API
import { logout } from '../../../services/auth';
import { listAllUserOrgAdminsActivateDeactivate, deleteOrgAdmins } from  './../../../services/organization';

interface Props extends RouteComponentProps {}

interface State {
  isLoading: boolean;
  toolbarOpen: boolean;
  organizationId: string;
  modalRemoveIsOpen: boolean;
  organizationData: {
    name: string;
    shortName: string;
    category: string;
    appVersion: string;
    theme: string;
    logo: string;
    ddlURL: string;
    domainFilter: string;
  };
  orgManagersData: {
    id: string;
    name: string;
    phone: string;
    obsolete: string;
  }[];
}

class ListAllUsersOrganization extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      toolbarOpen: localStorage.getItem('toolbarOpen') === 'true' ? true : false,
      organizationId: props.match.params.organizationId,
      isLoading: false,
      modalRemoveIsOpen: false,
      organizationData: {
        name: '',
        shortName: '',
        category: '',
        appVersion: '',
        theme: '',
        logo: '',
        ddlURL: '',
        domainFilter: '',
      },
      orgManagersData: [],
    };
  }

  componentDidMount() {
    this.getOrganization();
  }

  private getOrganization = async () => {
    const { organizationId } = this.state;

    try {
      const response = await listAllUserOrgAdminsActivateDeactivate(organizationId);
      const organizationData = response.data.organization;
      const orgManagersData = organizationData.Users.map((user) => {
        return { ...user, href: `/qactual/admin/organizations/${organizationId}/manager/${user.id}`, obsolete: user.obsolete ? 'No' : 'Yes' }
      });
      this.setState({ organizationData, orgManagersData });
    } catch (error) {
      console.log(error);
      swal('Error', 'Error loading organization', 'error');
    }
  };

  private handleDrawerOpen = () => {
    localStorage.setItem('toolbarOpen', 'true');
    this.setState({ toolbarOpen: true });
  };

  private handleDrawerClose = () => {
    localStorage.setItem('toolbarOpen', 'false');
    this.setState({ toolbarOpen: false });
  };

  private handleOpenRemoveModal = () => {
    this.setState({ modalRemoveIsOpen: true });
  }

  private confirmRemove = async () => {
    this.setState({ modalRemoveIsOpen: false, isLoading: true });

    try {
      const response = await deleteOrgAdmins(this.state.organizationId);
      if ( response.data.status === "success" ) {
        this.setState({ isLoading: false });
        swal("OK", "Record deleted successfully", "success");
        this.props.history.push(`/qactual/admin/organizations`);
      }
    } catch (error) {
      console.log(error);
      this.setState({ modalRemoveIsOpen: false, isLoading: false });
      swal('Error', 'Error loading organization', 'error');
    }
  }

  private dismissRemove = () => {
    this.setState({ modalRemoveIsOpen: false });
  }

  private handleEditClick = () => {
    const {
      organizationId,
    } = this.state;

    this.props.history.push(`/qactual/admin/organizations/${organizationId}/edit`);
  }

  private handleSeatsManagementClick = () => {
    const {
      organizationId,
    } = this.state;

    this.props.history.push(`/qactual/admin/organizations/${organizationId}/batches`);
  }

  private handleBackClick = () => {
    this.props.history.push('/qactual/admin/organizations/');
  }

  render () {
    const {
      organizationId,
      organizationData,
      orgManagersData,
    } = this.state;

    const columns = [
      {
        name: 'Name',
        key: 'name',
        type: 'link',
      },
      {
        name: 'Email',
        key: 'username',
        type: 'string',
      },
      {
        name: 'Phone Number',
        key: 'phone',
        type: 'string',
      },
      {
        name: 'Activated?',
        key: 'obsolete',
        type: 'boolean',
      }
    ];

    return (
      <div className='root'>
        <CssBaseline />
        <AppBar
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerOpen={this.handleDrawerOpen}
          breadcrumbs={[]}
        />
        <LocalDrawer
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerClose={this.handleDrawerClose}
          logout={logout}
        />
        <main className='content'>
          <div className='toolbarIcon' />
          <Container maxWidth="lg" className='container'>
            <Grid container spacing={3}>
            <Grid item xs={12}>
                <div style={{ float: 'left'}}>
                  <Typography variant="h4" display="block" gutterBottom>
                    {`Organization - ${organizationData.name}`}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
            
              </Grid>
              <Grid item xs={12}>    
              <Button
                    color="primary"
                    aria-label='outlined primary button'
                    onClick={() => this.handleSeatsManagementClick()}
                    style={{                      
                      marginRight: 20,
                      borderStyle: 'solid',
                      borderColor: 'gray',                  
                      borderWidth: 1,
                      paddingLeft: 20,
                      paddingRight: 20,
                    }}
                  >
                    Seats Management
                </Button>
                <Button
                  color="primary"
                  aria-label='outlined primary button'
                  onClick={() => this.props.history.push(`/qactual/admin/organizations/${organizationId}/resources`)}
                  style={{
                    
                    marginRight: 20,
                    borderStyle: 'solid',
                    borderColor: 'gray',
                    borderWidth: 1,
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                >
                  Resources
                </Button>

                <Button
                  color="primary"
                  aria-label='outlined primary button'
                  onClick={() => this.props.history.push(`/qactual/admin/organizations/${organizationId}/form`)}
                  style={{
                    
                    marginRight: 20,
                    borderStyle: 'solid',
                    borderColor: 'gray',
                    borderWidth: 1,
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                >
                  Wellness Forms
                </Button>
                <ButtonGroup
                  color="primary"
                  aria-label='outlined primary button group'
                  style={{float: 'right'}} >
                  <Button
                    onClick={() => this.handleBackClick()}>
                    <ArrowBack fontSize="small" />
                    &nbsp;Back
                  </Button>
                  <Button
                      onClick={() => this.handleEditClick()}>
                    <Edit fontSize="small" />
                    &nbsp;Edit
                  </Button>
                  <Button onClick={() => this.handleOpenRemoveModal()}>
                    <RemoveCircle
                      fontSize="small"
                      style={{ color: '#900707' }}
                    />
                    &nbsp;Remove
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className='paper' elevation={3}>
                  <Typography variant='overline' display='block' gutterBottom>
                    Name
                  </Typography>
                  <Typography variant="h6" display="block" gutterBottom>
                    {this.state.organizationData.name}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className='paper' elevation={3}>
                  <Typography variant='overline' display='block' gutterBottom>
                    DDL URL
                  </Typography>
                  <Typography variant="h6" display="block" gutterBottom>
                    {this.state.organizationData.ddlURL}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className='paper' elevation={3}>
                  <Typography variant='overline' display='block' gutterBottom>
                    Short Name
                  </Typography>
                  <Typography variant="h6" display="block" gutterBottom>
                    {this.state.organizationData.shortName}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className='paper' elevation={3}>
                  <Typography variant='overline' display='block' gutterBottom>
                    Distribution Category
                  </Typography>
                  <Typography variant="h6" display="block" gutterBottom>
                    {this.state.organizationData.category}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            {this.state.organizationData.category === 'closed' && <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className='paper' elevation={3}>
                  <Typography variant='overline' display='block' gutterBottom>
                    Domain Filter
                  </Typography>
                  <Typography variant="h6" display="block" gutterBottom>
                    {this.state.organizationData.domainFilter}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className='paper' elevation={3}>
                  <Typography variant='overline' display='block' gutterBottom>
                    App Version
                  </Typography>
                  <Typography variant="h6" display="block" gutterBottom>
                    {this.state.organizationData.appVersion}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className='paper' elevation={3}>
                  <Typography variant='overline' display='block' gutterBottom>
                    Default Theme
                  </Typography>
                  <Typography variant="h6" display="block" gutterBottom>
                    {this.state.organizationData.theme}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className='paper' elevation={3}>
                  <Typography variant='overline' display='block' gutterBottom>
                    Logo
                  </Typography>
                  <div
                    style={{
                      width: 600,
                      height: 450,
                      border: 'solid',
                      borderRadius: 6,
                      margin: 'auto',
                      borderColor: '#c4c4c4',
                      position: 'relative',
                    }}
                  >
                    {(this.state.organizationData.logo ? (
                      <img
                        src={`https://qactual-global-organizations-logos.s3.amazonaws.com/${this.state.organizationData.logo}.png`}
                        width="100%"
                        style={{
                          margin: 0,
                          position: 'absolute',
                          top: '50%',
                          transform: 'translateY(-50%)',
                        }}
                      />
                    ): null)}
                  </div>
                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className='paper' elevation={3}>
                  <Table
                    title='Organization Managers'
                    columns={columns}
                    data={orgManagersData}
                    addHref={`/qactual/admin/organizations/${organizationId}/add`}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </main>
        <RemoveModal
          modalRemoveIsOpen={this.state.modalRemoveIsOpen}
          handleModalRemoveClose={this.handleDrawerClose}
          confirmRemove={this.confirmRemove}
          dismissRemove={this.dismissRemove}
        />
      </div>
    );
  }

}

export default ListAllUsersOrganization;
