import React from 'react';

import { AppBar, Toolbar, IconButton } from '@material-ui/core';

import { Menu } from '@material-ui/icons';

import Breadcrumbs from '../Breadcrumbs';

import clsx from 'clsx';

interface Props {
  handleDrawerOpen: Function;
  toolbarOpen: boolean;
  breadcrumbs: {
    name: string;
    href: string;
  }[];
}

class AppBar_ extends React.Component<Props> {

  renderBreadcrumbs = () => {
    const { breadcrumbs } = this.props;

    return ( breadcrumbs ) ? <Breadcrumbs itens={breadcrumbs} /> : null;
  }

  render () {
    const { handleDrawerOpen, toolbarOpen } = this.props;

    return (
      <AppBar position="absolute" className={clsx('appBar', toolbarOpen && 'appBarShift')}>
        <Toolbar className='toolbar'>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='open drawer'
            className={clsx('menuButton', toolbarOpen && 'menuButtonHidden')}
            onClick={() => handleDrawerOpen()}
          >
            <Menu />
          </IconButton>
          {this.renderBreadcrumbs()}
        </Toolbar>
      </AppBar>
    );
  }
}

export default AppBar_;
