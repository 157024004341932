import React, { FC, useState } from "react";
import swal from "sweetalert";
import {
  Modal,
  Grid,
  TextField,
  Button,
  createStyles,
  makeStyles,
  Box,
  CircularProgress,
  ButtonGroup,
  Typography,
} from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SaveIcon from "@material-ui/icons/Save";
import { Formik } from "formik";
import * as SanitizeString from "../../../services/sanitize_strings";

// API
import { createPatient, findPatient } from "../../../services/patient";

import * as yup from "yup";
require("yup-phone");

interface Props {
  open: boolean;
  handleClose: Function;
  handleOpen: Function;
  handleReload: Function;
  patients?: any[];
}

const objectPatientData = {
  id: "",
  email: "",
  name: "",
  phoneNumber: "",
};

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      position: "absolute",
      width: 600,
      backgroundColor: "#EAEAEA",
      border: "2px solid #000",
      padding: 20,
      paddingTop: "3%",
      paddingBottom: "3%",
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
    },
  })
);

const AddPatient: FC<Props> = (props): JSX.Element => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const [action, setAction] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // stateSearch
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [patientData, setPatientData] = useState(objectPatientData);
  const [findPhoneValid, setFindPhoneValid] = useState(false);
  const classes = useStyles();

  const handleSubmit = async (values) => {
    setIsLoading(true);

    const patient = {
      name: values.name,
      email: SanitizeString.email(values.email),
      phoneNumber: SanitizeString.phoneNumber(values.phoneNumber),
    };

    await createPatient(patient).then((resp) => {
      if (resp.data.status === "success") {
        swal("OK", "Registered successfully", "success");
      } else {
        swal("ERROR", resp.data.message, "error");
      }
      props.handleClose();
      props.handleReload();
      handleFormReset();
      setIsLoading(false);
    });
  };

  // Handles Form Search
  const handleSubmitFind = async (event) => {
    event.preventDefault();

    if (!!patientData.id && !!!hasPatient()) {
      await handleSubmit(patientData);
    }
  };

  const startIconButton = (value: number) =>
    action === value ? (
      <CheckCircleOutlineIcon />
    ) : (
      <RadioButtonUncheckedIcon />
    );

  const handleFindPatientByEmail = async (email: string) => {
    let value = SanitizeString.email(email);
    setEmail(value);

    await handleFindPatient(value);
  };

  const handleFindPatientByPhone = async (phoneNumber: string) => {
    let value = SanitizeString.phoneNumber(phoneNumber);
    if (value) {
      const phoneSchema = yup.string().phone("IN").matches(/^\+/).required();

      (async () => {
        setFindPhoneValid(await phoneSchema.isValid(value));
      })();
    }

    setPhoneNumber(value);
    await handleFindPatient("", value);
  };

  const handleFindPatient = async (
    email: string | "",
    phoneNumber?: string | ""
  ) => {
    await findPatient({ email, phoneNumber }).then((resp) => {
      if (resp.data.patient) {
        setPatientData(resp.data.patient);
      } else {
        setPatientData(objectPatientData);
      }
    });
  };

  // set default values
  const handleFormReset = () => {
    setAction(0);
    setEmail("");
    setPhoneNumber("");
    setPatientData(objectPatientData);
    setShowForm(false);
  };

  const hasPatient = () => {
    return props.patients.length && patientData.id
      ? props.patients.find((p) => p.id === patientData.id)
      : false;
  };

  const formSearch = () => (
    <div className={classes.paper}>
      <form onSubmit={handleSubmitFind}>
        <Grid container justify="center" spacing={3}>
          <Grid item xs={8}>
            <Typography
              variant="h6"
              gutterBottom
              style={{ textAlign: "center" }}
            >
              SEARCH PATIENT BY
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <ButtonGroup
              disableElevation
              size="large"
              variant="contained"
              color="primary"
              aria-label="large outlined primary button group"
            >
              <Button
                onClick={() => {
                  setAction(0);
                  setEmail("");
                  setPatientData(objectPatientData);
                }}
                disabled={action === 0}
                startIcon={startIconButton(0)}
              >
                PHONE
              </Button>
              <Button
                onClick={() => {
                  setPhoneNumber("");
                  setAction(1);
                  setPatientData(objectPatientData);
                }}
                disabled={action === 1}
                startIcon={startIconButton(1)}
              >
                EMAIL
              </Button>
            </ButtonGroup>
          </Grid>
          {action === 1 && (
            <Grid item xs={8}>
              <TextField
                name="email"
                id="email"
                label="Email"
                variant="outlined"
                onChange={(e) => handleFindPatientByEmail(e.target.value)}
                value={email}
                fullWidth
                autoComplete="email"
                autoFocus
              />
            </Grid>
          )}
          {action === 0 && (
            <Grid item xs={8}>
              <TextField
                id="phoneNumber"
                label="Phone"
                variant="outlined"
                name="phoneNumber"
                onChange={(e) => handleFindPatientByPhone(e.target.value)}
                value={phoneNumber}
                fullWidth
                autoComplete="phoneNumber"
                autoFocus
              />
              {!findPhoneValid && phoneNumber && "Number phone invalid"}
            </Grid>
          )}
        </Grid>
        <Box mt={3}>
          {patientData.id ? (
            <>
              <Grid container justify="center" spacing={3}>
                <Grid item>
                  <Typography variant="subtitle1" gutterBottom>
                    {patientData.name} ({patientData.email})
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    Phone: {patientData.phoneNumber}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justify="center" spacing={3}>
                <Grid item>
                  {!hasPatient() ? (
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      size="large"
                      startIcon={isLoading && <CircularProgress size={24} />}
                    >
                      SEND INVITE
                    </Button>
                  ) : (
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ color: "green" }}
                    >
                      PATIENT ALREADY REGISTERED
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid container justify="center" spacing={3}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={() => setShowForm(true)}
                  size="large"
                  disabled={(!email && !phoneNumber) || !findPhoneValid}
                  endIcon={<ArrowForwardIosIcon />}
                >
                  CONFIRM AND NEXT STEP
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
      </form>
    </div>
  );

  const formBody = () => (
    <div className={classes.paper}>
      <Formik
        initialValues={{
          name: "",
          email: email,
          phoneNumber: phoneNumber,
        }}
        validationSchema={yup.object().shape({
          name: yup.string().required("Must enter a name for the user"),
          phoneNumber: yup
            .string()
            .phone()
            .matches(
              /^\+[0-9]*$/,
              'For phone numbers please add the "+" at the front followed by just numbers'
            )
            .required("Must enter a phone for the user"),
        })}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container justify="center" spacing={3}>
              <Grid item xs={8}>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ textAlign: "center" }}
                >
                  ADD PATIENT
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  id="name"
                  label="Name"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  fullWidth
                  error={errors.name && touched.name}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  id="phoneNumber"
                  label="Phone"
                  type="tel"
                  variant="outlined"
                  placeholder="Example +12025550185"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phoneNumber}
                  fullWidth
                  error={errors.phoneNumber && touched.phoneNumber}
                  helperText={
                    (touched.phoneNumber && errors.phoneNumber) ||
                    `Use the international standard (Example +12025550185)`
                  }
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  id="email"
                  label="Email"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  fullWidth
                  error={errors.email && touched.email}
                  helperText={touched.email && errors.email}
                />
              </Grid>
            </Grid>

            <Box mt={3}>
              <Grid container justify="center" spacing={3}>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    onClick={() => setShowForm(false)}
                    startIcon={<ArrowBackIosIcon />}
                  >
                    PREVIOUS STEP
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    startIcon={
                      isLoading ? <CircularProgress size={24} /> : <SaveIcon />
                    }
                  >
                    SEND INVITE
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        )}
      </Formik>
    </div>
  );

  return (
    <Modal
      open={props.open}
      onClose={() => {
        handleFormReset();
        props.handleClose();
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {showForm ? formBody() : formSearch()}
    </Modal>
  );
};

export default AddPatient;
