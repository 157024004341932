import React from 'react';
import { RouteComponentProps } from "react-router-dom";
import swal from 'sweetalert';
import {
  CssBaseline,
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  ButtonGroup,
} from '@material-ui/core';
import { Edit, RemoveCircle, ArrowBack } from '@material-ui/icons';
import LocalDrawer from '../../../Components/QActualAdminLocalDrawer';
import AppBar from '../../../Components/AppBar';
import Table from '../../../Components/Table';
import RemoveModal from '../../../Components/RemoveModal';

// CALL TO API
import { logout } from '../../../services/auth';
import { listAllUserOrgAdminsActivateDeactivate, deleteResourceOrganization } from  '../../../services/organization';
import API from '../../../services/api';

interface Props extends RouteComponentProps {}

interface State {
  isLoading: boolean;
  toolbarOpen: boolean;
  organizationId: string;
  modalRemoveIsOpen: boolean;
  organizationData: {
    name: string;
    shortName: string;
    category: string;
    appVersion: string;
    theme: string;
    logo: string;
    ddlURL: string;
  };
  resources: {
    id: string;
    title: string;
    description: string;
    phoneNumber: string;
    message: string;
    website: string;
  }[];
}

class ListResourcesOrganization extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      toolbarOpen: localStorage.getItem('toolbarOpen') === 'true' ? true : false,
      organizationId: props.match.params.organizationId,
      isLoading: false,
      modalRemoveIsOpen: false,
      organizationData: {
        name: '',
        shortName: '',
        category: '',
        appVersion: '',
        theme: '',
        logo: '',
        ddlURL: '',
      },
      resources: [],
    };
  }

  componentDidMount() {
    this.getOrganization();
  }

  private getOrganization = async () => {
    const { organizationId } = this.state;

    try {
      const response = await listAllUserOrgAdminsActivateDeactivate(organizationId);
      const organizationData = response.data.organization;
      this.setState({ organizationData });

      this.getOrganizationResources(organizationData.resourceGroupId);
    } catch (error) {
      console.log(error);
      swal('Error', 'Error loading organization', 'error');
    }
  };

  private getOrganizationResources = async (resourceGroupId: string) => {
    const {
      organizationId,
    } = this.state;

    if (!resourceGroupId) {
      // Creates a new resourceGroupId
      const response: any = await API.post('/resources/resourceGroup', {
        organizationId,
      }).catch(() => {
        console.log('Unable to create resource group');
      });
      resourceGroupId = response.data.resourceGroupId; 
    }

    // Get the resources
    const response: any = await API.get(`/resources/resourcesByGroup/${resourceGroupId}`).catch(() => {
      console.log('Unable to get resources');
    });
    let resources = response.data.resources;

    resources = resources.map((resource, index) => {
      return {
        index: (index+1),
        ...resource,
        href: `/qactual/admin/organizations/${organizationId}/resources/${resource.id}`
      };
    });

    this.setState({ resources });
  };

  private handleDrawerOpen = () => {
    localStorage.setItem('toolbarOpen', 'true');
    this.setState({ toolbarOpen: true });
  };

  private handleDrawerClose = () => {
    localStorage.setItem('toolbarOpen', 'false');
    this.setState({ toolbarOpen: false });
  };

  private confirmRemove = async (resourceId: string) => {
    this.setState({ modalRemoveIsOpen: false, isLoading: true });

    try {
      const response = await deleteResourceOrganization(this.state.organizationId, resourceId);
      if ( response.data.status === "success" ) {
        this.setState({ isLoading: false });
        swal("OK", "Record deleted successfully", "success");
        this.props.history.push(`/qactual/admin/organizations/${this.state.organizationId}/resources`);
      }
    } catch (error) {
      console.log(error);
      this.setState({ modalRemoveIsOpen: false, isLoading: false });
      swal('Error', 'Error loading organization', 'error');
    }
  }

  private dismissRemove = () => {
    this.setState({ modalRemoveIsOpen: false });
  }

  private handleBackClick = () => {
    this.props.history.push('/qactual/admin/organizations/');
  }

  render () {
    const {
      organizationId,
      organizationData,
      resources,
    } = this.state;

    const columns = [
      {
        name: '#',
        key: 'index',
        type: 'link',
      },
      {
        name: 'Title',
        key: 'title',
        type: 'string',
      },
      {
        name: 'Description',
        key: 'description',
        type: 'string',
      },
      {
        name: 'Phone Number',
        key: 'phoneNumber',
        type: 'string',
      },
      {
        name: 'Message',
        key: 'textMessage',
        type: 'string',
      },
      {
        name: 'Website',
        key: 'website',
        type: 'string',
      },
    ];

    return (
      <div className='root'>
        <CssBaseline />
        <AppBar
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerOpen={this.handleDrawerOpen}
          breadcrumbs={[]}
        />
        <LocalDrawer
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerClose={this.handleDrawerClose}
          logout={logout}
        />
        <main className='content'>
          <div className='toolbarIcon' />
          <Container maxWidth="lg" className='container'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div style={{ float: 'left' }}>
                  <Typography variant="h4" display="block" gutterBottom>
                    {`Organization - ${organizationData.name}`}
                  </Typography>
                </div>
                <ButtonGroup
                  color="primary"
                  aria-label='outlined primary button group'
                  style={{float: 'right'}} >
                  <Button
                    onClick={() => this.handleBackClick()}>
                    <ArrowBack fontSize="small" />
                    &nbsp;Back
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className='paper' elevation={3}>
                  <Table
                    title='Resources'
                    columns={columns}
                    data={resources}
                    addHref={`/qactual/admin/organizations/${organizationId}/resources/add`}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </main>
        <RemoveModal
          modalRemoveIsOpen={this.state.modalRemoveIsOpen}
          handleModalRemoveClose={this.handleDrawerClose}
          confirmRemove={this.confirmRemove}
          dismissRemove={this.dismissRemove}
        />
      </div>
    );
  }
}

export default ListResourcesOrganization;
