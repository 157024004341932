import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { getActiveUsers } from "../../../../../services/appCenterData";

interface Props {
  dataStart: Date;
  dataEnd: Date;
  system: "all" | "android" | "iOS";
}

export default function ActiveUsersChart({
  dataStart,
  dataEnd,
  system = "all",
}: Props): JSX.Element {
  const [dataActiveUsers, setDataActiveUsers] = useState<Object>();
  const [state, setState] = useState<Object>();
  const [loading, setLoading] = useState<boolean>();
  const [instevalTime, setIntervalTime] = useState<"P1D" | "P1W" | "P30D">(
    "P1D"
  );

  const getDataActiveUsers = async () => {
    let defineData = {};

    try {
      setLoading(true);
      const { data } = await getActiveUsers({
        dataStart,
        dataEnd,
        interval: instevalTime,
      });

      if (system === "all" || system === "android") {
        if (data?.data?.android) {
          data?.data.android.forEach((ActiveUsers) => {
            const date = ActiveUsers.dateTime.split("T");
            defineData[date[0]] =
              ActiveUsers.count + (defineData[date[0]] || 0);
          });
        }
      }
      if (system === "all" || system === "iOS") {
        if (data?.data?.iOS) {
          data?.data.iOS.forEach((ActiveUsers) => {
            const date = ActiveUsers.dateTime.split("T");
            defineData[date[0]] =
              ActiveUsers.count + (defineData[date[0]] || 0);
          });
        }
      }

      setDataActiveUsers(defineData);

      // setActiveUsers(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDataActiveUsers();
  }, [dataStart, dataEnd, system, instevalTime]);

  useEffect(() => {
    if (dataActiveUsers) {
      setState({
        labels: Object.keys(dataActiveUsers) || [],
        datasets: [
          {
            label: "Active User",
            backgroundColor: "#1FAECE",
            borderColor: "rgba(0,0,0,0.5)",
            borderWidth: 1,
            data: Object.values(dataActiveUsers) || [],
          },
        ],
      });
    }
  }, [dataActiveUsers]);

  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : (
        <div>
          <FormControl fullWidth>
            <InputLabel id="select-system-label">Select Interval</InputLabel>
            <Select
              labelId="select-system-label"
              id="system-select"
              value={instevalTime}
              onChange={(event: any) => setIntervalTime(event.target.value)}
            >
              <MenuItem value="P1D" selected>
                1 day
              </MenuItem>
              <MenuItem value="P1W">1 week</MenuItem>
              <MenuItem value="P30D">30 days</MenuItem>
            </Select>
          </FormControl>
          <Line
            data={state}
            options={{
              title: {
                display: false,
              },
              legend: {
                display: true,
                position: "right",
              },
            }}
          />
        </div>
      )}
    </div>
  );
}
