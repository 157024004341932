import API from "./api";

export const getLanguages = async (params: {
  dataStart: Date;
  dataEnd: Date;
  top: number;
}) => await API.get("/appcenter/languages", { params });

export const getCountries = async (params: {
  dataStart: Date;
  dataEnd: Date;
  top: number;
}) => await API.get("/appcenter/countries", { params });

export const getDailySessionUser = async (params: {
  dataStart: Date;
  dataEnd: Date;
  interval: string;
}) => await API.get("/appcenter/dailySessionUser", { params });

export const getActiveUserVersion = async (params: {
  dataStart: Date;
  dataEnd: Date;
  top: number;
}) => await API.get("/appcenter/activeUserVersion", { params });

export const getActiveUsers = async (params: {
  dataStart: Date;
  dataEnd: Date;
  interval: string;
}) => await API.get("/appcenter/activeUsers", { params });
