import React from "react";
import { Modal, Button } from "@material-ui/core";

interface Props {
  modalActivateIsOpen: boolean;
  handleModalActivateClose: Function;
  dismissActivate: Function;
  confirmActivate: Function;
}

class ActivateModal extends React.Component<Props> {
  render() {
    const {
      modalActivateIsOpen,
      handleModalActivateClose,
      dismissActivate,
      confirmActivate,
    } = this.props;

    let modalLeft = `${window.innerWidth / 2 - 200}px`;
    let modalTop = `${window.innerHeight / 2}px`;

    return (
      <Modal
        aria-labelledby="modal-activate-title"
        aria-describedby="modal-activate-description"
        open={modalActivateIsOpen}
        onClose={() => handleModalActivateClose()}
        style={{
          position: "absolute",
        }}
      >
        <div
          style={{
            width: "400px",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
            position: "fixed",
            left: modalLeft,
            top: modalTop,
          }}
        >
          <h2 id="modal-activate-title">Confirm Activate User</h2>
          <p id="modal-activate-description">
            Please, confirm that you want to activate this user.
          </p>
          <Button
            style={{ float: "right" }}
            color="primary"
            onClick={() => dismissActivate()}
          >
            Cancel
          </Button>
          <Button
            style={{ float: "right" }}
            color="secondary"
            onClick={() => confirmActivate()}
          >
            Activate
          </Button>
        </div>
      </Modal>
    );
  }
}

export default ActivateModal;
