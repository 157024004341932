import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import swal from 'sweetalert';
import {
  CssBaseline,
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  ButtonGroup,
} from '@material-ui/core';
import { RemoveCircle, Edit, ArrowBack, AddCircle } from '@material-ui/icons';
import LocalDrawer from '../../../Components/QActualAdminLocalDrawer';
import AppBar from '../../../Components/AppBar';
import DeactivateModal from '../../../Components/DeactivateModal';
import ActivateModal from '../../../Components/ActivateModal';
import { green } from '@material-ui/core/colors';

// CALL TO API
import { logout, getUserWithoutObsolete, activateUser } from '../../../services/auth';
import { deleteUserOrganization } from '../../../services/organization';

interface Props extends RouteComponentProps {}

interface State {
  isLoading: boolean;
  toolbarOpen: boolean;
  organizationId: string;
  userId: string;
  modalDeactivateIsOpen: boolean;
  modalActivateIsOpen: boolean;
  userData: {
    id: string;
    name: string;
    username: string;
    phone: string;
    obsolete: string;
  };
}

class ShowUserOrganization extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      toolbarOpen: localStorage.getItem('toolbarOpen') === 'true' ? true : false,
      organizationId: props.match.params.organizationId,
      userId: props.match.params.userId,
      isLoading: false,
      modalDeactivateIsOpen: false,
      modalActivateIsOpen: false,
      userData: {
        id: '',
        name: '',
        username: '',
        phone: '',
        obsolete: '',
      },
    };
  }

  componentDidMount() {
    this.getOrganizationManager();
  }

  private getOrganizationManager = async () => {
    const { userId } = this.state;

    try {
      const resp = await getUserWithoutObsolete(userId);
      const userData = resp.data.user;
      this.setState({ userData });
    } catch (error) {
      console.log(error);
    }
  };

  private handleDrawerOpen = () => {
    localStorage.setItem('toolbarOpen', 'true');
    this.setState({ toolbarOpen: true });
  };

  private handleDrawerClose = () => {
    localStorage.setItem('toolbarOpen', 'false');
    this.setState({ toolbarOpen: false });
  };

  private handleOpenDeactivateModal = () => this.setState({ modalDeactivateIsOpen: true });
  private handleOpenActivateModal = () => this.setState({ modalActivateIsOpen: true });

  private handleBackClick = (organizationId: string) => {
    this.props.history.push(`/qactual/admin/organizations/${organizationId}`);
  }

  private handleEditClick = (organizationId: string, userId: string) => {
    this.props.history.push(`/qactual/admin/organizations/${organizationId}/editManager/${userId}`);
  }

  private confirmDeactivate = async () => {
    try {
      this.setState({ isLoading: true });
      const resp = await deleteUserOrganization(this.state.userData.id);
      if (resp.data.status === 'success') {
        this.setState({ isLoading: false });
        swal("OK", "User deactivated successfully", "success");
        this.props.history.push(`/qactual/admin/organizations/${this.state.organizationId}`);
      }
    } catch (error) {
      this.setState({ isLoading: false });
      swal('Error', 'Something went wrong', 'error');
    }

    this.setState({ modalDeactivateIsOpen: false });
  }

  private confirmActivate = async () => {
    try {
      this.setState({ isLoading: true });
      let user = await activateUser(this.state.userData);

      if (user?.data?.status === 'success') {
        this.setState({ isLoading: false });
        swal('Success', 'User activated successfully', 'success');
        this.props.history.push(`/qactual/admin/organizations/${this.state.organizationId}`);
      }
    } catch (error) {
      this.setState({ isLoading: false });
      swal('Error', 'Something went wrong', 'error');
    }
    this.setState({ modalActivateIsOpen: false });
  }

  private dismissDeactivate = () => this.setState({ modalDeactivateIsOpen: false });
  private dismissActivate = () => this.setState({ modalActivateIsOpen: false });

  render () {
    const {
      userId,
      organizationId,
    } = this.state;

    return (
      <div className='root'>
        <CssBaseline />
        <AppBar
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerOpen={this.handleDrawerOpen}
          breadcrumbs={[]}
        />
        <LocalDrawer
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerClose={this.handleDrawerClose}
          logout={logout}
        />
        <main className='content'>
          <div className='toolbarIcon' />
          <Container maxWidth="lg" className='container'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div style={{ float: 'left' }}>
                  <Typography variant="h4" display="block" gutterBottom>
                    Organization Manager
                  </Typography>
                </div>
                <ButtonGroup
                  color="primary"
                  aria-label='outlined primary button group'
                  style={{float: 'right'}}
                >
                  <Button
                    onClick={() => this.handleBackClick(organizationId) }>
                    <ArrowBack fontSize="small" />
                    &nbsp;Back
                  </Button>
                  <Button onClick={() => this.handleEditClick(organizationId, userId) }>
                    <Edit fontSize="small" />
                    &nbsp;Edit
                  </Button>
                  {this.state.userData.obsolete ? (
                    <Button
                      onClick={() => this.handleOpenActivateModal() }
                      startIcon={<AddCircle fontSize="small" style={{ color: green[500] }} />}>
                      Active
                    </Button>
                  ) : (
                    <Button onClick={() => this.handleOpenDeactivateModal() } startIcon={<RemoveCircle fontSize="small" color="error"/>}>
                      Deactivate
                    </Button>
                  )}
                </ButtonGroup>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className='paper' elevation={3}>
                  <Typography variant='overline' display='block' gutterBottom>
                    Name
                  </Typography>
                  <Typography variant="h6" display="block" gutterBottom>
                    {this.state.userData.name}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper className='paper' elevation={3}>
                  <Typography variant='overline' display='block' gutterBottom>
                    Email
                  </Typography>
                  <Typography variant="h6" display="block" gutterBottom>
                    {this.state.userData.username}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper className='paper' elevation={3}>
                  <Typography variant='overline' display='block' gutterBottom>
                    Phone Number
                  </Typography>
                  <Typography variant="h6" display="block" gutterBottom>
                    {this.state.userData.phone}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </main>
        <DeactivateModal
          modalDeactivateIsOpen={this.state.modalDeactivateIsOpen}
          handleModalDeactivateClose={this.handleDrawerClose}
          confirmDeactivate={this.confirmDeactivate}
          dismissDeactivate={this.dismissDeactivate}
        />
        <ActivateModal
          modalActivateIsOpen={this.state.modalActivateIsOpen}
          handleModalActivateClose={this.handleDrawerClose}
          confirmActivate={this.confirmActivate}
          dismissActivate={this.dismissActivate}
        />
      </div>
    );
  }

}

export default ShowUserOrganization;
