import React, { FC } from 'react';
import { Container, CssBaseline, Grid } from "@material-ui/core";

import Logo from './../../assets/images/logoTitleGreen.svg';

const AppPrivacyPolicy: FC = () => {
  return (
    <>
      <CssBaseline />
      <Container className="main-container">
        <Grid container justify="center">
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <a href="/cliniciandashboard">
              <img src={Logo} alt="Logo" style={{ width: "90%" }} />
            </a>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <div id="fl-main-content" className="fl-page-content" role="main">
              <div className="fl-content-full container">
                <div className="row">
                  <div className="fl-content col-md-12">
                    <article className="fl-post post-3 page type-page status-publish hentry" id="fl-post-3">
                      <header className="fl-post-header" role="banner">
                        <h1 className="fl-post-title" >App Privacy Policy</h1>
                      </header>
                      <div className="fl-post-content clearfix">
                        <small>
                          Last updated 1-20-2021 OperationPopSmokeLLC DBA QACTUAL (“we” or “us” or “our”) respects the privacy of our users (“user” or “you”). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our mobile application (the “Application”). Please read this Privacy Policy carefully.
                        </small>
                        <h2>IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THE APPLICATION.</h2>
                        <p>
                          We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will alert you about any changes by updating the “Last updated” date of this Privacy Policy. You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of the Application after the date such revised Privacy Policy is posted. This Privacy Policy does not apply to the third-party online/mobile store from which you install the Application or make payments, including any in-game virtual items, which may also collect and use data about you. We are not responsible for any of the data collected by any such third party.
                        </p>
                        <h2>COLLECTION OF YOUR INFORMATION</h2>
                        <p>We may collect information about you in a variety of ways. The information we may collect via the Application depends on the content and materials you use, and includes:</p>
                        <p>Personal Data</p>
                        <p>
                          Only the provided information Name, Email, phone number. THIS INFORMATION IS NOT SHARED.
                        </p>
                        <p>Geo-Location Information</p>
                        <p>
                          We may request access or permission to and track location-based information from your mobile device, either continuously or while you are using the Application, to provide location-based services. If you wish to change our access or permissions, you may do so in your device’s settings.
                        </p>

                        <p>Mobile Device Data</p>

                        <p>
                          Device information such as your mobile device ID number, model, and manufacturer, version of your operating system, phone number, country, location, and any other data you choose to provide.
                        </p>
                        <h3>Push Notifications</h3>

                        <p>
                          We may request to send you push notifications regarding your account or the Application. If you wish to opt-out from receiving these types of communications, you may turn them off in your device.
                        </p>

                        <h2>USE OF YOUR INFORMATION</h2>

                        <p>
                          We will at no time use your personal information. Your information will not be sold or shared.
                        </p>
                        <p>
                          We will at no time use your personal information. Your information will not be sold or shared. <br />
                          Assist law enforcement and respond to subpoena. <br />
                          Compile anonymous statistical data and analysis for use internally or with third parties. <br />
                          Create and manage your account. <br />
                          Email you regarding your account or order. <br />
                          Enable user-to-user communications. <br />
                          Generate a personal profile about you to make future visits to the Application more personalized. <br />
                          Increase the efficiency and operation of the Application. <br />
                          Notify you of updates to the Application. <br />
                          Perform other business activities as needed. <br />
                          Resolve disputes and troubleshoot problems. <br />
                          Respond to product and customer service requests.
                        </p>
                        <h2>DISCLOSURE OF YOUR INFORMATION</h2>
                        <p>
                          We may share information we have collected about you in certain situations. Your information may be disclosed as follows:
                        </p>
                        <p>
                          By Law or to Protect Rights <br />
                          If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation. This includes exchanging information with other entities for fraud protection and credit risk reduction. <br />
                          Interactions with Other Users <br />
                          If you interact with other users of the Application, those users may see your name, profile photo, and descriptions of your activity, including sending invitations to other users.
                        </p>

                        <h2>TRACKING TECHNOLOGIES</h2>

                        <p>
                          Cookies and Web Beacons <br />
                          We may use cookies, web beacons, tracking pixels, and other tracking technologies on the Application to help customize the Application and improve your experience. When you access the Application, your personal information is NOT collected through the use of tracking technology. Most browsers are set to accept cookies by default. You can remove or reject cookies, but be aware that such action could affect the availability and functionality of the Application. You may not decline web beacons. However, they can be rendered ineffective by declining all cookies or by modifying your web browser’s settings to notify you each time a cookie is tendered, permitting you to accept or decline cookies on an individual basis.
                          <br />
                          Website Analytics
                          <br />
                          We may also partner with selected third-party vendors, such as [List any third-party analytics that your mobile app uses (eg. Google Analytics)] to allow tracking technologies and remarketing services on the Application through the use of first party cookies and third-party cookies, to, among other things, analyze and track users’ use of the Application, determine the popularity of certain content, and better understand online activity. By accessing the Application, you consent to the collection and use of your information by these third-party vendors. You are encouraged to review their privacy policy and contact them directly for responses to your questions. We do not transfer personal information to these third-party vendors.
                          <br />
                          You should be aware that getting a new computer, installing a new browser, upgrading an existing browser, or erasing or otherwise altering your browser’s cookies files may also clear certain opt-out cookies, plug-ins, or settings.
                        </p>

                        <h2>SECURITY OF YOUR INFORMATION</h2>

                        We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse. Any information disclosed online is vulnerable to interception and misuse by unauthorized parties. Therefore, we cannot guarantee complete security if you provide personal information.

                        <h2>CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
                        <p>
                          Most web browsers and some mobile operating systems [and our mobile applications] include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Policy.
                        </p>

                        <h2>OPTIONS REGARDING YOUR INFORMATION</h2>

                        <p>
                          Account Information <br />
                          You may at any time review or change the information in your account or terminate your account by: <br />
                          Logging into your account settings and updating your account <br />

                          Emails and Communications <br />
                          Logging into your account settings and updating your preferences. <br />

                          Contacting us using the contact information provided below <br />
                          If you no longer wish to receive correspondence, emails. <br />
                        </p>

                        <h2>CONTACT US</h2>
                        <p>
                          If you have questions or comments about this Privacy Policy, please contact us at:
                        </p>
                        <h3>QACTUAL</h3>
                        <p>
                          255 E Main Street <br />
                          Auburn, WA 98001 <br />
                          Info@QActual.com <br />

                          Mrs. Holly Quinonez <br />
                          Development Director <br />
                          QActual <br />
                          255 E Main Street <br />
                          Auburn, WA 98002 <br />
                          253-344-9544
                        </p>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default AppPrivacyPolicy;
