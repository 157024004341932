import { Badge } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  name: string;
  to: string;
}

const BoxClinician = ({name, to}: Props): JSX.Element => {

  const Box = () => {
    return (
      <Link to={to} className="card-patient-gray">
        {name}
      </Link>
    );
  }

  return (
    <Badge color="secondary" overlap="circle" className="badge-phq29">
      <Box />
    </Badge>
  );
}

export default BoxClinician;