import React from 'react';
import { RouteComponentProps } from "react-router-dom";
import swal from 'sweetalert';
import {
  CssBaseline,
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  ButtonGroup,
} from '@material-ui/core';
import { Edit, RemoveCircle, ArrowBack } from '@material-ui/icons';
import LocalDrawer from '../../../Components/QActualAdminLocalDrawer';
import AppBar from '../../../Components/AppBar';
import RemoveModal from '../../../Components/RemoveModal';
import * as QuestionApi from '../../../services/mobile/wellnessFormQuestionAnswers';

// CALL TO API
import { logout } from '../../../services/auth';

interface Props extends RouteComponentProps {}



interface State {
  isLoading: boolean;
  toolbarOpen: boolean;
  organizationId: string;
  questionId: string;
  formId: string;
  modalRemoveIsOpen: boolean;
  question: {
    id: string,
    questionTitle: string,
    questionType: string,
    defaultValue?: string,
    active: string,
    deletedAt?: string,
    createdAt: string,
    updatedAt: string
  }
}

class FormQuestion extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      toolbarOpen: localStorage.getItem('toolbarOpen') === 'true' ? true : false,
      organizationId: props.match.params.organizationId,
      questionId: props.match.params.questionId,
      formId: props.match.params.formId,
      isLoading: false,
      modalRemoveIsOpen: false,
      question: {
        id: '',
        questionTitle: '',
        questionType: '',
        defaultValue: '',
        active: '',
        deletedAt: '',
        createdAt: '',
        updatedAt: ''
      },
      
    };
  }

  componentDidMount() {
    this.getQuestion();
  }

  private getQuestion = async () => {
    
    const response: any = await QuestionApi.getQuestion(this.state.questionId);
    const question = response.data
    
    this.setState({ question });
  }

  private handleDrawerOpen = () => {
    localStorage.setItem('toolbarOpen', 'true');
    this.setState({ toolbarOpen: true });
  };

  private handleDrawerClose = () => {
    localStorage.setItem('toolbarOpen', 'false');
    this.setState({ toolbarOpen: false });
  };

  private handleOpenRemoveModal = () => {
    this.setState({ modalRemoveIsOpen: true });
  }

  private confirmRemove = async () => {
    const {
      questionId,
      organizationId,
      formId,
    } = this.state;

    this.setState({ modalRemoveIsOpen: false, isLoading: true });

    try {
      const response = await QuestionApi.deleteQuestion(questionId);
      if ( response.status === 200 ) {
        this.setState({ isLoading: false });
        swal("OK", "Record deleted successfully", "success");
        this.props.history.push(`/qactual/admin/organizations/${organizationId}/form/${formId}`);
      }
    } catch (error) {
      console.log(error);
      this.setState({ modalRemoveIsOpen: false, isLoading: false });
      swal('Error', 'Error loading organization', 'error');
    }
  }

  private dismissRemove = () => {
    this.setState({ modalRemoveIsOpen: false });
  }

  private handleEditClick = () => {
    const {
      organizationId,
      formId,
      questionId
    } = this.state;

    this.props.history.push(`/qactual/admin/organizations/${organizationId}/form/${formId}/question/${questionId}/edit`);
  }

  private handleBackClick = () => {
    const {
      organizationId,
      formId,
    } = this.state;

    this.props.history.push(`/qactual/admin/organizations/${organizationId}/form/${formId}`);
  }

  render () {
    
    const { question } = this.state;

    return (
      <div className='root'>
        <CssBaseline />
        <AppBar
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerOpen={this.handleDrawerOpen}
          breadcrumbs={[]}
        />
        <LocalDrawer
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerClose={this.handleDrawerClose}
          logout={logout}
        />
        <main className='content'>
          <div className='toolbarIcon' />
          <Container maxWidth="lg" className='container'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div style={{ float: 'left' }}>
                  <Typography variant="h4" display="block" gutterBottom>
                    {`Question - ${question.questionTitle}`}
                  </Typography>
                </div>
                <ButtonGroup
                  color="primary"
                  aria-label='outlined primary button group'
                  style={{float: 'right'}} >
                  <Button
                    onClick={() => this.handleBackClick()}>
                    <ArrowBack fontSize="small" />
                    &nbsp;Back
                  </Button>
                  <Button
                      onClick={() => this.handleEditClick()}>
                    <Edit fontSize="small" />
                    &nbsp;Edit
                  </Button>
                  <Button onClick={() => this.handleOpenRemoveModal()}>
                    <RemoveCircle
                      fontSize="small"
                      style={{ color: '#900707' }}
                    />
                    &nbsp;Remove
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className='paper' elevation={3}>
                  <Typography variant='overline' display='block' gutterBottom>
                      Title
                  </Typography>
                  <Typography variant="h6" display="block" gutterBottom>
                    {question.questionTitle}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12}>
              <Paper className='paper' elevation={3}>
                  <Typography variant='overline' display='block' gutterBottom>
                    Type
                  </Typography>
                  <Typography variant="h6" display="block" gutterBottom>
                    {question.questionType}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12}>
              <Paper className='paper' elevation={3}>
                  <Typography variant='overline' display='block' gutterBottom>
                    Default Answer
                  </Typography>
                  <Typography variant="h6" display="block" gutterBottom>
                    {question.defaultValue}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12}>
              <Paper className='paper' elevation={3}>
                  <Typography variant='overline' display='block' gutterBottom>
                    Active
                  </Typography>
                  <Typography variant="h6" display="block" gutterBottom>
                    {question.active ? 'Yes' : 'No'}
                  </Typography>
                </Paper>

              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12}>
              <Paper className='paper' elevation={3}>
                  <Typography variant='overline' display='block' gutterBottom>
                    Created At
                  </Typography>
                  <Typography variant="h6" display="block" gutterBottom>
                    {question.createdAt}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            
          </Container>
        </main>
        <RemoveModal
          modalRemoveIsOpen={this.state.modalRemoveIsOpen}
          handleModalRemoveClose={this.handleDrawerClose}
          confirmRemove={this.confirmRemove}
          dismissRemove={this.dismissRemove}
        />
      </div>
    );
  }

}

export default FormQuestion;
