import { CircularProgress, Typography } from "@material-ui/core";
import React, { useState, useEffect, memo } from "react";
import ReactTooltip from "react-tooltip";

import { getCountries } from "../../../../../services/appCenterData";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from "react-simple-maps";

interface Props {
  dataStart: Date;
  dataEnd: Date;
  top: number;
  system: "all" | "android" | "iOS";
}

const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

function CountriesChartMap({
  dataStart,
  dataEnd,
  top,
  system = "all",
}: Props): JSX.Element {
  const [dataCountries, setDataCountries] = useState<Object>();
  const [loading, setLoading] = useState<boolean>();
  const [contentTooltip, setContentTooltip] = useState<string>("");
  const [visitTotal, setVisistTotal] = useState<number>(0);

  const getDataCoutries = async () => {
    let defineData = {};

    try {
      setLoading(true);
      const { data } = await getCountries({
        dataStart,
        dataEnd,
        top,
      });

      if (system === "all" || system === "android") {
        if (data?.data?.android) {
          data?.data.android.forEach((countries) => {
            if (countries.code !== "NONE") {
              defineData[countries.code] =
                countries.count + (defineData[countries.code] || 0);
            }
          });
        }
      }
      if (system === "all" || system === "iOS") {
        if (data?.data?.iOS) {
          data?.data.iOS.forEach((countries) => {
            if (countries.code !== "NONE") {
              defineData[countries.code] =
                countries.count + (defineData[countries.code] || 0);
            }
          });
        }
      }

      setDataCountries(defineData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDataCoutries();
  }, [dataStart, dataEnd, top, system]);

  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : (
        <div>
          <h2>Countries</h2>
          <ComposableMap data-tip="">
            <ZoomableGroup>
              <Geographies
                geography={geoUrl}
                fill="#D6D6DA"
                stroke="#FFFFFF"
                strokeWidth={0.5}
              >
                {({ geographies }) =>
                  geographies.map((geo) => {
                    return (
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        fill={
                          Object.keys(dataCountries).includes(
                            geo.properties.ISO_A2
                          )
                            ? `rgba(8,120,145, ${
                                Math.round(
                                  ((dataCountries[geo.properties.ISO_A2] || 0) /
                                    (Object.values(dataCountries).reduce(
                                      (a, b) => a + b,
                                      0
                                    ) || 0)) *
                                    100
                                ) /
                                  100 +
                                0.3
                              })`
                            : "#D6D6DA"
                        }
                        onMouseEnter={() => {
                          const { NAME } = geo.properties;
                          setContentTooltip(
                            `${NAME} - ${
                              dataCountries[geo.properties.ISO_A2] || 0
                            } Visits`
                          );
                        }}
                        onMouseLeave={() => {
                          setContentTooltip("");
                        }}
                      />
                    );
                  })
                }
              </Geographies>
            </ZoomableGroup>
          </ComposableMap>
          <ReactTooltip>{contentTooltip}</ReactTooltip>
        </div>
      )}
    </div>
  );
}

export default memo(CountriesChartMap);
