import React from "react";
import { RouteComponentProps } from "react-router-dom";
import swal from "sweetalert";
import {
  CssBaseline,
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  CircularProgress,
  ButtonGroup,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";

import LocalDrawer from "../../../Components/QActualAdminLocalDrawer";
import AppBar from "../../../Components/AppBar";

// CALL TO API
import { logout } from "../../../services/auth";
import * as FormApi from "../../../services/mobile/wellnessForm";
import { Formik } from "formik";
import * as yup from "yup";
import { ArrowBack } from "@material-ui/icons";
import { listAllUserOrgAdminsActivateDeactivate } from "../../../services/organization";

interface Props extends RouteComponentProps {}

interface State {
  isLoading: boolean;
  toolbarOpen: boolean;
  organizationId: string;
  validationError: string;
  organizationData: {
    name: "";
    shortName: "";
    category: "";
    appVersion: "";
    theme: "";
    logo: "";
    ddlURL: "";
    resourceGroupId: "";
  };
}

class AddFormOrganization extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      toolbarOpen:
        localStorage.getItem("toolbarOpen") === "true" ? true : false,
      organizationId: props.match.params.organizationId,
      isLoading: false,
      validationError: "",
      organizationData: {
        name: "",
        shortName: "",
        category: "",
        appVersion: "",
        theme: "",
        logo: "",
        ddlURL: "",
        resourceGroupId: "",
      },
    };
  }

  componentDidMount() {
    this.getOrganization();
  }

  private getOrganization = async () => {
    const { organizationId } = this.state;

    try {
      const response = await listAllUserOrgAdminsActivateDeactivate(
        organizationId
      );
      const organizationData = response.data.organization;
      this.setState({ organizationData });
    } catch (error) {
      console.log(error);
      swal("Error", "Error loading organization", "error");
    }
  };

  private handleDrawerOpen = () => {
    localStorage.setItem("toolbarOpen", "true");
    this.setState({ toolbarOpen: true });
  };

  private handleDrawerClose = () => {
    localStorage.setItem("toolbarOpen", "false");
    this.setState({ toolbarOpen: false });
  };

  private handleBackClick = () => {
    const { organizationId } = this.state;

    this.props.history.push(
      `/qactual/admin/organizations/${organizationId}/form`
    );
  };

  handleSubmit = async (params: any) => {
    try {
      this.setState({ isLoading: true });
      const response = await FormApi.createForm(params);

      if (response?.data && response.status === 201) {
        this.setState({ isLoading: false });
        swal("Success", "Record saved", "success");
        this.handleBackClick();
      }
    } catch (error) {
      this.setState({ isLoading: false });
      swal("Error", "Something went wrong", "error");
    }
  };

  render() {
    const { organizationId, organizationData } = this.state;

    return (
      <div className="root">
        <CssBaseline />
        <AppBar
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerOpen={this.handleDrawerOpen}
          breadcrumbs={[]}
        />
        <LocalDrawer
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerClose={this.handleDrawerClose}
          logout={logout}
        />
        <main className="content">
          <div className="toolbarIcon" />
          <Container maxWidth="lg" className="container">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div style={{ float: "left" }}>
                  <Typography variant="h4" display="block" gutterBottom>
                    {`Organization - ${organizationData.name}`}
                  </Typography>
                </div>
                <ButtonGroup
                  color="primary"
                  aria-label="outlined primary button group"
                  style={{ float: "right" }}
                >
                  <Button onClick={() => this.handleBackClick()}>
                    <ArrowBack fontSize="small" />
                    &nbsp;Back
                  </Button>
                </ButtonGroup>
              </Grid>
              <Paper
                className="paper"
                style={{ paddingTop: 40, width: "100%" }}
              >
                <Formik
                  initialValues={{
                    formName: "",
                    active: true,
                  }}
                  validationSchema={yup.object().shape({
                    formName: yup
                      .string()
                      .required("You must provide a name to your new form"),
                  })}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    this.handleSubmit({
                      formName: values.formName,
                      active: values.active,
                      orgId: organizationId,
                      orgName: organizationData.shortName,
                    });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    isSubmitting,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            id="formName"
                            label="Name"
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.formName}
                            fullWidth
                            error={errors.formName && touched.formName}
                            helperText={touched.formName && errors.formName}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            style={{ float: "right" }}
                            size="large"
                            disabled={isSubmitting}
                            startIcon={
                              this.state.isLoading ? (
                                <CircularProgress />
                              ) : (
                                <SaveIcon />
                              )
                            }
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
                {this.state.validationError}
              </Paper>
            </Grid>
          </Container>
        </main>
      </div>
    );
  }
}

export default AddFormOrganization;
