import React from 'react';

import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

interface Props {
  logout: Function;
  isOpen: boolean;
  handleDrawerClose: Function;
}

class Drawer_ extends React.Component<Props> {
  render () {
    const { isOpen, handleDrawerClose } = this.props;

    return (
      <Drawer
          variant="permanent"
          open={false}
          classes={{
            paper: clsx('drawerPaper', !isOpen && 'drawerPaperClose'),
          }} >
        <div className='toolbarIcon'>
          <IconButton onClick={() => handleDrawerClose()}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {this.props.children}
        </List>
      </Drawer>
    );
  }
}

export default Drawer_;
