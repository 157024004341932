import React from 'react';
import { RouteComponentProps } from "react-router-dom";
import swal from 'sweetalert';
import {
  CssBaseline,
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  ButtonGroup,
} from '@material-ui/core';
import { Edit, RemoveCircle, ArrowBack } from '@material-ui/icons';
import LocalDrawer from '../../../Components/QActualAdminLocalDrawer';
import AppBar from '../../../Components/AppBar';
import RemoveModal from '../../../Components/RemoveModal';

// CALL TO API
import { logout } from '../../../services/auth';
import API from '../../../services/api';

interface Props extends RouteComponentProps {}

interface State {
  isLoading: boolean;
  toolbarOpen: boolean;
  organizationId: string;
  resourceId: string;
  modalRemoveIsOpen: boolean;
  resourceData: {
    title: string;
    description: string;
    phoneNumber: string;
    textMessage: string;
    website: string;
  }
}

class Resource extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      toolbarOpen: localStorage.getItem('toolbarOpen') === 'true' ? true : false,
      organizationId: props.match.params.organizationId,
      resourceId: props.match.params.resourceId,
      isLoading: false,
      modalRemoveIsOpen: false,
      resourceData: {
        title: '',
        description: '',
        phoneNumber: '',
        textMessage: '',
        website: '',
      }
    };
  }

  componentDidMount() {
    this.getResource();
  }

  private getResource = async () => {
    const {
      resourceId,
    } = this.state;

    const response: any = await API.get(`/resources/${resourceId}`).catch((error) => {
    });
    const resourceData = response.data.resource;
    this.setState({ resourceData });
  };

  private handleDrawerOpen = () => {
    localStorage.setItem('toolbarOpen', 'true');
    this.setState({ toolbarOpen: true });
  };

  private handleDrawerClose = () => {
    localStorage.setItem('toolbarOpen', 'false');
    this.setState({ toolbarOpen: false });
  };

  private handleOpenRemoveModal = () => {
    this.setState({ modalRemoveIsOpen: true });
  }

  private confirmRemove = async () => {
    const {
      resourceId,
      organizationId,
    } = this.state;

    this.setState({ modalRemoveIsOpen: false, isLoading: true });

    try {
      const response = await API.delete(`/resources/${resourceId}`);
      if ( response.data.status === "success" ) {
        this.setState({ isLoading: false });
        swal("OK", "Record deleted successfully", "success");
        this.props.history.push(`/qactual/admin/organizations/${organizationId}/resources`);
      }
    } catch (error) {
      console.log(error);
      this.setState({ modalRemoveIsOpen: false, isLoading: false });
      swal('Error', 'Error loading organization', 'error');
    }
  }

  private dismissRemove = () => {
    this.setState({ modalRemoveIsOpen: false });
  }

  private handleEditClick = () => {
    const {
      organizationId,
      resourceId,
    } = this.state;

    this.props.history.push(`/qactual/admin/organizations/${organizationId}/resources/${resourceId}/edit`);
  }

  private handleBackClick = () => {
    this.props.history.push('/qactual/admin/organizations/');
  }

  render () {
    const {
      organizationId,
      resourceData,
    } = this.state;

    return (
      <div className='root'>
        <CssBaseline />
        <AppBar
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerOpen={this.handleDrawerOpen}
          breadcrumbs={[]}
        />
        <LocalDrawer
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerClose={this.handleDrawerClose}
          logout={logout}
        />
        <main className='content'>
          <div className='toolbarIcon' />
          <Container maxWidth="lg" className='container'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div style={{ float: 'left' }}>
                  <Typography variant="h4" display="block" gutterBottom>
                    {`Resource - ${resourceData.title}`}
                  </Typography>
                </div>
                <ButtonGroup
                  color="primary"
                  aria-label='outlined primary button group'
                  style={{float: 'right'}} >
                  <Button
                    onClick={() => this.handleBackClick()}>
                    <ArrowBack fontSize="small" />
                    &nbsp;Back
                  </Button>
                  <Button
                      onClick={() => this.handleEditClick()}>
                    <Edit fontSize="small" />
                    &nbsp;Edit
                  </Button>
                  <Button onClick={() => this.handleOpenRemoveModal()}>
                    <RemoveCircle
                      fontSize="small"
                      style={{ color: '#900707' }}
                    />
                    &nbsp;Remove
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className='paper' elevation={3}>
                  <Typography variant='overline' display='block' gutterBottom>
                    Title
                  </Typography>
                  <Typography variant="h6" display="block" gutterBottom>
                    {this.state.resourceData.title}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className='paper' elevation={3}>
                  <Typography variant='overline' display='block' gutterBottom>
                    Description
                  </Typography>
                  <Typography variant="h6" display="block" gutterBottom>
                    {this.state.resourceData.description}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className='paper' elevation={3}>
                  <Typography variant='overline' display='block' gutterBottom>
                    Phone Number
                  </Typography>
                  <Typography variant="h6" display="block" gutterBottom>
                    {this.state.resourceData.phoneNumber}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className='paper' elevation={3}>
                  <Typography variant='overline' display='block' gutterBottom>
                    Text Message
                  </Typography>
                  <Typography variant="h6" display="block" gutterBottom>
                    {this.state.resourceData.textMessage}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className='paper' elevation={3}>
                  <Typography variant='overline' display='block' gutterBottom>
                    Website
                  </Typography>
                  <Typography variant="h6" display="block" gutterBottom>
                    {this.state.resourceData.website}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </main>
        <RemoveModal
          modalRemoveIsOpen={this.state.modalRemoveIsOpen}
          handleModalRemoveClose={this.handleDrawerClose}
          confirmRemove={this.confirmRemove}
          dismissRemove={this.dismissRemove}
        />
      </div>
    );
  }

}

export default Resource;
