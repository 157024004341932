import React from 'react';

import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@material-ui/core';

import {
  ExitToApp,
  People,
  VpnKey,
} from '@material-ui/icons';

import Drawer from '../Drawer';

interface Props {
  toolbarOpen: boolean;
  handleDrawerClose: Function;
  logout: Function;
};

class LocalDrawer extends React.Component<Props> {

  render () {
    const {
      toolbarOpen,
      handleDrawerClose,
      logout,
    } = this.props;

    return (
      <Drawer
        isOpen={toolbarOpen}
        handleDrawerClose={handleDrawerClose}
        logout={logout}
      >
        <ListItem button component="a" href="/organizationManager/">
          <ListItemIcon>
            <People />
          </ListItemIcon>
          <ListItemText primary="Care Providers" />
        </ListItem>
        <Divider />
        <ListItem button component="a" href="/organizationManager/changePassword">
          <ListItemIcon>
            <VpnKey />
          </ListItemIcon>
          <ListItemText primary="Change Password" />
        </ListItem>
        <ListItem button onClick={() => logout('/organizationManager/login')}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Signout" />
        </ListItem>
      </Drawer>
    );
  }
}

export default LocalDrawer;
