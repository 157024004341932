import React from 'react';
import dayjs from 'dayjs';
import { getById, deletePatient, createContactEvents } from '../../services/patient';
import { getAuthUser } from '../../services/auth';

import {
  Container,
  CssBaseline,
  Grid,
  Button,
  Card,
  CardContent,
  CardActions,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import swal from 'sweetalert';

// Icones
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';

//Websocket
import { wsClient } from '../../helpers/websockets';

// Componets Local
import WellnessCheckChart from './Components/WellnessCheckChart';
import PHQ29Chart from './Components/PHQ29Chart';
import RemoveModal from '../../Components/RemoveModal';
import EditPatient from './Components/EditPatient';
import AddAssignCareProvider from './AddAssignCareProvider';
import ConfirmAcknowlegdementModal from './Components/ConfirmAcknowlegdementModal';

// Images
import Logo from '../../assets/images/logoTitleGreen.svg';
import FilterWeek from './Components/FilterWeek';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

// CALL TO API
import { getPatientWellnessCheck, getPatientPHQ29 } from '../../services/patient';
import { getEventsWithPaginate } from '../../services/event';

interface Props extends RouteComponentProps { }

interface State {
  page: number;
  phq29s: any;
  wellnesschecks: any;
  patient: {
    id: string,
    name: string,
    email: string,
    phoneNumber: string,
  },
  modalRemoveIsOpen: boolean;
  openEdit: boolean;
  events: any;
  activeGetEvents: boolean;
  modalAknowledgeIsOpen: boolean;
  selectedCheckbox: string,
  loadingWellness: boolean,
  loadingPHQ29: boolean,
  openAssignCareProvider: boolean,
  userLogged: any,
  loading:boolean,
};

class Patient extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      wellnesschecks: [],
      phq29s: [],
      patient: {
        id: '',
        name: '',
        email: '',
        phoneNumber: '',
      },
      modalRemoveIsOpen: false,
      openEdit: false,
      events: [],
      activeGetEvents: true,
      modalAknowledgeIsOpen: false,
      selectedCheckbox: '',
      loadingWellness: false,
      loadingPHQ29: false,
      openAssignCareProvider: false,
      userLogged: {
        id: '',
        name: '',
        username: '',
        OrganizationId: '',
      },
      loading:false,
    }
  }

  componentDidMount() {
    this.getUserLogged();
    this.getPatientGetById(this.props.match.params.patientId);
    this.getPHQ29s(this.props.match.params.patientId);
    this.getWellnessChecks(this.props.match.params.patientId);
    this.getPatientEvents({
      patientId: this.props.match.params.patientId,
      page: this.state.page,
      records: 15,
    });

    const eventsContainer = document.getElementById('eventsContainer');
    eventsContainer.onscroll = () => {
      if ((eventsContainer.offsetHeight + eventsContainer.scrollTop) >= eventsContainer.scrollHeight && this.state.activeGetEvents) {
        // you're at the bottom of the page
        this.getPatientEvents({
          patientId: this.props.match.params.patientId,
          page: this.state.page,
          records: 15,
        });
      }
    };

    wsClient.onmessage = (data: any) => {
      data = JSON.parse(data.data)
      switch (data.op) {
        case 'updateStatusPatient': {
          if (data.patientId === this.props.match.params.patientId) {
            this.setState({ events: [] });

            this.getPatientEvents({
              patientId: this.props.match.params.patientId,
              page: 1,
              records: 15,
            });
          }

          break;
        }

        default:
          break
      }
    };
  }

  private getPatientEvents = async (obj: {
    patientId: string,
    page: number,
    records: number,
  }) => {
    const { patientId, page, records } = obj;

    try {
      const resp = await getEventsWithPaginate(patientId, page, records);
      const { events } = resp?.data;
      const processedEvents = this.state.events.concat(events);

      this.setState({
        events: processedEvents,
        activeGetEvents: events.length >= 15 ? true : false,
        page: page + 1,
      });
    } catch (error) {
      swal('ERROR', 'Something went wrong!', 'error');
    }
  };

  private getWellnessChecks = async (patientId, days = 7) => {
    this.setState({ loadingWellness: true })
    try {
      const answer = await getPatientWellnessCheck(patientId, days);
      const {
        wellnesschecks,
      } = answer.data;
      this.setState({ wellnesschecks });
      this.setState({ loadingWellness: false });

    } catch (error) {
      this.setState({ loadingWellness: false });
    }
  };

  private getPHQ29s = async (patientId, days = 7) => {
    this.setState({ loadingPHQ29: true })
    try {
      const answer = await getPatientPHQ29(patientId, days);
      const { phq29s } = answer.data;
      this.setState({ phq29s });
      this.setState({ loadingPHQ29: false })
    } catch (error) {
      this.setState({ loadingPHQ29: false })
    }
  };

  private getUserLogged = async () => {
    try {
      const resp = await getAuthUser();
      const { user } = resp.data;
      this.setState({ userLogged: user });
    } catch (error) {
      swal('Error', 'Error when loading user', 'error')
    }
  }

  private getPatientGetById = async (id: string) => {
    try {
      this.setState({ loading: true });
      const resp = await getById(id);
      const { patient } = resp.data;
      this.setState({ patient: patient });
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
      swal('Error', 'Error when loading patient', 'error')
    }
  }

  private handleOpenRemoveModal = (modalRemoveIsOpen: boolean) => {
    this.setState({ modalRemoveIsOpen });
  }

  private confirmRemove = async () => {
    const { history } = this.props;
    const { patient } = this.state;
    try {
      const resp = await deletePatient(patient.id);
      if (resp.data.status === 'success') {
        swal('OK', 'Record deleted successfully', 'success');
        history.push(`/cliniciandashboard`);
      }
    } catch (error) {
      swal('Error', 'Deleted record error', 'error')
    }

    this.handleOpenRemoveModal(false); //, isLoading: true });
  }

  private setOpenEdit = (openEdit: boolean) => this.setState({ openEdit });

  private dismissRemove = () => this.handleOpenRemoveModal(false);

  private handleOpenEdit = () => this.setOpenEdit(true);

  private handleCloseEdit = () => this.setOpenEdit(false);

  private handleOpenMultiCare = () => this.setState({ openAssignCareProvider: true });

  private handleCloseMultiCare = () => {
    this.getPatientGetById(this.props?.match?.params?.patientId);
    this.setState({ openAssignCareProvider: false });
  }

  private handleOpenAddPhq29 = (patientId: String) => {
    const { history } = this.props;

    history.push(`/cliniciandashboard/patient/${patientId}/addPHQ29`);
  }

  private handleCreateContactEvents = async (type: number) => {
    const { patient } = this.state;

    const data = { type: type }
    await createContactEvents(patient.id, data);
  }

  private processOpenPHQ29 = (
    selectedCheckbox: string
  ): boolean => {
    const { events } = this.state;

    const event = events.find((event) => event.id === selectedCheckbox);
    if (
      event.EventCategory.name === 'redAlert'
      || event.EventCategory.name === 'whiteAlert'
      || event.EventCategory.name === 'yellowEvent'
      || event.EventCategory.name === 'blackEvent'
    ) {
      return true;
    } else {
      return false;
    }
  }

  render(): JSX.Element {
    const { history } = this.props;
    const {
      patient,
      wellnesschecks,
      phq29s,
      modalRemoveIsOpen,
      openEdit,
      events,
      modalAknowledgeIsOpen,
      selectedCheckbox,
    } = this.state;

    return (
      <>
        <CssBaseline />
        <Container className="main-container">
          <AddAssignCareProvider
            open={this.state.openAssignCareProvider}
            handleClose={this.handleCloseMultiCare}
            handleOpen={this.handleOpenMultiCare}
            patient={patient}
            user={this.state.userLogged}
          />
          <EditPatient
            patientId={patient.id}
            open={openEdit}
            handleClose={this.handleCloseEdit}
            handleOpen={this.handleOpenEdit}
            handleReload={() => this.getPatientGetById(patient.id)}
          />
          <Grid container>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <a href="/cliniciandashboard" title="Go to home">
                <img
                  src={Logo}
                  alt="Logo"
                  style={{ width: "90%", margin: 'auto', display: 'block' }}
                />
              </a>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Grid container spacing={3} direction="column">
                <Grid item>
                  <Card className="box-info-user">
                    <CardContent>
                      <h2>{patient.name}</h2>
                      <h4>
                        Email: <a
                          href={`mailto:${patient.email}`}
                          onClick={() => this.handleCreateContactEvents(2)}
                          className="text-none white"
                        >
                          {patient.email}
                        </a>
                      </h4>
                      <h4>
                        Phone: <a
                          href={`tel:${patient.phoneNumber}`}
                          onClick={() => this.handleCreateContactEvents(1)}
                          className="text-none white"
                        >
                          {patient.phoneNumber}
                        </a>
                      </h4>
                      <CardActions>
                        <Button
                          onClick={() => this.handleOpenAddPhq29(patient.id)}
                          variant="contained"
                          size="small"
                          color="primary"
                          startIcon={<PlaylistAddCheckIcon />}
                          style={{ marginLeft: 0 }}
                        >
                          PHQ29
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          onClick={(e: React.SyntheticEvent) => {
                            this.handleOpenEdit();
                            return e.preventDefault();
                          }}
                          startIcon={<EditIcon />}
                        >
                          EDIT
                        </Button>
                        <Button
                          onClick={() => this.handleOpenRemoveModal(true)}
                          variant="contained"
                          size="small"
                          color="primary"
                          startIcon={<DeleteOutlineIcon />}
                        >
                          REMOVE
                        </Button>
                      </CardActions>

                      <CardActions>
                        <Button
                          onClick={() => this.setState({ openAssignCareProvider: true })}
                          size="small"
                          variant="contained"
                          color="primary"
                          startIcon={<GroupAddIcon />}
                          style={{ marginLeft: 0 }}
                          fullWidth
                        >
                          Assign Care Provider
                        </Button>
                      </CardActions>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item>
                  <Card className="card-check-ins">
                    <CardContent>
                      <h3>EVENTS</h3>

                      <div
                        id='eventsContainer'
                        style={{
                          maxHeight: 300,
                          overflow: 'auto',
                          marginBottom: 10,
                        }}
                      >
                        {events.map((event) => {
                          let color = 'gray';
                          let fontColor = 'black';

                          switch (event.EventCategory.name) {
                            case ('whiteAlert'): {
                              color = 'white';
                              fontColor = 'black';
                              break;
                            }

                            case ('redAlert'): {
                              color = '#a83333';
                              fontColor = 'black';
                              break;
                            }

                            case ('yellowEvent'): {
                              color = '#db9729';
                              fontColor = 'black';
                              break;
                            }

                            case ('blackEvent'): {
                              color = 'black';
                              fontColor = 'white';
                              break;
                            }

                            default: {
                              color = '#afafaf';
                              fontColor = 'black';
                              break;
                            }
                          }

                          return (
                            <Grid
                              container
                              style={{
                                backgroundColor: color,
                                padding: 8,
                                borderStyle: 'solid',
                                borderWidth: '2px',
                                borderColor: 'gray',
                                fontWeight: 600,
                                color: fontColor,
                              }}
                              key={event.id}
                            >
                              <Grid item style={{ minWidth: 100 }}>
                                {dayjs(event.createdAt).format('YYYY-MM-DD')}
                              </Grid>
                              <Grid item style={{ minWidth: 100 }}>
                                {dayjs(event.createdAt).format('hh:mm A')}
                              </Grid>
                              <Grid item style={{ minWidth: 22 }}>
                                <input
                                  type="checkbox"
                                  checked={event.acknowledged}
                                  disabled={event.acknowledged}
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  onChange={() => {
                                    this.setState({
                                      modalAknowledgeIsOpen: true,
                                      selectedCheckbox: event.id,
                                    });
                                  }}
                                />
                              </Grid>
                            </Grid>
                          );
                        })}
                      </div>
                      <Typography>
                        <span className="box-label-status-patient-gray" ></span>{" "}
                        <span> Neutral </span>
                      </Typography>
                      <Typography>
                        <span className="box-label-status-patient-yellow"></span>{" "}
                        <span> Elevated </span>
                      </Typography>
                      <Typography>
                        <span className="box-label-status-patient-white"></span>{" "}
                        <span> Needs Assistance </span>
                      </Typography>
                      <Typography>
                        <span className="box-label-status-patient-red"></span>{" "}
                        <span> Emergency </span>
                      </Typography>
                      <Typography>
                        <span className="box-label-status-patient-black"></span>{" "}
                        <span> No Assistance from Team</span>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item>
                  {this.state.patient?.id && (<FilterWeek
                    dataPatient={this.state.patient}
                    handleChangeWeek={async (days) => {
                      await this.getWellnessChecks(this.state.patient.id, days);
                      await this.getPHQ29s(this.state.patient.id, days);
                    }}
                  />)}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="column">
                <Grid item style={{ textAlign: "center" }}>
                  <h1>WELLNESS CHECK</h1>
                  {this.state.loadingWellness ? <CircularProgress /> : <WellnessCheckChart wellnesschecks={wellnesschecks} />}
                </Grid>
                <Grid item style={{ textAlign: "center" }}>
                  <h1>PHQ 2-9 QUESTIONS</h1>
                  {this.state.loadingPHQ29 ? <CircularProgress /> : <PHQ29Chart phq29s={phq29s} />}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <RemoveModal
            modalRemoveIsOpen={modalRemoveIsOpen}
            handleModalRemoveClose={() => { }}
            confirmRemove={this.confirmRemove}
            dismissRemove={this.dismissRemove}
          />
          <ConfirmAcknowlegdementModal
            selectedCheckbox={selectedCheckbox}
            open={modalAknowledgeIsOpen}
            handleClose={(eventId: string) => {
              events.forEach((event) => {
                if (event.id === eventId) {
                  event.acknowledged = false;
                }
              });

              this.setState({
                modalAknowledgeIsOpen: false,
                selectedCheckbox: '',
                events,
              });
            }}
            takeAknowledgment={() => {
              const openPHQ29 = this.processOpenPHQ29(selectedCheckbox);

              if (openPHQ29) {
                history.push(`/cliniciandashboard/patient/${this.props.match.params.patientId}/addPHQ29`);
              }
            }}
          />
        </Container>
      </>
    );
  };
};

export default Patient;
