import React, { FC, useState, useEffect } from 'react';
import { getAuthUser, resetPassword } from '../../services/auth';
import { RouteComponentProps } from 'react-router';
import swal from 'sweetalert';
import {
  CssBaseline,
  Container,
  Grid,
  TextField,
  Button,
  Box,
  CircularProgress,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import * as SanitizeString from '../../services/sanitize_strings';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
    '&$focused': {
      backgroundColor: '#fff',
    },
    '& label.Mui-focused': {
      color: '#000',
      fontWeight: 'bold',
      fontSize: '150%',
      marginTop: -10,
    },
    '& .MuiInputLabel-asterisk': {
      color: '#9e3636',
    }, '& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled.Mui-disabled': {
      color: '#7f7f7f'
    }
  }
});

const ChangePassword: FC<RouteComponentProps> = (props) => {

  let { pathname } = useLocation();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState < string > ('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [destination, setDestination] = useState < string > ('/');

  useEffect(() => {
    switch (pathname) {
      case ('/qactual/admin/changePassword'):
        setDestination('/qactual/admin/organizations');
        break;

      case ('/organizationManager/changePassword'):
        setDestination('/organizationManager');
        break;

      case ('/cliniciandashboard/changePassword'):
        setDestination('/cliniciandashboard');
        break;

      default:
        break;
    }

    (async function fetchAPI() {
      const user = await getAuthUser();

      if (user.data.status === 'fail' || user.data.status === 'error') {
        swal("ERROR", user.data.err.message, "error");
      } else {
        setEmail(user.data.user.username);
      }
    })();
  }, [pathname]);

  const handleSignIn = async (event: any) => {
    event.preventDefault();

    setIsLoading(true);

    if (!comparePassword()) {
      swal('Important', 'Passwords are not the same!', 'warning');
      setIsLoading(false);
    } else {
      let fields = {email: email, password: password, newPassword: newPassword};

      const reset = await resetPassword(fields);

      if(reset.data.status === 'success') {
        setIsLoading(false);
        swal("OK", "Password updated successfully.", "success");
        props.history.push(destination);
      } else {
        setIsLoading(false);
        swal("Error", "Failed to update password!", "error");
      }
      setIsLoading(false);
    }
  }

  const comparePassword = () => newPassword === confirmPassword;

  const classes = useStyles();

  return (
    <div className='root'>
      <CssBaseline />
      <main className='content' style={{ marginTop: '2%' }}>
      <Container component="main" maxWidth="xs">
        <Typography variant="h4" style={{ textAlign: 'center', marginBottom: '5%' }}>
          Change Password
        </Typography>
        <Paper className='paper'>
          <form onSubmit={handleSignIn}>
            <Box mt={2}>
              <TextField
                className={classes.root}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                onChange={(e) => setEmail(SanitizeString.email(e.target.value))}
                value={email}
                disabled={true}
                autoComplete="email"
                autoFocus
              />
            </Box>

            <Box mt={2}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Old password"
                type="password"
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
              />
            </Box>

            <Box mt={2}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="newPassword"
                label="New Password"
                type="password"
                id="newPassword"
                onChange={(e) => setNewPassword(e.target.value)}
                autoComplete="current-new-password"
              />
            </Box>

            <Box mt={2}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm New Password"
                type="password"
                id="confirmPassword"
                onChange={(e) => setConfirmPassword(e.target.value)}
                autoComplete="current-confirm-password"
              />
            </Box>

            <Box mt={2}>
              <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={12} sm={6}>
                  <Button
                    size="large"
                    startIcon={<ArrowBackIcon />}
                    onClick={() => props.history.push(destination)}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                    size="large"
                    fullWidth
                    startIcon={isLoading ? <CircularProgress size={24} /> : <SaveIcon />}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </Box>

          </form>
        </Paper>
      </Container>
      </main>
    </div>
  );
};

export default ChangePassword;
