import axios from "axios";
import jwtDecode from "jwt-decode";

import { getToken, clearLocalStorage } from "./auth";

let baseURL = "";

if (process.env.NODE_ENV === "development") {
  baseURL = "http://localhost:4000";
} else {
  const currentURL = window.location.href;
  if (currentURL.includes("staging--qactual-clinician-dashboard.netlify.app")) {
    baseURL = "https://staging-clinician-dashboard-api.qactualservice.com";
  } else {
    baseURL = "https://clinician-dashboard-api.qactualservice.com";
  }
}

// Create AXIOS API
const API = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

API.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    const decodedToken = jwtDecode(token);

    // If the token has expired redirects the user to the login page
    const referenceTime = new Date().getTime() + 5 * 60 * 1000; // now + 5 minutes
    const expTime = (decodedToken as any).exp * 1000;

    if (referenceTime > expTime) {
      // Clean up localStorage variables
      clearLocalStorage();

      const currentURL = window.location.href;
      if (currentURL.includes("qactual/admin")) {
        window.location.href = `${window.location.protocol}//${window.location.host}/qactual/admin`;
      } else if (currentURL.includes("organizationManager")) {
        window.location.href = `${window.location.protocol}//${window.location.host}/organizationManager`;
      } else {
        window.location.href = `${window.location.protocol}//${window.location.host}`;
      }
    } else {
      config.headers.Authorization = `${token}`;
    }
  }

  return config;
});

export default API;
