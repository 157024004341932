import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  wellnesschecks: {
    LevelAnger: number;
    LevelAnxiety: number;
    LevelDepression: number;
    FeelSuicidal?: number;
    CreatedAt?: Date;
    date?: Date;
    id?: number;
  }[];
};


const useStyles = makeStyles({
  containerBox: {
    width: '100%',
    display: 'flex',
    paddingLeft: 22,
  },
  boxLabelAlert: {
    width: 100,
  },
  labelAlert: {
    width: 80,
    height: 80,
  },
  boxContentAlerts: {
    width: 600,
    overflow: 'auto'
  },
  tableContentAlerts: {
    width: '100%',
  },
  SumAlert: {
    width: 30,
    height: 80,
    backgroundColor: '#FFFFFF'
  },
  labelColumn: {
    position: 'relative',
    top: -40,
  },
  boxLabel: {
    height: 50,
  },
  noDataLabel: {
    backgroundColor: '#717171',
    width: 20,
    height: 20,
    margin: 10,
    padding: '10px 20px',
  },
  levelZeroLabel: {
    backgroundColor: '#FFFFFF',
    width: 20,
    height: 20,
    margin: 10,
    padding: '10px 20px',
  },
  levelOneLabel: {
    backgroundColor: '#FFFF00',
    width: 20,
    height: 20,
    margin: 10,
    padding: '10px 20px',
  },
  levelTwoLabel: {
    backgroundColor: '#FFCC02',
    width: 20,
    height: 20,
    margin: 10,
    padding: '10px 20px',
  },
  levelThreeLabel: {
    backgroundColor: '#FF6C01',
    width: 20,
    height: 20,
    margin: 10,
    padding: '10px 20px',
  },
  levelFourLabel: {
    backgroundColor: '#f84100',
    width: 20,
    height: 20,
    margin: 10,
    padding: '10px 20px',
  },
  levelFiveLabel: {
    backgroundColor: '#DC0000',
    width: 20,
    height: 20,
    margin: 10,
    padding: '10px 20px',
  },
});

export default function DemoChart({ wellnesschecks }: Props): JSX.Element {

  const classes = useStyles();
  const [levelAnger, setLevelAnger] = useState([]);
  const [levelAnxiety, setLevelAnxiety] = useState([]);
  const [levelDepression, setLevelDepression] = useState([]);
  const [dateLabel, setDateLabel] = useState([]);
  // const [sumLevelAnger, setSumLevelAnger] = useState();
  // const [sumLevelAnxiety, setSumLevelAnxiety] = useState();
  // const [sumLevelDepression, setSumLevelDepression] = useState();

  const getLevelColor = (level: number): string => {
    switch (level) {
      case 0:
        return '#FFFFFF';
      case 1:
        return '#FFFF00';
      case 2:
        return '#FFCC02';
      case 3:
        return '#FF6C01';
      case 4:
        return '#f84100';
      case 5:
        return '#DC0000';
      default:
        return '#717171';
    }
  }

  // const sumValues = (data) => {
  //   let intSum = undefined;
  //   data.forEach(({ value }) => {
  //     if (value !== undefined) {
  //       intSum = parseInt(intSum) || 0;
  //       intSum += value;
  //     }
  //   });
  //   return intSum;
  // };


  useEffect(() => {
    let valLevelAnger = [];
    let valLevelAnxiety = [];
    let valLevelDepression = [];
    let valDateLabel = [];

    wellnesschecks.forEach((wellnesscheck, index) => {
      let month = '';
      let day = '';
      let year = '';

      if (wellnesscheck.date) {
        wellnesscheck.date = new Date(wellnesscheck.date);
        month = `${wellnesscheck.date.getUTCMonth() + 1}`;
        day = `${wellnesscheck.date.getUTCDate()}`;
        year = `${wellnesscheck.date.getUTCFullYear()}`;
      } else {
        wellnesscheck.CreatedAt = new Date(wellnesscheck.CreatedAt);
        month = `${wellnesscheck.CreatedAt.getUTCMonth() + 1}`;
        day = `${wellnesscheck.CreatedAt.getUTCDate()}`;
        year = `${wellnesscheck.CreatedAt.getUTCFullYear()}`;
      }

      month = month.length === 1 ? `0${month}` : month;

      day = day.length === 1 ? `0${day}` : day;

      if (wellnesschecks.length === 7) {

        valDateLabel.push({
          'value': `${month}/${day}/${year}`,
          'colspan': 1,
          'textAlign': 'center',
        });
      }
      if (wellnesschecks.length === 30) {
        if (index === 0) {
          valDateLabel.push({
            'value': `${month}/${day}/${year}`,
            'colspan': 4,
            'textAlign': 'left',
          });
        }
        for (let i = 1; i < 30; i += 7) {
          if (index === i) {
            valDateLabel.push({
              'value': `${month}/${day}/${year}`,
              'colspan': 4,
              'textAlign': 'center',
            });
          }
        }
        if (index === 29) {
          valDateLabel.push({
            'value': `${month}/${day}/${year}`,
            'colspan': 6,
            'textAlign': 'right',
          });
        }
      }


      if (wellnesschecks.length === 90) {
        if (index === 0) {
          valDateLabel.push({
            'value': `${month}/${day}/${year}`,
            'colspan': 15,
            'textAlign': 'left',
          });
        }
        for (let i = 1; i < 90; i += 22) {
          if (index === i) {
            valDateLabel.push({
              'value': `${month}/${day}/${year}`,
              'colspan': 12,
              'textAlign': 'center',
            });
          }
        }
        if (index === 89) {
          valDateLabel.push({
            'value': `${month}/${day}/${year}`,
            'colspan': 15,
            'textAlign': 'right',
          });
        }
      }


      if (wellnesschecks.length === 180) {
        if (index === 0) {
          valDateLabel.push({
            'value': `${month}/${day}/${year}`,
            'colspan': 29,
            'textAlign': 'left',
          });
        }
        for (let i = 1; i < 180; i += 30) {
          if (index === i) {
            valDateLabel.push({
              'value': `${month}/${day}/${year}`,
              'colspan': 22,
              'textAlign': 'center',
            });
          }
        }
        if (index === 179) {
          valDateLabel.push({
            'value': `${month}/${day}/${year}`,
            'colspan': 29,
            'textAlign': 'right',
          });
        }
      }


      if (wellnesschecks.length === 270) {
        if (index === 0) {
          valDateLabel.push({
            'value': `${month}/${day}/${year}`,
            'colspan': 54,
            'textAlign': 'left',
          });
        }
        for (let i = 1; i < 180; i += 60) {
          if (index === i) {
            valDateLabel.push({
              'value': `${month}/${day}/${year}`,
              'colspan': 54,
              'textAlign': 'center',
            });
          }
        }
        if (index === 269) {
          valDateLabel.push({
            'value': `${month}/${day}/${year}`,
            'colspan': 54,
            'textAlign': 'right',
          });
        }
      }


      if (wellnesschecks.length === 365) {
        if (index === 0) {
          valDateLabel.push({
            'value': `${month}/${day}/${year}`,
            'colspan': 73,
            'textAlign': 'left',
          });
        }
        for (let i = 1; i < 180; i += 73) {
          if (index === i) {
            valDateLabel.push({
              'value': `${month}/${day}/${year}`,
              'colspan': 73,
              'textAlign': 'center',
            });
          }
        }
        if (index === 364) {
          valDateLabel.push({
            'value': `${month}/${day}/${year}`,
            'colspan': 73,
            'textAlign': 'right',
          });
        }
      }

      valLevelAnger.push({
        'value': (wellnesscheck?.Id) ? wellnesscheck.LevelAnger : undefined,
        'date': `${month}/${day}/${year}`,
        'color': (wellnesscheck?.Id) ? getLevelColor(wellnesscheck.LevelAnger) : '#717171',
      });

      console.log('wellnesscheck: ');
      console.log(wellnesscheck);

      valLevelAnxiety.push({
        'value': (wellnesscheck?.Id) ? wellnesscheck.LevelAnxiety : undefined,
        'date': `${month}/${day}/${year}`,
        'color': (wellnesscheck?.Id) ? getLevelColor(wellnesscheck.LevelAnxiety) : '#717171',
      });

      valLevelDepression.push({
        'value': (wellnesscheck?.Id) ? wellnesscheck.LevelDepression : undefined,
        'date': `${month}/${day}/${year}`,
        'color': (wellnesscheck?.Id) ? getLevelColor(wellnesscheck.LevelDepression) : '#717171',
      });

    });

    console.log('valLevelAnger: ', valLevelAnger);

    setDateLabel(valDateLabel);
    // setSumLevelDepression(sumValues(valLevelDepression));
    setLevelDepression(valLevelDepression);
    // setSumLevelAnxiety(sumValues(valLevelAnxiety));
    setLevelAnxiety(valLevelAnxiety)
    // setSumLevelAnger(sumValues(valLevelAnger));
    setLevelAnger(valLevelAnger);

  }, [wellnesschecks])

  return (
    <Box className='marks'>
      <Box className={classes.containerBox}>
        <Box className={classes.boxLabelAlert}>
          <table>
            <tbody>
            <tr>
              <th className={classes.labelAlert}>Depression</th>
            </tr>
            <tr>
              <th className={classes.labelAlert}>Anxiety</th>
            </tr>
            <tr>
              <th className={classes.labelAlert}>Anger</th>
            </tr>
            </tbody>
          </table>
        </Box>
        <Box className={classes.boxContentAlerts}>
          <table className={classes.tableContentAlerts}>
            <tbody>
            <tr>
                {levelDepression.map((data, index) => (<td key={index} style={{ backgroundColor: data.color, height: 80, border: 'none', margin: 0, padding: 0 }} title={`${data.date} - ${data.value || 'No data'}`}>
                &nbsp;
              </td>))}
            </tr>
            <tr>
                {levelAnxiety.map((data, index) => (<td key={index} style={{ backgroundColor: data.color, height: 80, border: 'none', margin: 0, padding: 0 }} title={`${data.date} - ${data.value || 'No data'}`}>
                &nbsp;
              </td>))}
            </tr>
            <tr>

                {levelAnger.map((data, index) => (<td key={index} style={{ backgroundColor: data.color, height: 80, border: 'none', margin: 0, padding: 0 }} title={`${data.date} - ${data.value || 'No data'}`}>
                &nbsp;
              </td>))}
            </tr>
            <tr>
              {dateLabel.map((data, index) => (<td key={index} style={{ textAlign: data.textAlign, fontSize: 10, backgroundColor: 'none', height: 40, border: 'none', margin: 0, padding: 0 }}
                colSpan={data.colspan}>
                {data.value}
              </td>))}
            </tr>
            </tbody>
          </table>
        </Box>
        {/* <Box>
          <table>
            <tbody>
            <tr>
              <th className={classes.SumAlert}> {sumLevelDepression || '-'}</th>
            </tr>
            <tr>
              <th className={classes.SumAlert}>{sumLevelAnxiety || '-'}</th>
            </tr>
            <tr>
              <th className={classes.SumAlert}>{sumLevelAnger || '-'}</th>
            </tr>
            </tbody>
          </table>
        </Box> */}
        <Box className={classes.labelColumn}>
          <Box className={classes.boxLabel} style={{ paddingLeft: 5 }}>
            <span className={classes.noDataLabel}></span>{"No data "}
          </Box>
          <Box className={classes.boxLabel}>
            <span className={classes.levelZeroLabel}></span>{"Level 0 "}
          </Box>
          <Box className={classes.boxLabel}>
            <span className={classes.levelOneLabel}></span>{"Level 1 "}
          </Box>
          <Box className={classes.boxLabel}>
            <span className={classes.levelTwoLabel}></span>{"Level 2 "}
          </Box>
          <Box className={classes.boxLabel}>
            <span className={classes.levelThreeLabel}></span>{"Level 3 "}
          </Box>
          <Box className={classes.boxLabel}>
            <span className={classes.levelFourLabel}></span>{"Level 4 "}
          </Box>
          <Box className={classes.boxLabel}>
            <span className={classes.levelFiveLabel}></span>{"Level 5 "}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
