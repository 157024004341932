import React from 'react';
import { RouteComponentProps } from "react-router-dom";
import swal from 'sweetalert';
import {
  CssBaseline,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  ButtonGroup
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import * as yup from 'yup';
import AppBar from '../../../Components/AppBar';
import LocalDrawer from '../../../Components/OrganizationManagerLocalDrawer';
import { Formik } from 'formik';

// CALL TO API
import { logout, createUser } from '../../../services/auth';
import { getMyOrganization } from './../../../services/organization';

import * as SanitizeString from '../../../services/sanitize_strings';

require('yup-phone');

interface Props extends RouteComponentProps {}

interface State {
  isLoading: boolean;
  toolbarOpen: boolean;
  validationError: string;
  organizationId: string;
}

class AddCareProvider extends React.Component<Props, State> {

  constructor(props) {
    super(props);

    this.state = {
      toolbarOpen: localStorage.getItem('toolbarOpen') === 'true' ? true : false,
      validationError: '',
      isLoading: false,
      organizationId: props.match.params.organizationId,
    }
  }

  componentDidMount () {
    this.setState({ isLoading: false });
    this.getOrganization();
  }

  private getOrganization = async () => {
    try {
      const response = await getMyOrganization();
      if (response?.data?.organization) {
        this.setState({ organizationId: response?.data?.organization.id });
      }
    } catch (error) {
      swal('Error', 'Error loading my organization', 'error');
    }
  }

  handleDrawerOpen = () => {
    localStorage.setItem('toolbarOpen', 'true');
    this.setState({ toolbarOpen: true });
  };

  handleDrawerClose = () => {
    localStorage.setItem('toolbarOpen', 'false');
    this.setState({ toolbarOpen: false });
  };

  handleSubmit = async (values) => {
    const obj = {
      name: values.name,
      email: SanitizeString.email(values.username),
      phone: SanitizeString.phoneNumber(values.phone),
      organizationId: this.state.organizationId,
      group: 'care_provider'
    }
    this.setState({ isLoading: true });
    try {
      await createUser(obj);
      swal('Success', 'Record saved', 'success');
      this.setState({ isLoading: false });
      this.props.history.push(`/organizationManager`);
    } catch (error) {
      console.log(error);
      this.setState({ isLoading: false });
      swal('Error', 'Error loading care providers', 'error');
    }
  }

  private handleBackClick = () => {
    this.props.history.push('/organizationManager/');
  }

  render () {
    return (
      <div className='root'>
        <CssBaseline />
        <AppBar
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerOpen={this.handleDrawerOpen}
          breadcrumbs={[]}
        />
        <LocalDrawer
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerClose={this.handleDrawerClose}
          logout={logout}
        />
        <main className='content'>
          <div className='toolbarIcon' />
          <Container maxWidth="lg" className='container'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div style={{ float: 'left' }}>
                  <Typography variant="h4" display="block" gutterBottom>
                    Add Care Provider
                  </Typography>
                </div>
                <ButtonGroup
                  color="primary"
                  aria-label='outlined primary button group'
                  style={{float: 'right'}} >
                  <Button
                    onClick={() => this.handleBackClick()}>
                    <ArrowBack fontSize="small" />
                    &nbsp;Back
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
            <Formik
              initialValues={{
                name: '',
                username: '',
                phone: '',
              }}
              validationSchema={yup.object().shape({
                name: yup.string().required("Must enter a name for the user"),
                username: yup.string().required("Must enter an email for the user"),
                phone: yup.string().phone().matches(/^\+[0-9]*$/, 'For phone numbers please add the "+" at the front followed by just numbers').required("Must enter a phone for the user")
              })}
              onSubmit = {(values) => {
                this.handleSubmit(values);
              }}
            >
            {({values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit}) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      id="name"
                      label="Name"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      fullWidth
                      error={errors.name && touched.name}
                      helperText={touched.name && errors.name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="username"
                      label="Email"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.username}
                      fullWidth
                      error={errors.username && touched.username}
                      helperText={touched.username && errors.username}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="phone"
                      label="Phone"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      placeholder="Example +12025550185"
                      fullWidth
                      error={errors.phone && touched.phone}
                      helperText={(touched.phone && errors.phone) || `Use the international standard (Example +12025550185)`}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      style={{ float: 'right' }}
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
            </Formik>
            {this.state.validationError}
          </Container>
        </main>
      </div>
    );
  }
}

export default AddCareProvider;
