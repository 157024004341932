import React from "react";
import { RouteComponentProps } from "react-router-dom";
import swal from "sweetalert";
import {
  CssBaseline,
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  MenuItem,
  CircularProgress,
  ButtonGroup,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";

import LocalDrawer from "../../../Components/QActualAdminLocalDrawer";
import AppBar from "../../../Components/AppBar";

// CALL TO API
import { logout } from "../../../services/auth";
import * as QuestionApi from "../../../services/mobile/wellnessFormQuestionAnswers";
import { Formik, Field } from "formik";
import * as yup from "yup";
import { ArrowBack } from "@material-ui/icons";

interface Props extends RouteComponentProps {}

interface State {
  isLoading: boolean;
  toolbarOpen: boolean;
  organizationId: string;
  formId: string;
  validationError: string;
}

class AddFormQuestion extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      toolbarOpen:
        localStorage.getItem("toolbarOpen") === "true" ? true : false,
      organizationId: props.match.params.organizationId,
      formId: props.match.params.formId,
      isLoading: false,
      validationError: "",
    };
  }

  componentDidMount() {}

  private handleDrawerOpen = () => {
    localStorage.setItem("toolbarOpen", "true");
    this.setState({ toolbarOpen: true });
  };

  private handleDrawerClose = () => {
    localStorage.setItem("toolbarOpen", "false");
    this.setState({ toolbarOpen: false });
  };

  private handleBackClick = () => {
    const { organizationId, formId } = this.state;

    this.props.history.push(
      `/qactual/admin/organizations/${organizationId}/form/${formId}`
    );
  };

  handleSubmit = async (params: any) => {
    const { formId } = this.state;

    try {
      this.setState({ isLoading: true });
      const response = await QuestionApi.createQuestion({
        ...params,
        form: formId,
      });
      if (response.status === 201) {
        this.setState({ isLoading: false });
        swal("Success", "Record saved", "success");
        this.handleBackClick();
      }
    } catch (error) {
      this.setState({ isLoading: false });
      swal("Error", "Something went wrong", "error");
    }
  };

  CustomizedSelectForFormik = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <TextField
        name={name}
        value={value}
        select
        label="Question Type"
        variant="outlined"
        fullWidth
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </TextField>
    );
  };

  render() {
    return (
      <div className="root">
        <CssBaseline />
        <AppBar
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerOpen={this.handleDrawerOpen}
          breadcrumbs={[]}
        />
        <LocalDrawer
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerClose={this.handleDrawerClose}
          logout={logout}
        />
        <main className="content">
          <div className="toolbarIcon" />
          <Container maxWidth="lg" className="container">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div style={{ float: "left" }}>
                  <Typography variant="h4" display="block" gutterBottom>
                    Create Question
                  </Typography>
                </div>
                <ButtonGroup
                  color="primary"
                  aria-label="outlined primary button group"
                  style={{ float: "right" }}
                >
                  <Button onClick={() => this.handleBackClick()}>
                    <ArrowBack fontSize="small" />
                    &nbsp;Back
                  </Button>
                </ButtonGroup>
              </Grid>
              <Paper
                className="paper"
                style={{ paddingTop: 40, width: "100%" }}
              >
                <Formik
                  initialValues={{
                    questionTitle: "",
                    questionType: "",
                    defaultValue: "",
                    sliderMin: undefined,
                    sliderMax: undefined,
                  }}
                  validationSchema={yup.object().shape({
                    questionTitle: yup
                      .string()
                      .required(
                        "You must provide a title to your new question"
                      ),
                    // questionType: yup.string().required("You must provide a type to your new question"),
                    defaultValue: yup.string(),
                  })}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    // console.log(values)
                    this.handleSubmit({
                      questionTitle: values.questionTitle,
                      questionType: values.questionType,
                      defaultValue: values.defaultValue,
                      sliderMin: values.sliderMin,
                      sliderMax: values.sliderMax,
                      active: true,
                    });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    isSubmitting,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            id="questionTitle"
                            label="Title"
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.questionTitle}
                            fullWidth
                            error={
                              errors.questionTitle && touched.questionTitle
                            }
                            helperText={
                              touched.questionTitle && errors.questionTitle
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Field
                            id="questionType"
                            name="questionType"
                            component={this.CustomizedSelectForFormik}
                          >
                            {/* <MenuItem key='questionType.text' value="text">Text</MenuItem> */}
                            <MenuItem
                              key="questionType.check"
                              selected
                              value="check"
                            >
                              Check
                            </MenuItem>
                            <MenuItem key="questionType.slider" value="slider">
                              Slider
                            </MenuItem>
                          </Field>
                        </Grid>
                      </Grid>
                      {values.questionType === "slider" ? (
                        <div>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <TextField
                                id="sliderMin"
                                label="Slider Min Value"
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.sliderMin}
                                fullWidth
                                error={errors.sliderMin && touched.sliderMin}
                                helperText={
                                  touched.sliderMin && errors.sliderMin
                                }
                              />
                            </Grid>
                          </Grid>

                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <TextField
                                id="sliderMax"
                                label="Slider Max Value"
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.sliderMax}
                                fullWidth
                                error={errors.sliderMax && touched.sliderMax}
                                helperText={
                                  touched.defaulsliderMaxtValue &&
                                  errors.sliderMax
                                }
                              />
                            </Grid>
                          </Grid>
                        </div>
                      ) : null}
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            id="defaultValue"
                            label="Default Answer"
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.defaultValue}
                            fullWidth
                            error={errors.defaultValue && touched.defaultValue}
                            helperText={
                              touched.defaultValue && errors.defaultValue
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            style={{ float: "right" }}
                            size="large"
                            disabled={isSubmitting}
                            startIcon={
                              this.state.isLoading ? (
                                <CircularProgress />
                              ) : (
                                <SaveIcon />
                              )
                            }
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
                {this.state.validationError}
              </Paper>
            </Grid>
          </Container>
        </main>
      </div>
    );
  }
}

export default AddFormQuestion;
