import React, { useEffect, useState } from "react";
import {
  CssBaseline,
  Container,
  Grid,
  CircularProgress,
  // Box,
  // Typography,
} from '@material-ui/core';
// import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '../../../Components/AppBar';
import LocalDrawer from '../../../Components/QActualAdminLocalDrawer';
import BoxClinician from '../../../Components/BoxClinician';
import swal from 'sweetalert';
// import { useHistory } from 'react-router-dom';

// CALL TO API
import { logout } from '../../../services/auth';
import { getAllCareProviders } from '../../../services/careProvider';
import Logo from "../../../assets/images/logoTitleGreen.svg";

interface Clinician {
  id: string;
  name: string;
  phone: string;
  username: string;
}

// const useStyles = makeStyles((theme: Theme) => ({
//   toggleBox: {
//     display: 'flex',
//     border: '3px solid #6E6E6E',
//     borderRadius: 15,
//     padding: 0,
//     margin: 0,
//     backgroundColor: '#FFFFFF',
//     cursor: 'pointer',
//   },
//   toggleSelected: {
//     display: 'flex',
//     width: 250,
//     height: 120,
//     backgroundColor: '#414141',
//     color: '#FFFFFF',
//     borderRadius: 11,
//     borderRight: '3px solid #6E6E6E',
//     justifyContent: 'center',
//     alignItems: 'center',
//     textAlign: 'center',
//     fontSize: 26,
//     paddingLeft: 30,
//     paddingRight: 30,
//     fontWeight: 'bold',

//   },
//   toggleUnselected: {
//     display: 'flex',
//     width: 100,
//     paddingRight: 10,
//     paddingLeft: 10,
//     height: 120,
//     backgroundColor: '#FFFFFF',
//     borderRadius: 11,
//     justifyContent: 'center',
//     alignItems: 'center',
//     textAlign: 'center',
//     fontSize: 16,
//     fontWeight: 600,
//   },
// }));



const ListAllClinician = (): JSX.Element => {
  // const history = useHistory();
  const [toolbarOpen, setToolbarOpen] = useState<boolean>(localStorage.getItem('toolbarOpen') === 'true' ? true : false);
  const [clinicianData, setClinicianData] = useState<Clinician[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  // const classes = useStyles();
  const getClinician = async () => {
    setLoading(true);
    try {
      const response = await getAllCareProviders();
      if (response?.data?.user) {
        setClinicianData(response?.data?.user);
      } else {
        setClinicianData([]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      swal('Error', 'Error loading clinicians', 'error');
    }
  };

  useEffect(() => {
    getClinician();
  }, []);

  const handleDrawerOpen = () => {
    console.log('Opening toolbar');
    localStorage.setItem('toolbarOpen', 'true');
    setToolbarOpen(true);
  };

  const handleDrawerClose = () => {
    console.log('Closing toolbar');
    localStorage.setItem('toolbarOpen', 'false');
    setToolbarOpen(false);
  };


  // const handleToggle = () => {
  //   history.replace('/qactual/admin/patients');
  // };

  return (
    <div className='root'>
      <CssBaseline />
      <AppBar
        toolbarOpen={toolbarOpen}
        handleDrawerOpen={handleDrawerOpen}
        breadcrumbs={[]}
      />
      <LocalDrawer
        toolbarOpen={toolbarOpen}
        handleDrawerClose={handleDrawerClose}
        logout={logout}
      />
      <main className='content'>
        <div className='toolbarIcon' />
        <Container maxWidth="lg" className='container'>
          <Grid container>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <img src={Logo} alt="Logo" style={{ width: "90%" }} />
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <Grid container justify="flex-end">
                <Grid item>
                  {/* <Box className={classes.toggleBox} onClick={handleToggle} >
                    <Box>
                      <Typography className={classes.toggleSelected}>CLINICIAN VIEW</Typography>
                    </Box>
                    <Box><Typography className={classes.toggleUnselected}>PATIENT VIEW</Typography></Box>
                  </Box> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            wrap="wrap"
            style={{
              marginTop: "50px",
            }}
          >
            {loading ? (<CircularProgress size={50} />) : <>
              {clinicianData.length && clinicianData.map(({ id, name }) => (<Grid item xs={12} sm={6} md={3} lg={2} xl={2} key={id}>
                <BoxClinician
                  name={name}
                  to={`/qactual/admin/clinician/${id}`}
                />
              </Grid>))}
            </>}
          </Grid>
        </Container>
      </main>
    </div>
  );
};

export default ListAllClinician;
