import React from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  CssBaseline,
  Container,
  Grid,
  TextField,
  Typography,
  Button,
  CircularProgress,
  ButtonGroup,
  Paper,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import LocalDrawer from "../../../Components/QActualAdminLocalDrawer";
import AppBar from "../../../Components/AppBar";
import { Formik } from "formik";
import * as yup from "yup";
import swal from "sweetalert";
import SaveIcon from "@material-ui/icons/Save";

// CALL TO API
import { logout } from "../../../services/auth";
import API from "../../../services/api";
import { string } from "yup";

interface Props extends RouteComponentProps {}

interface State {
  isLoading: boolean;
  toolbarOpen: boolean;
  organizationId: string;
  modalRemoveIsOpen: boolean;
  resourceId: string;
  resourceData: {
    title: string;
    description: string;
    phoneNumber: string;
    textMessage: string;
    website: string;
  };
}

class EditResource extends React.Component<Props, State> {
  private fileSelector: HTMLInputElement;

  constructor(props) {
    super(props);

    this.state = {
      toolbarOpen:
        localStorage.getItem("toolbarOpen") === "true" ? true : false,
      organizationId: props.match.params.organizationId,
      resourceId: props.match.params.resourceId,
      isLoading: false,
      modalRemoveIsOpen: false,
      resourceData: {
        title: "",
        description: "",
        phoneNumber: "",
        textMessage: "",
        website: "",
      },
    };
  }

  componentDidMount() {
    this.getResource();
  }

  private getResource = async () => {
    const { resourceId } = this.state;
    console.log("resourceId:", resourceId);

    const response: any = await API.get(`/resources/${resourceId}`).catch(
      (error) => {
        console.log("[getResource] error:", error);
      }
    );
    const resourceData = response.data.resource;
    console.log("resourceData:", resourceData);
    this.setState({ resourceData });
  };

  private handleDrawerOpen = () => {
    localStorage.setItem("toolbarOpen", "true");
    this.setState({ toolbarOpen: true });
  };

  private handleDrawerClose = () => {
    localStorage.setItem("toolbarOpen", "false");
    this.setState({ toolbarOpen: false });
  };

  handleSubmit = async (params: any) => {
    try {
      this.setState({ isLoading: true });

      const { resourceId, organizationId } = this.state;

      const payload = {
        resourceId,
        description: params.description,
        phoneNumber: params.phoneNumber,
        website: params.website,
        textMessage: params.textMessage,
        title: params.title,
      };

      const response = await API.put("/resources", payload);

      if (response.data.status === "success") {
        this.setState({ isLoading: false });
        swal("Success", "Record updated", "success");
        this.props.history.push(
          `/qactual/admin/organizations/${organizationId}/resources/${resourceId}`
        );
      }
    } catch (error) {
      this.setState({ isLoading: false });
      swal("Error", "Something went wrong", "error");
    }
  };

  private handleBackClick = () => {
    const { organizationId, resourceId } = this.state;

    this.props.history.push(
      `/qactual/admin/organizations/${organizationId}/resources/${resourceId}`
    );
  };

  render() {
    const { isLoading, organizationId, resourceData } = this.state;

    // console.log('resourceData:', resourceData);

    return (
      <div className="root">
        <CssBaseline />
        <AppBar
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerOpen={this.handleDrawerOpen}
          breadcrumbs={[]}
        />
        <LocalDrawer
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerClose={this.handleDrawerClose}
          logout={logout}
        />
        <main className="content">
          <div className="toolbarIcon" />
          <Container maxWidth="lg" className="container">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div style={{ float: "left" }}>
                  <Typography variant="h4" display="block" gutterBottom>
                    Edit Resource
                  </Typography>
                </div>
                <ButtonGroup
                  color="primary"
                  aria-label="outlined primary button group"
                  style={{ float: "right" }}
                >
                  <Button onClick={() => this.handleBackClick()}>
                    <ArrowBack fontSize="small" />
                    &nbsp;Back
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
            <Paper className="paper" style={{ paddingTop: 40 }}>
              <Formik
                enableReinitialize
                initialValues={{ ...resourceData }}
                validationSchema={yup.object().shape({
                  title: yup
                    .string()
                    .required("You must provide a title to your new resource"),
                  description: yup
                    .string()
                    .required(
                      "You must provide a description to your new resource"
                    ),
                  phoneNumber: yup.string(),
                  textMessage: yup.string(),
                  website: yup.string(),
                })}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  this.handleSubmit({
                    title: values.title,
                    description: values.description,
                    phoneNumber: values.phoneNumber,
                    textMessage: values.textMessage,
                    website: values.website,
                  });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          id="title"
                          label="Title"
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.title}
                          fullWidth
                          error={errors.title && touched.title}
                          helperText={touched.title && errors.title}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          id="description"
                          label="Description"
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.description}
                          fullWidth
                          error={errors.description && touched.description}
                          helperText={touched.description && errors.description}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          id="phoneNumber"
                          label="Phone Number"
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phoneNumber}
                          fullWidth
                          error={errors.phoneNumber && touched.phoneNumber}
                          helperText={touched.phoneNumber && errors.phoneNumber}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          id="textMessage"
                          label="Text Message"
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.textMessage}
                          fullWidth
                          error={errors.textMessage && touched.textMessage}
                          helperText={touched.textMessage && errors.textMessage}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          id="website"
                          label="Website"
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.website}
                          fullWidth
                          error={errors.website && touched.website}
                          helperText={touched.website && errors.website}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          style={{ float: "right" }}
                          disabled={isSubmitting}
                          startIcon={
                            isLoading ? (
                              <CircularProgress size={24} />
                            ) : (
                              <SaveIcon />
                            )
                          }
                          size="large"
                        >
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </Paper>
          </Container>
        </main>
      </div>
    );
  }
}

export default EditResource;
