import React, { FC, useState, useEffect } from 'react';
import swal from 'sweetalert';
import {
    Modal,
    Grid,
    Button,
    Checkbox,
    Divider,
    createStyles,
    makeStyles,
    Box,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    CircularProgress,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

// API
import { getOrganizationWithCareProviders } from '../../../services/organization';
import { addCareProviders } from '../../../services/patient';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    paper: {
        position: 'absolute',
        width: 500,
        minHeight: 400,
        backgroundColor: "#F3F3F3",
        border: '2px solid #CCC',
        padding: 5,
        paddingTop: '3%',
        paddingBottom: '3%',
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
    },
    list: {
        height: 300,
        overflow: 'auto',
    }
  }),
);

interface Props {
    open: boolean;
    handleClose: Function;
    handleOpen: Function;
    patient: any;
    user: any;
}

const AddAssignCareProvider: FC<Props> = (props): JSX.Element => {

    const [careProviders, setCareProviders] = useState([]);
    const [checked, setChecked] = useState(new Map());
    const [checkedAll, setCheckedAll] = useState(false);
    const [didMount, setDidMount] = useState(false);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        setDidMount(true);
        if (props.user.OrganizationId) {
            getCareProvidersByOrganizationId();
        }
        return () => setDidMount(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.user.OrganizationId, props.patient?.CareProviderPatients]);

    const getCareProvidersByOrganizationId = async () => {
        try {
            setLoading(true);
            const resp = await getOrganizationWithCareProviders(props.user.OrganizationId);

            if (resp.data?.organization?.Users) {
                const cares = resp.data.organization.Users;
                setCareProviders(cares);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    const handleConfirmSubmit = async (event) => {
        event.preventDefault();

        swal({
            title: "Warning",
            text: "Do you want to add this Care Providers to the patient?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async (okSubmit) => {
            if (okSubmit) {
                await handleSubmit();
            }
        });
    }

    const handleSubmit = async () => {
        let strCareProviders = [];

        checked.forEach((value, key) => {
            if (value) strCareProviders.push(key);
        });

        let data = { careProviders: strCareProviders }

        try {
            await addCareProviders(props.patient.id, data);

            swal('OK', 'Record saved successfully', 'success');
            let newChecked = checked;
            newChecked.clear();
            setChecked(newChecked);
            props.handleClose();
            window.location.reload();
        } catch (error) {
            swal('Error', 'Failed to save', 'error');
        }
    }

    const handleMultiChecked = (event) => {
        let isChecked = event.target.checked;
        setCheckedAll(isChecked);
        getCareProvidersByOrganizationId();

        let newChecked = checked;
        if (isChecked) {
            careProviders.map(user => newChecked.set(user.id, isChecked));
        } else {
            careProviders.map(user => newChecked.delete(user.id));
        }

        setChecked(newChecked);
    }

    const handleChange = (event) =>  {
        let name = event.target.name;
        let isChecked = event.target.checked;
        let newChecked = checked;

        if (isChecked) {
            newChecked.set(name, true);
        } else {
            newChecked.delete(name);
        }

        if (checkedAll) getCareProvidersByOrganizationId();

        setChecked(newChecked);
    }

    const isDisabledCheckbox = (userId: string) => {
        if (props.user.id === userId) return true;

        if (props.patient.CareProviderPatients.length) {
            return props.patient.CareProviderPatients.find(care => userId === care.UserId)
                ? true : false;
        }

        return false;
    }

    const formBody = () => (
        <div className={classes.paper}>
            <form onSubmit={handleConfirmSubmit}>
                <Grid item xs={12} >
                    <Typography variant="body1" gutterBottom style={{ textAlign: 'center' }}>
                        Add care providers to patient: <b>{props.patient.name}</b>
                    </Typography>
                </Grid>

                <List dense>
                    <ListItem>
                        <ListItemIcon>
                            <Checkbox
                                name="all"
                                checked={checkedAll}
                                onChange={handleMultiChecked}
                            />
                        </ListItemIcon>
                        <ListItemText primary="Select All" />
                    </ListItem>
                </List>
                {loading ? (
                    <div className={classes.root}>
                        <CircularProgress />
                    </div>
                ) : (
                    <List dense className={classes.list}>
                        {careProviders.map((user, index) => {
                            return (
                                <div key={index}>
                                    <ListItem>
                                        <ListItemIcon>
                                            {isDisabledCheckbox(user.id)
                                                ? <Checkbox disabled={true} checked={true} />
                                                :
                                                <Checkbox
                                                    name={user.id}
                                                    checked={checked.get(user.id)}
                                                    onChange={handleChange}
                                                />
                                            }
                                        </ListItemIcon>
                                        <ListItemText id={user.id} primary={user.name} />
                                    </ListItem>
                                    <Divider />
                                </div>
                            )
                        })}
                    </List>
                )}
                <Box mt={1}>
                    <Grid container justify="center">
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                size="large"
                                startIcon={<SaveIcon />}
                            >
                                SUBMIT
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </div>
    );

    if(!didMount) {
        return null;
    }

    return (
        <Modal
            open={props.open}
            onClose={() => {
                let newCheckeds = checked;
                newCheckeds.clear();
                setChecked(newCheckeds);

                setCheckedAll(false);

                props.handleClose();
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            {formBody()}
        </Modal>
    );
}

export default AddAssignCareProvider;