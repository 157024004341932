import API from './api';

export const getAllMyPatients = async () => await API.get(`/patients/myPatients`);

export const getAllPatients = async () => await API.get(`/patients`);

export const getById = async (id: string) => await API.get(`/patients/${id}`);

export const findPatient = async (data: any) => await API.post(`/patients/find`, data);

export const createPatient = async (patient: any) => await API.post(`/patients`, patient);

export const updatePatient = async (id: string, patient: any) => await API.put(`/patients/${id}`, patient);

export const deletePatient = async (id: string) =>  await API.delete(`/patients/${id}`);

export const createFormPHQ29 = async (patientId: String, formPHQ29: any) => await API.post(`/patients/${patientId}/phq29`, formPHQ29);

// ContactEvents
export const getAllContactEvents = async (patientId: string) => await API.get(`/patients/${patientId}/contactEvents`);

export const createContactEvents = async (patientId: string, data: any) => await API.post(`/patients/${patientId}/contactEvents`, data);

// PHQ29
export const phq29Logs = async (patientId: string) => await API.get(`/patients/${patientId}/phq29logs`);

// addMultiCareProviders
export const addCareProviders = async (patientId: string, data: any) => await API.post(`/patients/${patientId}/addCareProviders`, data);

export const getPatientWellnessCheck = async (patientId: string, days: number) => await API.get(`/patients/${patientId}/wellnesscheck/${days}`);
export const getPatientPHQ29 = async (patientId: string, days: number) => await API.get(`/patients/${patientId}/phq29/${days}`);

