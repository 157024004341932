import React, { FC, useEffect, useState } from 'react';
import { confirmPassword } from '../../services/auth';
import { RouteComponentProps, useHistory } from 'react-router';
import swal from 'sweetalert';
import {
  CssBaseline,
  Container,
  Grid,
  TextField,
  Button,
  Box,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../../assets/images/logoTitleGreen.svg';
import { useLocation } from 'react-router-dom';
import DoneAllIcon from '@material-ui/icons/DoneAll';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
    '&$focused': {
      backgroundColor: '#fff',
    },
    '& label.Mui-focused': {
      color: '#000',
      fontWeight: 'bold',
      fontSize: '150%',
      marginTop: -10,
    },
    '& .MuiInputLabel-asterisk': {
      color: '#9e3636',
    }, '& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled.Mui-disabled': {
      color: '#7f7f7f'
    }
  }
});

const ConfirmForgotPassword: FC<RouteComponentProps> = (props) => {

  let { pathname } = useLocation();
  const history = useHistory();

  const [email, setEmail] = useState < string > ('');
  const [code, setCode] = useState < string > ('');
  const [password, setPassword] = useState < string > ('');
  const [destination, setDestination] = useState < string > ('/');
  const [isLoading, setIsLoading] = useState < boolean > (false);

  useEffect(() => {
    switch (pathname) {
      case ('/qactual/admin/forgotPassword'):
        setDestination('/qactual/admin/login');
        break;

      case ('/organizationManager/forgotPassword'):
        setDestination('/organizationManager/login');
        break;

      case ('/cliniciandashboard/forgotPassword'):
        setDestination('/cliniciandashboard/login');
        break;

      default:
        break;
    }

    setEmail(localStorage.getItem('forgotPassword'));
  },[pathname]);

  const handleConfirmNewPassword = async (event: any) => {

    event.preventDefault();
    setIsLoading(true);
    try {
      const result = await confirmPassword({
        email: email,
        code: code,
        password: password
      });

      if (result?.data?.status === 'error') {
        swal("Error", result?.data?.err?.message, "error");
        setIsLoading(false);
      }else {
        swal("Success", "Password changed successfully!", "success");
        history.push(destination);
        setIsLoading(false);
      }
    } catch (error) {
      swal("Error", "Something went wrong!!", "error");
      setIsLoading(false);
    }
  }

  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <div>
          <a href={destination}>
            <img src={Logo} alt="Logo" className="logo-login" />
          </a>
          <form onSubmit={handleConfirmNewPassword}>
            <Box>
              <TextField
                className={classes.root}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                value={email}
                disabled
                autoComplete="email"
                autoFocus
              />
            </Box>

            <Box mt={1}>
              <TextField
                className={classes.root}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="code"
                label="Code"
                name="code"
                onChange={(e) => setCode(e.target.value)}
                value={code}
              />
            </Box>

            <Box mt={1}>
              <TextField
                className={classes.root}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="New Password"
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Box>

            <Box mt={2}>
              <Grid container justify="center">
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                    size="large"
                    startIcon={isLoading ? <CircularProgress size={24} /> : <DoneAllIcon />}
                  >
                    Confirm new password
                </Button>
                </Grid>
              </Grid>
            </Box>

          </form>
        </div>
      </Container>
    </>
  );
};

export default ConfirmForgotPassword;
