import { Box, Container, CssBaseline } from '@material-ui/core';
import React, { FC } from 'react';

// import Logo from '../../assets/images/logoTitleGreen.svg';
import Logo from '../../assets/images/logoTitleGreen.svg';
import GooglePlayLogo from '../../assets/images/google_play.png';
import AppStoreLogo from '../../assets/images/app_store.png';

const InstallInstructions: FC = () => {
  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="md">
        <div>
          <img src={Logo} alt="Logo" className="logo-login" style={{ width: 300 }} />
        </div>
        <Box width='80%'>
          <p>You have been invited to join the team on the QActual App.</p>
          <p>QActual is a peer to peer tool, used to create mental support networks by integrating technology with proven behavioral health strategies.</p>
          <p><b>Step 1:</b> Download the app and sign in.</p>
          <p>Download for Android:</p>
          <p>
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.qactual.global">
              <img src={GooglePlayLogo} style={{ width: 200 }}/>
            </a>
          </p>
          <p>Download for Apple:</p>
          <p>
            <a target="_blank" href="https://apps.apple.com/app/qactual/id1563908764">
              <img src={AppStoreLogo} style={{ width: 200 }}/>
            </a>
          </p>
          <p><b>Step 2:</b> Sign in using the same phone number that received the invitation text message.</p>
          <p><b>Step 3:</b> Accept the invitation in-app.</p>
          <p>Go to "Invitations" in the app menu and accept the invitation to the team.</p>
          <p><b>Step 4:</b> Review the built-in training.</p>
          <p>Thank you for using QActual!</p>
        </Box>
      </Container>
    </>
  );
};

export default InstallInstructions;