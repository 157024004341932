import React from 'react';
import { RouteComponentProps } from "react-router-dom";
import { Formik } from "formik";
import LocalDrawer from '../../../Components/OrganizationManagerLocalDrawer';
import LocalAppBar from '../../../Components/AppBar';
import swal from 'sweetalert';
import {
  CssBaseline,
  Container,
  Grid,
  Button,
  TextField,
  Typography,
  ButtonGroup
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import * as yup from 'yup';
import * as SanitizeString from '../../../services/sanitize_strings';

// CALL TO API
import { logout, getUser, editUser } from '../../../services/auth';

require('yup-phone');

interface Props extends RouteComponentProps {}

interface State {
  toolbarOpen: boolean;
  userId: string;
  validationError: string;
  userData: {
    id: string;
    name: string;
    username: string;
    phone: string;
  };
}

class EditCareProvider extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      toolbarOpen: localStorage.getItem('toolbarOpen') === 'true' ? true : false,
      userId: props.match.params.organizationManagerId,
      validationError: '',
      userData: {
        id: '',
        name: '',
        username: '',
        phone: '',
      },
    };
  }

  componentDidMount() {
    this.getUser();
  };

  private getUser = async () => {
    const { userId } = this.state;

    try {
      const resp = await getUser(userId);
      const userData = resp.data.user;
      this.setState({ userData });
    } catch (error) {
      console.log(error);
      swal('Error', 'Error loading user', 'error');
    }
  };

  private handleDrawerOpen = () => {
    localStorage.setItem('toolbarOpen', 'true');
    this.setState({ toolbarOpen: true });
  }

  private handleDrawerClose = () => {
    localStorage.setItem('toolbarOpen', 'false');
    this.setState({ toolbarOpen: false });
  }

  private handleSubmit = async (values) => {
    const { userId } = this.state;

    const obj = {
      id: userId,
      name: values.name,
      username: SanitizeString.email(values.username),
      phone: SanitizeString.phoneNumber(values.phone),
    }

    try {
      await editUser(obj, userId);
      this.props.history.push(`/organizationManager/${userId}`);
      swal('Success', 'Record updated', 'success');
    } catch (error) {
      console.log(error);
      swal('Error', 'Something went wrong', 'error');
    }
  }

  private handleBackClick = (userId: String) => {
    this.props.history.push(`/organizationManager/${userId}`);
  }

  render () {
    const { toolbarOpen, userData } = this.state;
    document.title = 'WeCare - Organization';
    return (
      <div className='root'>
        <CssBaseline />
        <LocalAppBar
          toolbarOpen={toolbarOpen}
          handleDrawerOpen={this.handleDrawerOpen}
          breadcrumbs={[]}
        />
        <LocalDrawer
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerClose={this.handleDrawerClose}
          logout={logout} />
        <main className='content'>
          <div className='toolbarIcon' />
          <Container maxWidth="lg" className='container'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div style={{ float: 'left' }}>
                  <Typography variant="h4" display="block" gutterBottom>
                    Edit care provider
                  </Typography>
                </div>
                <ButtonGroup
                  color="primary"
                  aria-label='outlined primary button group'
                  style={{float: 'right'}} >
                  <Button
                    onClick={() => this.handleBackClick(userData.id)}>
                    <ArrowBack fontSize="small" />
                    &nbsp;Back
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
            {userData.username && <Formik
              initialValues={{
                name: userData.name,
                username: userData.username,
                phone: userData.phone,
              }}
              validationSchema={yup.object().shape({
                name: yup.string().required("Must enter a name for the user"),
                username: yup.string().required("Must enter an email for the user"),
                phone: yup.string().phone().matches(/^\+[0-9]*$/, 'For phone numbers please add the "+" at the front followed by just numbers').required("Must enter a phone for the user")
              })}
              onSubmit = {(values) => {
                this.handleSubmit(values);
              }}
            >
              {({values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        id="name"
                        label="Name"
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        fullWidth
                        error={errors.name && touched.name}
                        helperText={touched.name && errors.name}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="email"
                        label="Email"
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.username}
                        fullWidth
                        error={errors.username && touched.username}
                        helperText={touched.username && errors.username}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="phone"
                        label="Phone"
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Example +12025550185"
                        value={values.phone}
                        fullWidth
                        error={errors.phone && touched.phone}
                        helperText={(touched.phone && errors.phone) || `Use the international standard (Example +12025550185)`}
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        style={{ float: 'right' }}
                        disabled={isSubmitting}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
            }
            {this.state.validationError}
          </Container>
        </main>
      </div>
    );
  }

}

export default EditCareProvider;
