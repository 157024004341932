import API from './api';

// CRUD ORGANIZATION
export const getAllOrganizations = async () => await API.get('/organizations');

export const getOrganization = async (organizationId: string) => await API.get(`/organizations/${organizationId}/orgAdmins`);

export const createNewOrganization = async (params: any) => await API.post('/organizations/', params);

export const editOrganization = async (params: any, organizationId: string) => await API.put(`/organizations/${organizationId}`, { organization: params })

export const deleteUserOrganization = async (id: string) => {
  const response = await API.delete(`/organizations/admin/${id}`);
  return response;
}

export const getOrganizationWithCareProviders = async (organizationId: string) => await API.get(`/organizations/${organizationId}/careProviders`);

export const addOrganizationManager = async (user: any) => API.post(`/organizations/addOrganizationManager`, user);

export const editOrganizationManager = async (user: any) => API.put(`/organizations/editOrganizationManager`, user);

export const addCareProvider = async (user: any) => API.post(`/organizations/addCareProvider`, user);

export const getMyOrganization = async () => await API.get('/organizations/myOrganizationAsOrgManager');
export const getAllCareProvidersWithoutObsolete = async () => await API.get(`/organizations/getAllCareProvidersOfAOrganization/`);

export const getSeatBatches = async (organizationId: string) => await API.get(`/seats_batch/list/${organizationId}`);

// ORG ADMINS
export const listAllUserOrgAdmins = async (organizationId: string) => await API.get(`/organizations/${organizationId}/orgAdmins`);
export const listAllUserOrgAdminsActivateDeactivate = async (organizationId: string) => await API.get(`/organizations/${organizationId}/orgAdminsActivateAndDeactivate`);
export const deleteOrgAdmins = async (organizationId: string) => await API.delete(`/organizations/${organizationId}`);

export const addResourcesOrganization = async (resourceGroupId: string, resource: any) =>
  await API.post(`/resources/${resourceGroupId}`, resource);

export const deleteResourceOrganization = async (organizationId: string, resourceId: string) =>
  await API.delete(`/organizations/${organizationId}/resources/${resourceId}`);