import React from 'react';
import { RouteComponentProps } from "react-router-dom";
import swal from 'sweetalert';
import {
  CssBaseline,
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  CircularProgress,
  ButtonGroup,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

import LocalDrawer from '../../../Components/QActualAdminLocalDrawer';
import AppBar from '../../../Components/AppBar';

// CALL TO API
import { logout } from '../../../services/auth';
import { listAllUserOrgAdminsActivateDeactivate, addResourcesOrganization } from  '../../../services/organization';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ArrowBack } from '@material-ui/icons';

interface Props extends RouteComponentProps {}

interface State {
  isLoading: boolean;
  toolbarOpen: boolean;
  organizationId: string;
  validationError: string;
  organizationData: {
    name: '',
    shortName: '',
    category: '',
    appVersion: '',
    theme: '',
    logo: '',
    ddlURL: '',
    resourceGroupId: '',
  },
}

class AddResourceOrganization extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      toolbarOpen: localStorage.getItem('toolbarOpen') === 'true' ? true : false,
      organizationId: props.match.params.organizationId,
      isLoading: false,
      validationError: '',
      organizationData: {
        name: '',
        shortName: '',
        category: '',
        appVersion: '',
        theme: '',
        logo: '',
        ddlURL: '',
        resourceGroupId: '',
      },
    };
  }

  componentDidMount() {
    this.getOrganization();
  }

  private getOrganization = async () => {
    const { organizationId } = this.state;

    try {
      const response = await listAllUserOrgAdminsActivateDeactivate(organizationId);
      const organizationData = response.data.organization;
      this.setState({ organizationData });
    } catch (error) {
      console.log(error);
      swal('Error', 'Error loading organization', 'error');
    }
  };

  private handleDrawerOpen = () => {
    localStorage.setItem('toolbarOpen', 'true');
    this.setState({ toolbarOpen: true });
  };

  private handleDrawerClose = () => {
    localStorage.setItem('toolbarOpen', 'false');
    this.setState({ toolbarOpen: false });
  };

  private handleBackClick = () => {
    const { organizationId } = this.state;

    this.props.history.push(`/qactual/admin/organizations/${organizationId}/resources`);
  }

  handleSubmit = async (params: any) => {
    const {
      organizationData,
    } = this.state;

    try {
      this.setState({ isLoading: true });
      const response = await addResourcesOrganization(organizationData.resourceGroupId, params);
      if ( response.data.status === 'success' ) {
        this.setState({ isLoading: false });
        swal('Success', 'Record saved', 'success');
        this.handleBackClick();
      }
    } catch (error) {
      this.setState({ isLoading: false });
      swal('Error', 'Something went wrong', 'error');
    }
  }

  render () {
    const {
      organizationId,
      organizationData,
    } = this.state;

    return (
      <div className='root'>
        <CssBaseline />
        <AppBar
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerOpen={this.handleDrawerOpen}
          breadcrumbs={[]}
        />
        <LocalDrawer
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerClose={this.handleDrawerClose}
          logout={logout}
        />
        <main className='content'>
          <div className='toolbarIcon' />
          <Container maxWidth="lg" className='container'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div style={{ float: 'left' }}>
                  <Typography variant="h4" display="block" gutterBottom>
                    {`Organization - ${organizationData.name}`}
                  </Typography>
                </div>
                <ButtonGroup
                  color="primary"
                  aria-label='outlined primary button group'
                  style={{float: 'right'}} >
                  <Button
                    onClick={() => this.handleBackClick()}>
                    <ArrowBack fontSize="small" />
                    &nbsp;Back
                  </Button>
                </ButtonGroup>
              </Grid>
              <Paper className="paper" style={{ paddingTop: 40, width: '100%' }}>
                <Formik
                  initialValues={{
                    title: '',
                    description: '',
                    phoneNumber: '',
                    textMessage: '',
                    website: '',
                  }}
                  validationSchema={yup.object().shape({
                    title: yup.string().required("You must provide a title to your new resource"),
                    description: yup.string().required("You must provide a description to your new resource"),
                    phoneNumber: yup.string(),
                    textMessage: yup.string(),
                    website: yup.string(),
                  })}
                  onSubmit={(values, {setSubmitting, resetForm}) => {
                    this.handleSubmit({
                      title: values.title,
                      description: values.description,
                      phoneNumber: values.phoneNumber,
                      textMessage: values.textMessage,
                      website: values.website,
                    });
                  }}
                >
                {({values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit}) => (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            id="title"
                            label="Title"
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.title}
                            fullWidth
                            error={errors.title && touched.title}
                            helperText={touched.title && errors.title}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            id="description"
                            label="Description"
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.description}
                            fullWidth
                            error={errors.description && touched.description}
                            helperText={touched.description && errors.description}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            id="phoneNumber"
                            label="Phone Number"
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phoneNumber}
                            fullWidth
                            error={errors.phoneNumber && touched.phoneNumber}
                            helperText={touched.phoneNumber && errors.phoneNumber}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            id="textMessage"
                            label="SMS Text Message Number"
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.textMessage}
                            fullWidth
                            error={errors.textMessage && touched.textMessage}
                            helperText={touched.textMessage && errors.textMessage}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            id="website"
                            label="Website"
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.website}
                            fullWidth
                            error={errors.website && touched.website}
                            helperText={touched.website && errors.website}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            style={{ float: 'right' }}
                            size="large"
                            disabled={isSubmitting}
                            startIcon={this.state.isLoading ? <CircularProgress /> : <SaveIcon /> }
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
                {this.state.validationError}
              </Paper>
            </Grid>
          </Container>
        </main>
      </div>
    );
  }
}

export default AddResourceOrganization;
