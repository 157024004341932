import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  phq29s: {
    interestOnDoingThings: number,
    feelingDown: number,
    sleepingToMuch: number,
    feelingTired: number,
    appetiteDisturbance: number,
    feelingBad: number,
    concentrationTrouble: number,
    movingSlow: number,
    betterDead: number,
    createdAt?: Date,
    date?: Date,
    id?: number,
  }[],
};

const useStyles = makeStyles({
  containerBox: {
    width: '100%',
    display: 'flex',
    paddingLeft: 22,
  },
  boxLabelAlert: {
    width: 100,
  },
  labelAlert: {
    width: 80,
    height: 60,
  },
  boxContentAlerts: {
    width: 600,
    overflow: 'auto'
  },
  tableContentAlerts: {
    width: '100%',
  },
  SumAlert: {
    width: 30,
    height: 60,
    backgroundColor: '#FFFFFF'
  },
  labelColumn: {
    marginTop: 150,
  },
  boxLabel: {

    height: 50,
  },
  noDataLabel: {
    backgroundColor: '#717171',
    width: 20,
    height: 20,
    margin: 10,
    padding: '10px 20px',
  },
  levelZeroLabel: {
    backgroundColor: '#FFFFFF',
    width: 20,
    height: 20,
    margin: 10,
    padding: '10px 20px',
  },
  levelOneLabel: {
    backgroundColor: '#FFFF00',
    width: 20,
    height: 20,
    margin: 10,
    padding: '10px 20px',
  },
  levelTwoLabel: {
    backgroundColor: '#FF6C01',
    width: 20,
    height: 20,
    margin: 10,
    padding: '10px 20px',
  },
  levelThreeLabel: {
    backgroundColor: '#DC0000',
    width: 20,
    height: 20,
    margin: 10,
    padding: '10px 20px',
  },


});

export default function DemoChart({ phq29s }: Props): JSX.Element {

  const classes = useStyles();

  const [interestOnDoingThings, setInterestOnDoingThings] = useState([]);
  const [feelingDown, setFeelingDown] = useState([]);
  const [sleepingToMuch, setSleepingToMuch] = useState([]);
  const [feelingTired, setFeelingTired] = useState([]);
  const [appetiteDisturbance, setAppetiteDisturbance] = useState([]);
  const [feelingBad, setFeelingBad] = useState([]);
  const [concentrationTrouble, setConcentrationTrouble] = useState([]);
  const [movingSlow, setMovingSlow] = useState([]);
  const [betterDead, setBetterDead] = useState([]);
  const [dateLabel, setDateLabel] = useState([]);
  // const [sumInterestOnDoingThings, setSumInterestOnDoingThings] = useState();
  // const [sumFeelingDown, setSumFeelingDown] = useState();
  // const [sumSleepingToMuch, setSumSleepingToMuch] = useState();
  // const [sumFeelingTired, setSumFeelingTired] = useState();
  // const [sumAppetiteDisturbance, setSumAppetiteDisturbance] = useState();
  // const [sumFeelingBad, setSumFeelingBad] = useState();
  // const [sumConcentrationTrouble, setSumConcentrationTrouble] = useState();
  // const [sumMovingSlow, setSumMovingSlow] = useState();
  // const [sumBetterDead, setSumBetterDead] = useState();
  const [valSum, setSum] = useState([]);

  const getLevelColor = (level: number): string => {
    switch (level) {
      case 0:
        return '#FFFFFF'

      case 1:
        return '#FFFF00'

      case 2:
        return '#FF6C01'

      case 3:
        return '#DC0000'
      default:
        return '#717171'
    }
  }

  // const sumValues = (data) => {
  //   let intSum = undefined;
  //   data.forEach(({ value }) => {
  //     if (value !== undefined) {
  //       intSum = parseInt(intSum) || 0;
  //       intSum += value;

  //     }
  //   });
  //   return intSum;
  // };

  useEffect(() => {

    let valInterestOnDoingThings = [];
    let valFeelingDown = [];
    let valSleepingToMuch = [];
    let valFeelingTired = [];
    let valAppetiteDisturbance = [];
    let valFeelingBad = [];
    let valConcentrationTrouble = [];
    let valMovingSlow = [];
    let valBetterDead = [];
    let valDateLabel = [];
    let valSum = [];

    

    phq29s.forEach((phq29, index) => {
      let month = '';
      let day = '';
      let year = '';


      if (phq29.date) {
        phq29.date = new Date(phq29.date);
        month = `${phq29.date.getUTCMonth() + 1}`;
        day = `${phq29.date.getUTCDate()}`;
        year = `${phq29.date.getUTCFullYear()}`;

      } else {
        phq29.createdAt = new Date(phq29.createdAt);
        month = `${phq29.createdAt.getUTCMonth() + 1}`;
        day = `${phq29.createdAt.getUTCDate()}`;
        year = `${phq29.createdAt.getUTCFullYear()}`;
      }

      month = month.length === 1 ? `0${month}` : month;

      day = day.length === 1 ? `0${day}` : day;

      if (phq29s.length === 7) {

        valDateLabel.push({
          'value': `${month}/${day}/${year}`,
          'colspan': 1,
          'textAlign': 'center',
        });
      }

      if (phq29s.length === 30) {
        if (index === 0) {
          valDateLabel.push({
            'value': `${month}/${day}/${year}`,
            'colspan': 4,
            'textAlign': 'left',
          });
        }
        for (let i = 1; i < 30; i += 7) {
          if (index === i) {
            valDateLabel.push({
              'value': `${month}/${day}/${year}`,
              'colspan': 4,
              'textAlign': 'center',
            });
          }
        }
        if (index === 29) {
          valDateLabel.push({
            'value': `${month}/${day}/${year}`,
            'colspan': 6,
            'textAlign': 'right',
          });
        }
      }


      if (phq29s.length === 90) {
        if (index === 0) {
          valDateLabel.push({
            'value': `${month}/${day}/${year}`,
            'colspan': 15,
            'textAlign': 'left',
          });
        }
        for (let i = 1; i < 90; i += 22) {
          if (index === i) {
            valDateLabel.push({
              'value': `${month}/${day}/${year}`,
              'colspan': 12,
              'textAlign': 'center',
            });
          }
        }
        if (index === 89) {
          valDateLabel.push({
            'value': `${month}/${day}/${year}`,
            'colspan': 15,
            'textAlign': 'right',
          });
        }
      }


      if (phq29s.length === 180) {
        if (index === 0) {
          valDateLabel.push({
            'value': `${month}/${day}/${year}`,
            'colspan': 29,
            'textAlign': 'left',
          });
        }
        for (let i = 1; i < 180; i += 30) {
          if (index === i) {
            valDateLabel.push({
              'value': `${month}/${day}/${year}`,
              'colspan': 22,
              'textAlign': 'center',
            });
          }
        }
        if (index === 179) {
          valDateLabel.push({
            'value': `${month}/${day}/${year}`,
            'colspan': 39,
            'textAlign': 'right',
          });
        }
      }


      if (phq29s.length === 270) {
        if (index === 0) {
          valDateLabel.push({
            'value': `${month}/${day}/${year}`,
            'colspan': 54,
            'textAlign': 'left',
          });
        }
        for (let i = 1; i < 180; i += 60) {
          if (index === i) {
            valDateLabel.push({
              'value': `${month}/${day}/${year}`,
              'colspan': 54,
              'textAlign': 'center',
            });
          }
        }
        if (index === 269) {
          valDateLabel.push({
            'value': `${month}/${day}/${year}`,
            'colspan': 54,
            'textAlign': 'right',
          });
        }
      }


      if (phq29s.length === 365) {
        if (index === 0) {
          valDateLabel.push({
            'value': `${month}/${day}/${year}`,
            'colspan': 73,
            'textAlign': 'left',
          });
        }
        for (let i = 1; i < 180; i += 73) {
          if (index === i) {
            valDateLabel.push({
              'value': `${month}/${day}/${year}`,
              'colspan': 73,
              'textAlign': 'center',
            });
          }
        }
        if (index === 364) {
          valDateLabel.push({
            'value': `${month}/${day}/${year}`,
            'colspan': 73,
            'textAlign': 'right',
          });
        }
      }
      valInterestOnDoingThings.push({
        'value': (phq29?.id) ? phq29.interestOnDoingThings : undefined,
        'date': `${month}/${day}/${year}`,
        'color': (phq29?.id) ? getLevelColor(phq29.interestOnDoingThings) : '#717171',
      });

      valFeelingDown.push({
        'value': (phq29?.id) ? phq29.feelingDown : undefined,
        'date': `${month}/${day}/${year}`,
        'color': (phq29?.id) ? getLevelColor(phq29.feelingDown) : '#717171',
      });
      valSleepingToMuch.push({
        'value': (phq29?.id) ? phq29.sleepingToMuch : undefined,
        'date': `${month}/${day}/${year}`,
        'color': (phq29?.id) ? getLevelColor(phq29.sleepingToMuch) : '#717171',
      });
      valFeelingTired.push({
        'value': (phq29?.id) ? phq29.feelingTired : undefined,
        'date': `${month}/${day}/${year}`,
        'color': (phq29?.id) ? getLevelColor(phq29.feelingTired) : '#717171',
      });
      valAppetiteDisturbance.push({
        'value': (phq29?.id) ? phq29.appetiteDisturbance : undefined,
        'date': `${month}/${day}/${year}`,
        'color': (phq29?.id) ? getLevelColor(phq29.appetiteDisturbance) : '#717171',
      });
      valFeelingBad.push({
        'value': (phq29?.id) ? phq29.feelingBad : undefined,
        'date': `${month}/${day}/${year}`,
        'color': (phq29?.id) ? getLevelColor(phq29.feelingBad) : '#717171',
      });
      valConcentrationTrouble.push({
        'value': (phq29?.id) ? phq29.concentrationTrouble : undefined,
        'date': `${month}/${day}/${year}`,
        'color': (phq29?.id) ? getLevelColor(phq29.concentrationTrouble) : '#717171',
      });
      valMovingSlow.push({
        'value': (phq29?.id) ? phq29.movingSlow : undefined,
        'date': `${month}/${day}/${year}`,
        'color': (phq29?.id) ? getLevelColor(phq29.movingSlow) : '#717171',
      });
      valBetterDead.push({
        'value': (phq29?.id) ? phq29.betterDead : undefined,
        'date': `${month}/${day}/${year}`,
        'color': (phq29?.id) ? getLevelColor(phq29.betterDead) : '#717171',
      });

      let valueSum = 0;
      valueSum += (phq29?.id) ? phq29.interestOnDoingThings : 0;
      valueSum += (phq29?.id) ? phq29.feelingDown : 0;
      valueSum += (phq29?.id) ? phq29.sleepingToMuch : 0;
      valueSum += (phq29?.id) ? phq29.feelingTired : 0;
      valueSum += (phq29?.id) ? phq29.appetiteDisturbance : 0;
      valueSum += (phq29?.id) ? phq29.feelingBad : 0;
      valueSum += (phq29?.id) ? phq29.concentrationTrouble : 0;
      valueSum += (phq29?.id) ? phq29.movingSlow : 0;
      valueSum += (phq29?.id) ? phq29.betterDead : 0;

      if (!phq29?.id) {
        valueSum = undefined;
      }

      valSum.push({
        'value': valueSum,
        'date': `${month}/${day}/${year}`,
      });
    });


    setDateLabel(valDateLabel);

    // setSumSleepingToMuch(sumValues(valSleepingToMuch));
    setSleepingToMuch(valSleepingToMuch);

    // setSumMovingSlow(sumValues(valMovingSlow));
    setMovingSlow(valMovingSlow);

    // setSumInterestOnDoingThings(sumValues(valInterestOnDoingThings));
    setInterestOnDoingThings(valInterestOnDoingThings);

    // setSumFeelingDown(sumValues(valFeelingDown));
    setFeelingDown(valFeelingDown);

    // setSumFeelingTired(sumValues(valFeelingTired));
    setFeelingTired(valFeelingTired);

    // setSumAppetiteDisturbance(sumValues(valAppetiteDisturbance));
    setAppetiteDisturbance(valAppetiteDisturbance);

    // setSumFeelingBad(sumValues(valFeelingBad));
    setFeelingBad(valFeelingBad);

    // setSumConcentrationTrouble(sumValues(valConcentrationTrouble));
    setConcentrationTrouble(valConcentrationTrouble);

    // setSumBetterDead(sumValues(valBetterDead));
    setBetterDead(valBetterDead);

    setSum(valSum);

  }, [phq29s]);


  return (
    <Box className='marks'>
      <Box className={classes.containerBox}>
        <Box className={classes.boxLabelAlert}>
          <table>
            <tbody>
            <tr>
              <th className={classes.labelAlert}>Sleeping Disturbance</th>
            </tr>
            <tr>
              <th className={classes.labelAlert}>Moving/ Speaking Trouble</th>
            </tr>
            <tr>
              <th className={classes.labelAlert}>Interest on Doing Things</th>

            </tr>
            <tr>
              <th className={classes.labelAlert}> Hurting yourself</th>

            </tr>
            <tr>
              <th className={classes.labelAlert}>Feeling Tired</th>

            </tr>
            <tr>
              <th className={classes.labelAlert}>Feeling Down</th>

            </tr>
            <tr>
              <th className={classes.labelAlert}>Feeling Bad</th>

            </tr>
            <tr>
              <th className={classes.labelAlert}>Concentration Trouble</th>

            </tr>
            <tr>
              <th className={classes.labelAlert}>Appetite Disturbance</th>
            </tr>
            </tbody>
          </table>
        </Box>
        <Box className={classes.boxContentAlerts}>
          <table className={classes.tableContentAlerts}>
            <tbody>
            <tr>
              {sleepingToMuch.map((data, index) => (<td key={index} style={{ backgroundColor: data.color, height: 60, border: 'none', margin: 0, padding: 0 }}
              title={data.value}>
                &nbsp;
              </td>))}
            </tr>
            <tr>
                {movingSlow.map((data, index) => (<td key={index} style={{ backgroundColor: data.color, height: 60, border: 'none', margin: 0, padding: 0 }} title={`${data.date} - ${data.value || 'No data'}`}>
                &nbsp;
              </td>))}
            </tr>
            <tr>

                {interestOnDoingThings.map((data, index) => (<td key={index} style={{ backgroundColor: data.color, height: 60, border: 'none', margin: 0, padding: 0 }} title={`${data.date} - ${data.value || 'No data'}`}>
                &nbsp;
              </td>))}
            </tr>
            <tr>

                {betterDead.map((data, index) => (<td key={index} style={{ backgroundColor: data.color, height: 60, border: 'none', margin: 0, padding: 0 }} title={`${data.date} - ${data.value || 'No data'}`}>
                &nbsp;
              </td>))}
            </tr>
            <tr>

                {feelingTired.map((data, index) => (<td key={index} style={{ backgroundColor: data.color, height: 60, border: 'none', margin: 0, padding: 0 }} title={data.value}>
                &nbsp;
              </td>))}
            </tr>
            <tr>

                {feelingDown.map((data, index) => (<td key={index} style={{ backgroundColor: data.color, height: 60, border: 'none', margin: 0, padding: 0 }} title={data.value}>
                &nbsp;
              </td>))}
            </tr>
            <tr>

                {feelingBad.map((data, index) => (<td key={index} style={{ backgroundColor: data.color, height: 60, border: 'none', margin: 0, padding: 0 }} title={data.value}>
                &nbsp;
              </td>))}
            </tr>
            <tr>

                {concentrationTrouble.map((data, index) => (<td key={index} style={{ backgroundColor: data.color, height: 60, border: 'none', margin: 0, padding: 0 }} title={data.value}>
                &nbsp;
              </td>))}
            </tr>
            <tr>

                {appetiteDisturbance.map((data, index) => (<td key={index} style={{ backgroundColor: data.color, height: 60, border: 'none', margin: 0, padding: 0 }} title={data.value}>
                &nbsp;
              </td>))}
            </tr>
            <tr>
              {valSum.map((data, index) => {
                return (
                  <th className={classes.SumAlert}> {data.value !== undefined ? data.value : '-'}</th>
                );
              })}
            </tr>
            <tr>

              {dateLabel.map((data, index) => (<td key={index} style={{ textAlign: data.textAlign, fontSize: 10, backgroundColor: 'none', height: 40, border: 'none', margin: 0, padding: 0 }}
                colSpan={data.colspan}>
                {data.value}
              </td>))}
            </tr>
            </tbody>
          </table>
        </Box>
        {/* <Box>
          <table>
            <tbody>
            <tr>
              <th className={classes.SumAlert}> {sumSleepingToMuch || '-'}</th>
            </tr>
            <tr>
              <th className={classes.SumAlert}>{sumMovingSlow || '-'}</th>


            </tr>
            <tr>
              <th className={classes.SumAlert}>{sumInterestOnDoingThings || '-'}</th>

            </tr>
            <tr>
              <th className={classes.SumAlert}>{sumBetterDead || '-'}</th>

            </tr>
            <tr>
              <th className={classes.SumAlert}>{sumFeelingTired || '-'}</th>

            </tr>
            <tr>
              <th className={classes.SumAlert}>{sumFeelingDown || '-'}</th>

            </tr>
            <tr>
              <th className={classes.SumAlert}>{sumFeelingBad || '-'}</th>

            </tr>
            <tr>
              <th className={classes.SumAlert}>{sumConcentrationTrouble || '-'}</th>

            </tr>
            <tr>
              <th className={classes.SumAlert}>{sumAppetiteDisturbance || '-'}</th>
            </tr>
            </tbody>
          </table>
        </Box> */}

        <Box className={classes.labelColumn}>
          <Box className={classes.boxLabel} style={{paddingLeft: 5}}>
            <span className={classes.noDataLabel}></span> {"No data "}
          </Box>
          <Box className={classes.boxLabel}>
            <span className={classes.levelZeroLabel}></span>{"Level 0 "}
          </Box>
          <Box className={classes.boxLabel}>
            <span className={classes.levelOneLabel}></span>{"Level 1 "}
          </Box>
          <Box className={classes.boxLabel}>
            <span className={classes.levelTwoLabel}></span>{"Level 2 "}
          </Box>
          <Box className={classes.boxLabel}>
            <span className={classes.levelThreeLabel}></span>{"Level 3 "}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}