import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  CssBaseline,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  ButtonGroup,
  Paper,
  CircularProgress,
  MenuItem,
  Fab,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import AppBar from '../../../Components/AppBar';
import LocalDrawer from '../../../Components/QActualAdminLocalDrawer';
import { Formik } from "formik"
import { logout } from '../../../services/auth';
import { createNewOrganization } from './../../../services/organization';
import * as yup from 'yup';
import swal from 'sweetalert';
import SaveIcon from '@material-ui/icons/Save';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import API from '../../../services/api';

interface Props extends RouteComponentProps {}

interface State {
  isLoading: boolean;
  toolbarOpen: boolean;
  validationError: string;
  name: string;
  shortName: string;
  distributionCategory: string;
  appVersion: string;
  fileKey: string;
  theme: string;
}

class AddOrganization extends React.Component<Props, State> {
  private fileSelector: HTMLInputElement;

  constructor(props) {
    super(props);

    this.state = {
      toolbarOpen: localStorage.getItem('toolbarOpen') === 'true' ? true : false,
      validationError: '',
      isLoading: false,
      name: '',
      shortName: '',
      distributionCategory: '',
      appVersion: '',
      fileKey: '',
      theme: '',
    }
  }

  componentDidMount () {
    this.setState({ isLoading: false });
    this.fileSelector = this.buildFileSelector();
  }

  handleDrawerOpen = () => {
    localStorage.setItem('toolbarOpen', 'true');
    this.setState({ toolbarOpen: true });
  };

  handleDrawerClose = () => {
    localStorage.setItem('toolbarOpen', 'false');
    this.setState({ toolbarOpen: false });
  };

  handleSubmit = async (params: any) => {
    try {
      this.setState({ isLoading: true });
      const response = await createNewOrganization(params);
      if ( response.data.status === 'success' ) {
        this.setState({ isLoading: false });
        swal('Success', 'Record saved', 'success');
        this.props.history.push('/qactual/admin/organizations');
      }
    } catch (error) {
      this.setState({ isLoading: false });
      swal('Error', 'Something went wrong', 'error');
    }
  }

  private handleBackClick = () => {
    this.props.history.push('/qactual/admin/organizations/');
  }

  private buildFileSelector = () => {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('id', 'schoolPhotoSelector');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('multiple', 'multiple');

    fileSelector.onchange = async (e) => {
      let formData = new FormData();
      formData.append('file', fileSelector.files[0]);
      
      this.setState({ isLoading: true });

      const res: {
        data: {
          status: string,
          fileKey: string,
        },
      } = await API.post('/organizations/storeLogo', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log(`fileKey: ${res.data.fileKey}`);

      this.setState({
        isLoading: false,
        fileKey: res.data.fileKey,
      });
    }

    return fileSelector;
  }

  private handleFileSelect = (e) => {
    e.preventDefault();
    this.fileSelector.click();
  }

  render () {
    const {
      toolbarOpen,
      fileKey,
    } = this.state;

    return (
      <div className='root'>
        <CssBaseline />
        <AppBar
          toolbarOpen={toolbarOpen}
          handleDrawerOpen={this.handleDrawerOpen}
          breadcrumbs={[]}
        />
        <LocalDrawer
          toolbarOpen={toolbarOpen}
          handleDrawerClose={this.handleDrawerClose}
          logout={logout}
        />
        <main className='content'>
          <div className='toolbarIcon' />
          <Container maxWidth="lg" className='container'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div style={{ float: 'left' }}>
                  <Typography variant="h4" display="block" gutterBottom>
                    Add Organization
                  </Typography>
                </div>
                <ButtonGroup
                  color="primary"
                  aria-label='outlined primary button group'
                  style={{float: 'right'}} >
                  <Button
                    onClick={() => this.handleBackClick()}>
                    <ArrowBack fontSize="small" />
                    &nbsp;Back
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
            <Paper className="paper" style={{ paddingTop: 40 }}>
              <Formik
                initialValues={{
                  name: '',
                  shortName: '',
                  distributionCategory: '',
                  appVersion: '',
                  theme: '',
                  domainFilter: '',
                }}
                validationSchema={yup.object().shape({
                  name: yup.string().required("You must enter a name for the organization"),
                  shortName: yup.string().required("You must enter a short name for the organization"),
                  distributionCategory: yup.string().required("You must select a Distribution Category for the organization"),
                  appVersion: yup.string().required("You must select an App Version for the organization"),
                  domainFilter: yup.string(),
                })}
                onSubmit={(values, {setSubmitting, resetForm}) => {
                  this.handleSubmit({
                    name: values.name,
                    shortName: values.shortName.trim().toLowerCase(),
                    category: values.distributionCategory,
                    appVersion: values.appVersion,
                    theme: values.theme,
                    logo: fileKey.replace('.png', ''),
                    domainFilter: values.domainFilter,
                  });
                }}
              >
              {({values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit}) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          id="name"
                          label="Name"
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          fullWidth
                          error={errors.name && touched.name}
                          helperText={touched.name && errors.name}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          id="shortName"
                          label="Short Name (used to build DDL links)"
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.shortName}
                          fullWidth
                          error={errors.shortName && touched.shortName}
                          helperText={touched.shortName && errors.shortName}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          id="distributionCategory"
                          label="Distribution Category"
                          variant="outlined"
                          onChange={handleChange('distributionCategory')}
                          onBlur={handleBlur}
                          value={values.distributionCategory}
                          select
                          fullWidth
                          error={errors.distributionCategory && touched.distributionCategory}
                          helperText={touched.distributionCategory && errors.distributionCategory}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={'closed'}>
                            {'Closed to Email Domain (i.e. weCare on Cone Health)'}
                          </MenuItem>
                          <MenuItem value={'open'}>
                            {'Open to everyone that has access to a Deferred Deep Link (i.e. Seattle Seahawks)'}
                          </MenuItem>
                          <MenuItem value={'whitelist'}>
                            {'White listed by email (i.e. Civilian App on Cone Health)'}
                          </MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>
                    {values.distributionCategory === 'closed' && <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          id="domainFilter"
                          label="Domain Filter (the emails with this domain will be accepted)"
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.domainFilter}
                          fullWidth
                          error={errors.domainFilter && touched.domainFilter}
                          helperText={touched.domainFilter && errors.domainFilter}
                        />
                      </Grid>
                    </Grid>}
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          id="appVersion"
                          label="App Version"
                          variant="outlined"
                          onChange={handleChange('appVersion')}
                          onBlur={handleBlur}
                          value={values.appVersion}
                          fullWidth
                          select
                          error={errors.appVersion && touched.appVersion}
                          helperText={touched.appVersion && errors.appVersion}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={'global'}>
                            {'New multi theme app (Global App)'}
                          </MenuItem>
                          <MenuItem value={'civilian'}>
                            {'Civilian App (.NET)'}
                          </MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          id="theme"
                          label="Default Theme"
                          variant="outlined"
                          onChange={handleChange('theme')}
                          onBlur={handleBlur}
                          value={values.theme}
                          fullWidth
                          select
                          error={errors.theme && touched.theme}
                          helperText={touched.theme && errors.theme}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={'popsmoke'}>
                            {'PopSmoke (Military)'}
                          </MenuItem>
                          <MenuItem value={'civilian'}>
                            {'Civilian'}
                          </MenuItem>
                          <MenuItem value={'wecare'}>
                            {'weCare (Care Providers)'}
                          </MenuItem>
                          <MenuItem value={'coderesponse'}>
                            {'Code Response (Police Department)'}
                          </MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={3}
                      style={{
                        paddingTop: 20,
                        paddingBottom: 20,
                      }}>
                      <div
                        style={{
                          width: 600,
                          height: 450,
                          border: 'solid',
                          borderRadius: 6,
                          margin: 'auto',
                          borderColor: '#c4c4c4',
                          position: 'relative',
                        }}
                      >
                        {(fileKey ? (
                          <img
                            src={`https://qactual-global-organizations-logos.s3.amazonaws.com/${fileKey}`}
                            width="100%"
                            style={{
                              margin: 0,
                              position: 'absolute',
                              top: '50%',
                              transform: 'translateY(-50%)',
                            }}
                          />
                        ): null)}
                        <Fab
                          color='secondary'
                          aria-label='edit'
                          style={{
                            right: '10px',
                            bottom: '10px',
                            position: 'absolute'
                          }}
                          onClick={this.handleFileSelect}
                        >
                          <PhotoCameraIcon />
                        </Fab>
                      </div>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          style={{ float: 'right' }}
                          size="large"
                          disabled={isSubmitting}
                          startIcon={this.state.isLoading ? <CircularProgress /> : <SaveIcon /> }
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
              {this.state.validationError}
            </Paper>
          </Container>
        </main>
      </div>
    );
  }
}

export default AddOrganization;
