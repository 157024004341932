import React, { FC, useEffect } from "react";
import "./App.scss";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./Theme";

// Util
import {
  isAuthenticatedAsGeneralAdmin,
  refreshTokenUser,
} from "./services/auth";

// Pages public
import Login from "./Pages/Login";
import ForgotPassword from "./Pages/ForgotPassword";
import ConfirmForgotPassword from "./Pages/ConfirmForgotPassword";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import AppPrivacyPolicy from "./Pages/AppPrivacyPolicy";
import InstallInstructions from "./Pages/InstallInstructions";
import CareProvider from "./Pages/CareProvider";
import Patient from "./Pages/Patient";
import AddPHQ29 from "./Pages/Patient/AddPHQ29";

// Routes Private
import ChangePassword from "./Pages/ChangePassword";
import ListAllOrganizations from "./Pages/QActualAdmin/ListAllOrganizations";
import AddOrganization from "./Pages/QActualAdmin/AddOrganization";
import EditOrganization from "./Pages/QActualAdmin/EditOrganization";
import AddUserOrganization from "./Pages/QActualAdmin/AddUserOrganization";
import ListAllUsersOrganization from "./Pages/QActualAdmin/ListAllUsersOrganization";
import ShowUserOrganization from "./Pages/QActualAdmin/ShowUserOrganization";
import EditUserOrganization from "./Pages/QActualAdmin/EditUserOrganization";

import ListAllCareProvider from "./Pages/OrganizationManagement/ListAllCareProvider";
import AddCareProvider from "./Pages/OrganizationManagement/AddCareProvider";
import ShowCareProvider from "./Pages/OrganizationManagement/ShowCareProvider";
import EditCareProvider from "./Pages/OrganizationManagement/EditCareProvider";

import ComplaintEmail from "./Pages/ComplaintEmail";

import {
  getToken,
  getEmailSession,
  getRefreshToken,
  setToken,
  setRefreshToken,
} from "./services/auth";
import { wsClient } from "./helpers/websockets";
import ListAllClinician from "./Pages/QActualAdmin/ListAllClinician";
// import ListAllPatients from "./Pages/QActualAdmin/ListAllPatients";
import Clinician from "./Pages/QActualAdmin/Clinician";
import ReportPatientPrint from "./Pages/ReportPatientPrint";
import ReportCareProviderPrint from "./Pages/ReportCareProviderPrint";
import OrganizationTokensBatches from "./Pages/QActualAdmin/OrganizationTokensBatches";
import AddOrganizationSeatsBatch from "./Pages/QActualAdmin/AddOrganizationSeatsBatches";
import ListResourcesOrganization from "./Pages/QActualAdmin/ListResourcesOrganization";
import AddResourceOrganization from "./Pages/QActualAdmin/AddResourceOrganization";
import Resource from "./Pages/QActualAdmin/Resource";
import EditResource from "./Pages/QActualAdmin/EditResource";
import DDLRedirect from "./Pages/DDLRedirect";
import AdminData from "./Pages/QActualAdmin/AdminData";
import ListOrganizationWellnessForm from "./Pages/QActualAdmin/ListOrganizationWellnessForm";
import AddFormOrganization from "./Pages/QActualAdmin/AddFormOrganization";
import Form from "./Pages/QActualAdmin/Form";
import FormQuestion from "./Pages/QActualAdmin/FormQuestion";
import EditFormQuestion from "./Pages/QActualAdmin/EditFormQuestion";
import AddFormQuestion from "./Pages/QActualAdmin/AddFormQuestion";
import EditForm from "./Pages/QActualAdmin/EditForm";

const QActualAdminGeneralPrivateRoute = ({ component, ...rest }: any) => {
  let pathLogin = "/login";

  switch (rest.groupUser) {
    case "qactual_admin":
      pathLogin = "/qactual/admin/login";
      break;
    case "org_manager":
      pathLogin = "/organizationManager/login";
      break;
    case "care_provider":
      pathLogin = "/cliniciandashboard/login";
      break;
    default:
      pathLogin = "/cliniciandashboard/login";
      break;
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticatedAsGeneralAdmin(rest.groupUser) ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{ pathname: pathLogin, state: { from: props.location } }}
          />
        )
      }
    />
  );
};

function refreshToken() {
  if (getToken() && getEmailSession() && getRefreshToken()) {
    refreshTokenUser().then((resp) => {
      if (resp?.status === 200) {
        if (resp?.data?.status === "success") {
          setToken(resp.data.token);
          setRefreshToken(resp.data.refreshToken);
        }
      }
    });
  }
}

const App: FC = () => {
  useEffect(() => {
    refreshToken();
    wsClient.onopen = () => {
      console.log("WebSocket wsClient Connected");

      let token = getToken();

      if (token) {
        wsClient.send(
          JSON.stringify({
            op: "register",
            token,
          })
        );
      } else {
        const interval = setInterval(() => {
          token = getToken();
          if (token) {
            clearInterval(interval);

            wsClient.send(
              JSON.stringify({
                op: "register",
                token,
              })
            );
          }
        }, 1000);
      }
    };

    setInterval(refreshToken, 10 * 60 * 1000); // Updates token every 10 minutes

    wsClient.onclose = () => {
      console.log("WebSocket wsClient Disconnected");
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          {/* Puplic general routes */}
          <Route path="/complaint/:email" exact component={ComplaintEmail} />

          {/* QActual Admin routes */}
          <Route path="/qactual/admin/login" exact component={Login} />
          <Route
            path="/qactual/admin/forgotPassword"
            exact
            component={ForgotPassword}
          />
          <Route
            path="/qactual/admin/confirmForgotPassword"
            exact
            component={ConfirmForgotPassword}
          />
          <QActualAdminGeneralPrivateRoute
            path="/qactual/admin/changePassword"
            exact
            component={ChangePassword}
            groupUser="qactual_admin"
          />

          <QActualAdminGeneralPrivateRoute
            path="/qactual/admin/organizations/add"
            exact
            component={AddOrganization}
            groupUser="qactual_admin"
          />
          <QActualAdminGeneralPrivateRoute
            path="/qactual/admin/organizations/:organizationId"
            exact
            component={ListAllUsersOrganization}
            groupUser="qactual_admin"
          />
          <QActualAdminGeneralPrivateRoute
            path="/qactual/admin/organizations/:organizationId/resources"
            exact
            component={ListResourcesOrganization}
            groupUser="qactual_admin"
          />
          <QActualAdminGeneralPrivateRoute
            path="/qactual/admin/organizations/:organizationId/resources/add"
            exact
            component={AddResourceOrganization}
            groupUser="qactual_admin"
          />
          <QActualAdminGeneralPrivateRoute
            path="/qactual/admin/organizations/:organizationId/resources/:resourceId"
            exact
            component={Resource}
            groupUser="qactual_admin"
          />
          <QActualAdminGeneralPrivateRoute
            path="/qactual/admin/organizations/:organizationId/resources/:resourceId/edit"
            exact
            component={EditResource}
            groupUser="qactual_admin"
          />
          <QActualAdminGeneralPrivateRoute
            path="/qactual/admin/organizations/:organizationId/edit"
            component={EditOrganization}
            groupUser="qactual_admin"
          />
          <QActualAdminGeneralPrivateRoute
            path="/qactual/admin/organizations/:organizationId/addSeatsBatch"
            component={AddOrganizationSeatsBatch}
            groupUser="qactual_admin"
          />
          <QActualAdminGeneralPrivateRoute
            path="/qactual/admin/organizations/:organizationId/form/add"
            component={AddFormOrganization}
            groupUser="qactual_admin"
          />  
          <QActualAdminGeneralPrivateRoute
            path="/qactual/admin/organizations/:organizationId/form/:formId/edit"
            component={EditForm}
            groupUser="qactual_admin"
          />         
          <QActualAdminGeneralPrivateRoute
            path="/qactual/admin/organizations/:organizationId/form/:formId/question/:questionId/edit"
            component={EditFormQuestion}
            groupUser="qactual_admin"
          />
          <QActualAdminGeneralPrivateRoute
            path="/qactual/admin/organizations/:organizationId/form/:formId/question/add"
            component={AddFormQuestion}
            groupUser="qactual_admin"
          />
          <QActualAdminGeneralPrivateRoute
            path="/qactual/admin/organizations/:organizationId/form/:formId/question/:questionId"
            component={FormQuestion}
            groupUser="qactual_admin"
          />
      
          <QActualAdminGeneralPrivateRoute
            path="/qactual/admin/organizations/:organizationId/form/:formId"
            component={Form}
            groupUser="qactual_admin"
          />
          <QActualAdminGeneralPrivateRoute
            path="/qactual/admin/organizations/:organizationId/form"
            component={ListOrganizationWellnessForm}
            groupUser="qactual_admin"
          />
         
          <QActualAdminGeneralPrivateRoute
            path="/qactual/admin/organizations/:organizationId/add"
            exact
            component={AddUserOrganization}
            groupUser="qactual_admin"
          />
          <QActualAdminGeneralPrivateRoute
            path="/qactual/admin/organizations/:organizationId/batches"
            exact
            component={OrganizationTokensBatches}
            groupUser="qactual_admin"
          />
          <QActualAdminGeneralPrivateRoute
            path="/qactual/admin/organizations/:organizationId/manager/:userId"
            component={ShowUserOrganization}
            groupUser="qactual_admin"
          />
          <QActualAdminGeneralPrivateRoute
            path="/qactual/admin/organizations/:organizationId/editManager/:userId"
            component={EditUserOrganization}
            groupUser="qactual_admin"
          />
          <QActualAdminGeneralPrivateRoute
            path="/qactual/admin/organizations"
            exact
            component={ListAllOrganizations}
            groupUser="qactual_admin"
          />
          <QActualAdminGeneralPrivateRoute
            path="/qactual/admin"
            exact
            component={ListAllOrganizations}
            groupUser="qactual_admin"
          />
          <QActualAdminGeneralPrivateRoute
            path="/qactual/admin/clinician"
            exact
            component={ListAllClinician}
            groupUser="qactual_admin"
          />
          <QActualAdminGeneralPrivateRoute
            path="/qactual/admin/clinician/:userId"
            exact
            component={Clinician}
            groupUser="qactual_admin"
          />
          {/* <QActualAdminGeneralPrivateRoute path="/qactual/admin/patients" exact component={ListAllPatients} groupUser="qactual_admin" /> */}
          <QActualAdminGeneralPrivateRoute
            path="/qactual/admin/clinician/:careProviderId/report/days"
            exact
            component={ReportCareProviderPrint}
            groupUser="qactual_admin"
          />
          <QActualAdminGeneralPrivateRoute
            path="/qactual/admin/data"
            exact
            component={AdminData}
            groupUser="qactual_admin"
          />

          {/* Organization Manager routes */}
          <Route
            path="/organizationManager/login"
            exact
            component={Login}
            groupUser="org_manager"
          />
          <Route
            path="/organizationManager/forgotPassword"
            exact
            component={ForgotPassword}
          />
          <Route
            path="/organizationManager/confirmForgotPassword"
            exact
            component={ConfirmForgotPassword}
          />
          <QActualAdminGeneralPrivateRoute
            path="/organizationManager/changePassword"
            exact
            component={ChangePassword}
            groupUser="org_manager"
          />

          <QActualAdminGeneralPrivateRoute
            path="/organizationManager/editOrganizationManager/:organizationManagerId"
            exact
            component={EditCareProvider}
            groupUser="org_manager"
          />
          <QActualAdminGeneralPrivateRoute
            path="/organizationManager/add"
            exact
            component={AddCareProvider}
            groupUser="org_manager"
          />
          <QActualAdminGeneralPrivateRoute
            path="/organizationManager"
            exact
            component={ListAllCareProvider}
            groupUser="org_manager"
          />
          <QActualAdminGeneralPrivateRoute
            path="/organizationManager/:userId"
            exact
            component={ShowCareProvider}
            groupUser="org_manager"
          />

          {/* Care Provider routes */}
          <Redirect path="/" to={"/cliniciandashboard/login"} exact />
          <Route
            path="/cliniciandashboard/login"
            to={"/cliniciandashboard/login"}
            exact
            component={Login}
          />
          <Route
            path="/cliniciandashboard/forgotPassword"
            exact
            component={ForgotPassword}
          />
          <Route
            path="/cliniciandashboard/confirmForgotPassword"
            exact
            component={ConfirmForgotPassword}
          />
          <QActualAdminGeneralPrivateRoute
            path="/cliniciandashboard/changePassword"
            exact
            component={ChangePassword}
            groupUser="care_provider"
          />

          <QActualAdminGeneralPrivateRoute
            path="/cliniciandashboard/patient/:patientId"
            exact
            component={Patient}
            groupUser="care_provider"
          />

          <QActualAdminGeneralPrivateRoute
            path="/cliniciandashboard/patient/:patientId/addPHQ29"
            exact
            component={AddPHQ29}
            groupUser="care_provider"
          />
          <QActualAdminGeneralPrivateRoute
            path="/cliniciandashboard"
            exact
            component={CareProvider}
            groupUser="care_provider"
          />

          <QActualAdminGeneralPrivateRoute
            path="/cliniciandashboard/patient/:patientId/report/:days"
            exact
            component={ReportPatientPrint}
            groupUser="care_provider"
          />
          <QActualAdminGeneralPrivateRoute
            path="/cliniciandashboard/report/:days"
            exact
            component={ReportCareProviderPrint}
            groupUser="care_provider"
          />

          <Route path="/forgotPassword" exact component={ForgotPassword} />
          <Route
            path="/confirmForgotPassword"
            exact
            component={ConfirmForgotPassword}
          />
          <Route path="/privacy-policy" exact component={PrivacyPolicy} />
          <Route
            path="/app-privacy-policy"
            exact
            component={AppPrivacyPolicy}
          />
          <Route path="/install" exact component={InstallInstructions} />
          <Route path="/:organizationShortName" exact component={DDLRedirect} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
