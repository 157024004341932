import API from "./api";

interface CreateFormParams {
  formName: string;
  active: boolean;
  orgId: string;
  orgName: string;
}

// CRUD ORGANIZATION
export const getFormsByOrganizationName = async (orgShortName: string) => {
  try {
    return await API.get(
      `/wellnessCheckForm/organizations/name/${orgShortName}/forms/questions`
    );
  } catch (error) {
    console.log("error", error);
    return error;
  }
};

export const getFormsByOrganizationId = async (organizationId: string) => {
  try {
    return await API.get(
      `/wellnessCheckForm/organizations/id/${organizationId}/forms/questions`
    );
  } catch (error) {
    console.log("error", error);
    return error;
  }
};

export const getFormById = async (formId: string) => {
  try {
    return await API.get(`/wellnessCheckForm/${formId}`);
  } catch (error) {
    console.log("error", error);
    return error;
  }
};

export const getFormQuestions = async (formId: string) => {
  try {
    return await API.get(`/wellnessCheckForm/${formId}`);
  } catch (error) {
    console.log("error", error);
    return error;
  }
};

export const createForm = async (params: CreateFormParams) => {
  try {
    return await API.post("/wellnessCheckForm/create", params);
  } catch (error) {
    console.log("error", error);
    return error;
  }
};

export const deleteForm = async (formId: string) => {
  try {
    return await API.delete(`/wellnessCheckForm/${formId}`);
  } catch (error) {
    console.log("error", error);
    return error;
  }
};

export const updateForm = async (formId: string, params: any) => {
  try {
    return await API.put(`/wellnessCheckForm/${formId}`, params);
  } catch (error) {
    console.log("error", error);
    return error;
  }
};
