import React from 'react';
import { Modal, Button } from '@material-ui/core';

interface Props {
  modalDeactivateIsOpen: boolean;
  handleModalDeactivateClose: Function;
  dismissDeactivate: Function;
  confirmDeactivate: Function;
}

class DeactivateModal extends React.Component<Props> {
  render () {
    const {
      modalDeactivateIsOpen,
      handleModalDeactivateClose,
      dismissDeactivate,
      confirmDeactivate,
    } = this.props;

    let modalLeft = `${(window.innerWidth/2)-200}px`;
    let modalTop = `${(window.innerHeight/2)}px`;

    return (
      <Modal
        aria-labelledby='modal-deactivate-title'
        aria-describedby='modal-deactivate-description'
        open={modalDeactivateIsOpen}
        onClose={() => handleModalDeactivateClose()}
        style={{
          position: 'absolute',
        }}
      >
        <div
          style={{
            width: '400px',
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px',
            position: 'fixed',
            left: modalLeft,
            top: modalTop,
          }}>
          <h2 id='modal-deactivate-title'>Confirm Deactivate User</h2>
          <p id='modal-deactivate-description'>
            Please, confirm that you want to deactivate this user.
          </p>
          <Button
            style={{ float: 'right' }}
            color='primary'
            onClick={() => dismissDeactivate()}
          >
            Cancel
          </Button>
          <Button
            style={{ float: 'right' }}
            color='secondary'
            onClick={() => confirmDeactivate()}
          >
            Deactivate
          </Button>
        </div>
      </Modal>
    );
  }
}

export default DeactivateModal;
