import { w3cwebsocket as W3CWebSocket } from 'websocket';

let websocketsBaseURL = '';
const currentURL = window.location.href;
if (currentURL.includes('staging--qactual-clinician-dashboard.netlify.app') || currentURL.includes('localhost')) {
  websocketsBaseURL = 'wss://staging-clinician-dashboard-websocket.qactualservice.com';
} else {
  websocketsBaseURL = 'wss://clinician-dashboard-websocket.qactualservice.com';
}

export const wsClient = new W3CWebSocket(websocketsBaseURL);

export const clientOnClose = () => wsClient.onclose = () => {
  console.log('WebSocket Client Disconnected');
}

export const clientOnMessage = () => wsClient.onmessage = (data: any) => {
  data = JSON.parse(data.data)
  console.log(data);
  switch (data.op) {
    case 'registerIsOk':
      console.log(data);
      break;

    default:
      break
  }
};

export const onError = () =>
  wsClient.onerror = err => {
  console.error(
    "Socket encountered error: ",
    err.message,
    "Closing socket"
  );
  wsClient.close();
}
