import React from 'react';
import {
  CssBaseline,
  Container,
  Grid,
  Paper,
  CircularProgress,
} from '@material-ui/core';
import AppBar from '../../../Components/AppBar';
import LocalDrawer from '../../../Components/QActualAdminLocalDrawer';
import Table from '../../../Components/Table';
import swal from 'sweetalert';

// CALL TO API
import { logout } from '../../../services/auth';
import { getAllOrganizations } from './../../../services/organization';

interface OrganizationData {
  [key: string]: string;
}

interface Props {}

interface State {
  toolbarOpen: boolean;
  organizationsData: OrganizationData[];
  loading: boolean;
};

class ListAllOrganizations extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      toolbarOpen: localStorage.getItem('toolbarOpen') === 'true' ? true : false,
      organizationsData: [],
      loading: false,
    }
  }

  componentDidMount() {
    this.getOrganizations();
  }

  private getOrganizations = async () => {
    try {
      this.setState({ loading: true });
      const response = await getAllOrganizations();
      const organizationsData = response?.data?.organizations.map((org) => {
        return { ...org, href: `/qactual/admin/organizations/${org.id}` }
      });
      if (response?.data?.organizations) this.setState({ loading: false });
      this.setState({ organizationsData });
    } catch (error) {
      this.setState({ loading: false });
      swal('Error', 'Error loading organizations', 'error');
    }
  };

  private handleDrawerOpen = () => {
    console.log('Opening toolbar');
    localStorage.setItem('toolbarOpen', 'true');
    this.setState({ toolbarOpen: true });
  };

  private handleDrawerClose = () => {
    console.log('Closing toolbar');
    localStorage.setItem('toolbarOpen', 'false');
    this.setState({ toolbarOpen: false });
  };

  private columns = [{
    name: 'Name',
    key: 'name',
    type: 'link'
  }];

  render() {
    const { toolbarOpen, organizationsData } = this.state;

    return (
      <div className='root'>
        <CssBaseline />
        <AppBar
          toolbarOpen={toolbarOpen}
          handleDrawerOpen={this.handleDrawerOpen}
          breadcrumbs={[]}
        />
        <LocalDrawer
          toolbarOpen={toolbarOpen}
          handleDrawerClose={this.handleDrawerClose}
          logout={logout}
        />
        <main className='content'>
          <div className='toolbarIcon' />
          <Container maxWidth="lg" className='container'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className='paper'>
                  {this.state.loading
                    ? <CircularProgress />
                    :
                      <Table
                        title='Organizations'
                        columns={this.columns}
                        data={organizationsData}
                        addHref='/qactual/admin/organizations/add'
                      />
                  }
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </main>
      </div>
    );
  };
};

export default ListAllOrganizations;
