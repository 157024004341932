import React, { FC, useEffect, useState } from 'react';
import {
    MenuItem,
    Grid,
    makeStyles,
    Theme,
    Typography,
    Select,
    IconButton,
} from '@material-ui/core';

import { BiArrowToBottom } from 'react-icons/bi';


import {Link } from 'react-router-dom';



const useStyles = makeStyles((theme: Theme) => ({
    lastWeek: {
        width: '100%',
        border: '2px solid #000000',
        justifyContent: 'flex-end',
        textAlign: 'right',
        padding: '5px'
    },
}));

interface Props {
    handleChangeWeek: Function;
    dataPatient: {
        id: string,
        name: string,
        email: string,
        phoneNumber: string,
    };
}

const FilterWeek: FC<Props> = (props): JSX.Element => {
    const classes = useStyles();

    const [days, setDays] = useState < number > (7);

    const handleChange = (event: React.MouseEvent<HTMLButtonElement>) => {
        setDays(event.target.value);
    }

    useEffect(()=> {
        props.handleChangeWeek(days);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [days]);


    
    return (
        <Grid container>
            <Grid item xs={11}>
                <Select
                    className={classes.lastWeek}
                    onChange={handleChange}
                    value={days}
                >
                    <MenuItem value={7}>
                        <Typography align="right">LAST WEEK(LAST 7 DAYS)</Typography>
                    </MenuItem>
                    <MenuItem value={30}>
                        <Typography>LAST MONTH(LAST 30 DAYS)</Typography>
                    </MenuItem>
                    <MenuItem value={90}>
                        <Typography>LAST 3 MONTHS(LAST 90 DAYS)</Typography>
                    </MenuItem>
                    <MenuItem value={180}>
                        <Typography>LAST 6 MONTHS(LAST 180 DAYS)</Typography>
                    </MenuItem>
                    <MenuItem value={270}>
                        <Typography>LAST 9 MONTHS(LAST 270 DAYS)</Typography>
                    </MenuItem>
                    <MenuItem value={365}>
                        <Typography>LAST 12 MONTHS(LAST 365 DAYS)</Typography>
                    </MenuItem>
                </Select>
            </Grid>
            <Grid item xs={1}>
                <Link to={`/cliniciandashboard/patient/${props.dataPatient.id}/report/${days}`} target='_blank'>

                <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"

                >
                    <BiArrowToBottom />
                </IconButton>
                </Link>
            </Grid>
        </Grid>
    );
}

export default FilterWeek;