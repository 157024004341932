import React from 'react';
import { RouteComponentProps } from "react-router-dom";
import {
  CssBaseline,
  Container,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import LocalDrawer from '../../../Components/OrganizationManagerLocalDrawer';
import AppBar from '../../../Components/AppBar';
import Table from '../../../Components/Table';
import swal from 'sweetalert';

// CALL TO API
import { logout } from '../../../services/auth';
import { getAllCareProvidersWithoutObsolete } from '../../../services/organization';

interface Props extends RouteComponentProps {}

interface State {
  organizationId: string;
  isLoading: boolean;
  toolbarOpen: boolean;
  modalRemoveIsOpen: boolean;
  organization: any;
  careProvidersData: {
    id: string;
    name: string;
    phone: string;
    obsolete: string;
  }[];
}

class OrgManagerOrganization extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      organizationId: '',
      toolbarOpen: localStorage.getItem('toolbarOpen') === 'true' ? true : false,
      isLoading: false,
      modalRemoveIsOpen: false,
      organization: {},
      careProvidersData: [],
    };
  }

  componentDidMount() {
    this.getOrganization();
  }

  private getOrganization = async () => {
    try {
      const organization = await getAllCareProvidersWithoutObsolete();

      if (organization?.data?.organization) {
        const careProvidersData = organization?.data?.organization.Users.map((user) => {
          return {...user, href: `/organizationManager/${user.id}`, obsolete: user.obsolete ? 'No' : 'Yes'}
        });
        console.log('careProvidersData', careProvidersData);
        this.setState({ organization, careProvidersData });
      }
    } catch (error) {
      swal('Error', 'Error loading care providers', 'error');
    }
  }

  private handleDrawerOpen = () => {
    localStorage.setItem('toolbarOpen', 'true');
    this.setState({ toolbarOpen: true });
  }

  private handleDrawerClose = () => {
    localStorage.setItem('toolbarOpen', 'false');
    this.setState({ toolbarOpen: false });
  }

  render () {
    const {
      careProvidersData,
      organization,
    } = this.state;

    const columns = [
      {
        name: 'Name',
        key: 'name',
        type: 'link',
      },
      {
        name: 'Email',
        key: 'username',
        type: 'string',
      },
      {
        name: 'Phone',
        key: 'phone',
        type: 'string',
      },
      {
        name: 'Activated?',
        key: 'obsolete',
        type: 'boolean',
      },
    ];

    return (
      <div className='root'>
        <CssBaseline />
        <AppBar
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerOpen={this.handleDrawerOpen}
          breadcrumbs={[]}
        />
        <LocalDrawer
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerClose={this.handleDrawerClose}
          logout={logout}
        />
        <main className='content'>
          <div className='toolbarIcon' />
          <Container maxWidth="lg" className='container'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div style={{ float: 'left' }}>
                  <Typography variant="h4" display="block" gutterBottom>
                    {`Organization - ${organization?.data?.organization?.name}`}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className='paper' elevation={3}>
                  <Table
                    title='Care Providers'
                    columns={columns}
                    data={careProvidersData}
                    addHref={'/organizationManager/add'}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </main>
      </div>
    );
  }

}

export default OrgManagerOrganization;
