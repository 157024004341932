import { Box, Container, CssBaseline } from "@material-ui/core";
import React, { FC } from "react";

import Logo from "../../assets/images/logoTitleGreen.svg";

const DDLRedirect: FC = () => {
  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="md">
        <div>
          <img
            src={Logo}
            alt="Logo"
            className="logo-login"
            style={{ width: 300 }}
          />
        </div>
        <Box width="80%">
          <p>
            <b>Congratulations!</b>
          </p>
          <p>
            <b>You've been invited to join the QActual App.</b>
          </p>
          <p>
            If you recognize this link, in order to have the best experience
            possible, please, open the original link that you received from your
            mobile device.
          </p>
          <p>
            IF YOU HAVE THE QACTUAL 2.0 APP INSTALLED ON YOUR PHONE, PLEASE,
            CLOSE THE APP BEFORE CLICKING ON THIS LINK
          </p>
        </Box>
      </Container>
    </>
  );
};

export default DDLRedirect;
