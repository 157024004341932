import React, { FC, useState, useEffect } from 'react';
import { login, twofa, setToken, setRefreshToken } from '../../services/auth';
import { RouteComponentProps } from 'react-router';
import swal from 'sweetalert';

import {
  CssBaseline,
  Container,
  Grid,
  TextField,
  Button,
  Box,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../../assets/images/logoTitleGreen.svg';
import { Link, useLocation } from 'react-router-dom';
import * as SanitizeString from '../../services/sanitize_strings';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
    '&$focused': {
      backgroundColor: '#fff',
    },
    '& label.Mui-focused': {
      color: '#000',
      fontWeight: 'bold',
      fontSize: '150%',
      marginTop: -10,
    },
    '& .MuiInputLabel-asterisk': {
      color: '#9e3636',
    }, '& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled.Mui-disabled': {
      color: '#7f7f7f'
    }
  }
});

const Login: FC<RouteComponentProps> = (props) => {

  let { pathname } = useLocation();
  const [email, setEmail] = useState < string > ('');
  const [password, setPassword] = useState < string > ('');
  const [newPassword, setNewPassword] = useState < string > ('');
  const [confirmPassword, setConfirmPassword] = useState < string > ('');
  const [isNewPassword, setIsNewPassword] = useState < boolean > (false);
  const [isLoading, setIsLoading] = useState < boolean > (false);
  const [destination, setDestination] = useState < string > ('/');
  // twofa
  const [isSingIn, setIsSingIn] = useState < boolean > (false);
  const [isEnableCode, setIsEnableCode] = useState < boolean > (false);
  const [code, setCode] = useState < string > ('');

  useEffect(() => {
    switch (pathname) {
      case ('/qactual/admin/login'):
        setDestination('/qactual/admin');
        break;

      case ('/organizationManager/login'):
        setDestination('/organizationManager');
        break;

      case ('/cliniciandashboard/login'):
        setDestination('/cliniciandashboard');
        break;

      default:
        break;
    }
  },[pathname]);

  const resetDataForm = () => {
    setCode('');
    setPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setIsSingIn(false);
    setIsEnableCode(false);
    setIsNewPassword(false);
    setIsSingIn(false);
  }

  const handleTwoFa = async () => {

    setIsLoading(true);

    const resp = await twofa(email);

    if (resp?.data?.status === 'success') {
      setIsSingIn(true);
      setIsEnableCode(true);
      setIsLoading(false);
      swal("OK", "A code has been sent to your email.", "success");
    } else {
      swal("OPS...", "Login failed with your username.", "error");
    }
  }

  const handleLogin = async (event: any) => {
    const form = event.target;

    setIsLoading(true);

    let fields = { email: email, password: password, code: code };

    if (isNewPassword) {
      fields = Object.assign(fields, { newPassword: newPassword });
    }

    if (isNewPassword && !comparePassword()) {
      swal('Important', 'Passwords are not the same!', 'warning');
      setIsLoading(false);
      form.reset();
    } else {
      const resp = await login(fields);
      if (resp?.data?.token) {
        setToken(resp?.data?.token);
        setRefreshToken(resp?.data?.refreshToken)
        setIsLoading(false);
        props.history.push(destination);
      } else {
        if (resp?.data?.err?.code === "PasswordResetRequiredException" || resp?.data?.err === "new_password_required") {
          swal("Important", "First access. You must change your password.", "info");
          setIsLoading(false);
          setIsNewPassword(true);
        } else {
          swal("ERROR", resp?.data?.err?.message  || '', "error");
          setIsLoading(false);
          resetDataForm();
        }
      }
    }
  }

  const handleSignIn = async (event: any) => {
    event.preventDefault();
    if (isSingIn) {
      handleLogin(event);
    } else {
      handleTwoFa();
    }
  }

  const comparePassword = () => newPassword === confirmPassword;

  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <div>
          <img src={Logo} alt="Logo" className="logo-login" />
          <form onSubmit={handleSignIn} id="form-login">
            <Box mt={1}>
              <TextField
                className={classes.root}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                onChange={(e) => setEmail(SanitizeString.email(e.target.value))}
                value={email}
                disabled={isNewPassword || isEnableCode}
                autoComplete="email"
                autoFocus
              />
            </Box>

            {(!isNewPassword && !isEnableCode) &&
              <Box mt={1}>
                <TextField
                  className={classes.root}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  disabled={isEnableCode}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="current-password"
                />
              </Box>
            }
            {isNewPassword &&
              <Box mt={1}>
                <TextField
                  className={classes.root}
                  variant="outlined"
                  margin="normal"
                  disabled
                  fullWidth
                  name="PasswordOld"
                  label="Old Password"
                  type="password"
                  id="passwordOld"
                  value="********"
                />

                <TextField
                  className={classes.root}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="newPassword"
                  label="New Password"
                  type="password"
                  id="newPassword"
                  onChange={(e) => setNewPassword(e.target.value)}
                  autoComplete="current-new-password"
                />
                <TextField
                  className={classes.root}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  id="confirmPassword"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  autoComplete="current-confirm-password"
                />
              </Box>
            }

            {/* TWOFA - Input Code */}
            {isEnableCode &&
              <Box mt={1}>
                <TextField
                  className={classes.root}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="code"
                  label="Code"
                  name="code"
                  onChange={(e) => setCode(SanitizeString.phoneNumber(e.target.value))}
                  value={code}
                  helperText="Tell us the code you received"
                  autoFocus
                />
              </Box>
            }

            <Box mt={1}>
              <Grid container justify="flex-end">
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                    size="large"
                    startIcon={isLoading && <CircularProgress size={24} />}
                  >
                    {isEnableCode ? `Validate access code` : `Login` }
                  </Button>
                </Grid>
              </Grid>
              {/* Resend code */}
              <Grid container justify="flex-start">
                <Grid item>
                  {isEnableCode ? (
                    <Button onClick={() => handleTwoFa()} color="primary">
                      Resend code
                    </Button>
                  ) : (
                    <Link to={`${destination}/forgotPassword`}>
                      <Typography>I forgot my password</Typography>
                    </Link>
                  )}
                </Grid>
              </Grid>
            </Box>

          </form>
        </div>
      </Container>
    </>
  );
};

export default Login;
