import React from 'react';
import { Modal, Button } from '@material-ui/core';

interface Props {
  modalRemoveIsOpen: boolean;
  handleModalRemoveClose: Function;
  dismissRemove: Function;
  confirmRemove: Function;
}

class RemoveModal extends React.Component<Props> {
  render () {
    const {
      modalRemoveIsOpen,
      handleModalRemoveClose,
      dismissRemove,
      confirmRemove,
    } = this.props;

    let modalLeft = `${(window.innerWidth/2)-200}px`;
    let lodalTop = `${(window.innerHeight/2)}px`;

    return (
      <Modal
        aria-labelledby='modal-remove-title'
        aria-describedby='modal-remove-description'
        open={modalRemoveIsOpen}
        onClose={() => handleModalRemoveClose()}
        style={{
          position: 'absolute',
        }}
      >
        <div
          style={{
            width: '400px',
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px',
            position: 'fixed',
            left: modalLeft,
            top: lodalTop,
          }}>
          <h2 id='modal-remove-title'>Confirm Remove Data</h2>
          <p id='modal-remove-description'>
            Please, confirm that you want to remove this data.
          </p>
          <Button
            style={{ float: 'right' }}
            color='primary'
            onClick={() => dismissRemove()}
          >
            Cancel
          </Button>
          <Button
            style={{ float: 'right' }}
            color='secondary'
            onClick={() => confirmRemove()}
          >
            Remove
          </Button>
        </div>
      </Modal>
    );
  }
}

export default RemoveModal;
