import axios from "axios";

let baseURL = "";

if (process.env.NODE_ENV === "development") {
  baseURL = "http://localhost:4001";
} else {
  const currentURL = window.location.href;
  if (currentURL.includes("staging--qactual-clinician-dashboard.netlify.app")) {
    baseURL = "https://qactual-mobile-global-api-staging.qactualservice.com";
  } else {
    baseURL = "https://qactual-mobile-global-api.qactualservice.com";
  }
}

// Create AXIOS API
const API = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.YbJMet3GTNlHUFdV8inA0RKbAplRNP3skGfYyu0SUJE",
  },
});
export default API;
