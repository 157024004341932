import React, { useState, useEffect} from 'react';
import {
  Container,
  Grid,
  CircularProgress,
  Card,
  CardContent,
  CssBaseline,
  Typography,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import Logo from "../../../assets/images/logoTitleGreen.svg";
import AppBar from '../../../Components/AppBar';
import LocalDrawer from '../../../Components/QActualAdminLocalDrawer';
import swal from 'sweetalert';

// CALL TO API
import { getClinician, getStatusPatients, getAverageTimeToRespond } from '../../../services/careProvider';
import { logout } from '../../../services/auth';
import { getWellnesschecks, getPHQ29s } from '../../../services/careProvider';

import WellnessCheckChart from '../../Patient/Components/WellnessCheckChart';
import PHQ29Chart from '../../Patient/Components/PHQ29Chart';
import FilterChartsReport from './Components/FilterChartsReport';

interface IClinician {
  id: string;
  name: string;
  phone: string;
  username: string;
}

const Clinician = (): JSX.Element => {
  const [toolbarOpen, setToolbarOpen] = useState < boolean > (localStorage.getItem('toolbarOpen') === 'true' ? true : false);
  const [loading, setLoading] = useState < boolean > (false);
  const [loadingAverageTime, setLoadingAverageTime] = useState < boolean > (false);
  const [clinicianData, setClinicianData] = useState < IClinician > ();
  const [statusData, setStatusData] = useState();
  const [days, setDays] = useState < number > (7);
  const [phq29s, setPhq29s] = useState([]);
  const [wellnesschecks, setWellnesschecks] = useState([]);
  const [averageTimeRespond, setAverageTimeRespond] = useState<number>();
  const [loadWellnessChats, setLoadWellnessChats] = useState<boolean>(false);
  const [loadPHQ29Chats, setLoadPHQ29Chats] = useState<boolean>(false);
  const { userId } = useParams();

  useEffect(() => {
    if (clinicianData?.cognitoId) {
      handleChartsData(days, clinicianData.cognitoId);
    }
  }, [days, clinicianData]);

  useEffect(() => {
    if (userId) {
      getAverageTimeRespond(userId, days);
    }
  }, [userId, days]);

  useEffect(() => {
    if (userId) {
      getClinicianResponse(userId);
      getStatusResponse(userId);
    }
  }, [userId]);


  const handleChartsData = async (period: number, cognitoId: string ) => {
    setLoadWellnessChats(true);
    setLoadPHQ29Chats(true);

    try {
      const resp = await getWellnesschecks(period, cognitoId);
      if (resp?.status === 200) {
        setWellnesschecks(resp?.data?.wellnesschecks);
        setLoadWellnessChats(false);
      }

      const respPHQ29s = await getPHQ29s(period, cognitoId);
      if (respPHQ29s?.status === 200) {
        setPhq29s(respPHQ29s?.data?.phq29s);
        setLoadPHQ29Chats(false);
      }
    } catch (error) {
      swal('Error', 'Something went wrong while loading reports', 'error');
      setLoadWellnessChats(false);
      setLoadPHQ29Chats(false);
    }
  }

  const handleDrawerOpen = () => {
    console.log('Opening toolbar');
    localStorage.setItem('toolbarOpen', 'true');
    setToolbarOpen(true);
  };

  const handleDrawerClose = () => {
    console.log('Closing toolbar');
    localStorage.setItem('toolbarOpen', 'false');
    setToolbarOpen(false);
  };

  const getClinicianResponse = async (userId:string) => {
    setLoading(true);
    try {
      const response = await getClinician(userId);
      if (response?.data?.user) {
        setClinicianData(response?.data?.user);
      } else {
        setClinicianData([]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      swal('Error', 'Error loading clinician', 'error');
    }
  };

  const getAverageTimeRespond = async (userId: string, days: number) => {
    setLoadingAverageTime(true);
    try {
      const {data} = await getAverageTimeToRespond(userId, days);
      if (data?.responseTime) {
        setAverageTimeRespond(data?.responseTime);
      } else {
        setAverageTimeRespond(0);
      }

      setLoadingAverageTime(false);
    } catch (error) {
      swal('Error', 'Error loading average time response', 'error');
      setLoadingAverageTime(false);
    }
  }

  const getStatusResponse = async (userId: string) => {
    setLoading(true);
    try {
      const response = await getStatusPatients(userId);
      if (response?.data?.status_patients) {
        setStatusData(response?.data?.status_patients);
      } else {
        setStatusData(undefined);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      swal('Error', 'Error loading status', 'error');
    }
  };

  return (
    <div className='root'>
      <CssBaseline />
      <AppBar
        toolbarOpen={toolbarOpen}
        handleDrawerOpen={handleDrawerOpen}
        breadcrumbs={[]}
      />
      <LocalDrawer
        toolbarOpen={toolbarOpen}
        handleDrawerClose={handleDrawerClose}
        logout={logout}
      />
      <main className='content'>
        <div className='toolbarIcon' />
        <Container className="main-container">
          <Grid container justify="space-between">
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <a href="/qactual/admin/clinician" title="Go to home">
                <img src={Logo} alt="Logo" style={{ width: "90%" }} />
              </a>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} >
              <Grid container spacing={3} direction="column" >
                <Grid item>
                  <Card className="box-info-user">

                    <CardContent>
                      {loading ? (<CircularProgress  size={25} />): (<>
                        <Typography style={{textAlign: 'center', fontWeight: 600}}>
                          {clinicianData?.name}
                        </Typography>
                        <Typography style={{ fontWeight: 600 }}  component="span">
                          ID: #
                        </Typography>
                        <Typography component="span">
                          {clinicianData?.id}
                        </Typography>
                      </>)}
                    </CardContent>

                  </Card>
                </Grid>
                <Grid item>
                  <Card className="box-info-user">

                    <CardContent>
                      {loading ? (<CircularProgress size={25} />) : (<>
                        <Typography style={{ textAlign: 'center', fontWeight: 600, color: '#000000' }}>
                          STATUS
                        </Typography>
                        <Typography style={{ color: '#BBBABA', fontSize: 14 }}>
                          PATIENTS {statusData?.totalPatients}
                        </Typography>
                        <Typography style={{ color: '#BBBABA', fontSize: 14}}>
                          AVERAGE TIME TO RESPOND {loadingAverageTime ? (<CircularProgress size={10} />) : (averageTimeRespond)} 
                        </Typography>
                        <Typography style={{ color: '#BBBABA', fontSize: 14 }}>
                          YELLOW ALERT {statusData?.totalYellowEvent}
                        </Typography>
                        <Typography style={{ color: '#A83333', fontSize: 14 }}>
                          RED ALERT {statusData?.totalRedAlert}
                        </Typography>
                        <Typography style={{ color: '#FFFFFF', fontSize: 14 }}>
                          WHITE ALERT {statusData?.totalWhiteAlert}
                        </Typography>
                        <Typography style={{ color: '#000000', fontSize: 14 }}>
                          BLACK ESCALATIONS {statusData?.totalBlackEvent}
                        </Typography>
                      </>)}
                    </CardContent>

                  </Card>
                </Grid>
                <Grid item>
                  <FilterChartsReport
                    days={days}
                    setDays={setDays}
                    loadWellnessChats={loadWellnessChats}
                    loadPHQ29Chats={loadPHQ29Chats}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {loadWellnessChats && loadPHQ29Chats ? (<CircularProgress />) : (<Grid container direction="column">
                <Grid item style={{ textAlign: "center" }}>
                  <h1>WELLNESS CHECK</h1>
                  <WellnessCheckChart wellnesschecks={wellnesschecks}/>
                </Grid>
                <Grid item style={{ textAlign: "center" }}>
                  <h1>PHQ 2-9 QUESTIONS</h1>
                  <PHQ29Chart phq29s={phq29s} />
                </Grid>
              </Grid>)}

            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
};

export default Clinician;