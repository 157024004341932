import React from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  CssBaseline,
  Container,
  Grid,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import LocalDrawer from "../../../Components/QActualAdminLocalDrawer";
import AppBar from "../../../Components/AppBar";

import { Map, TileLayer } from "react-leaflet";

import HeatmapLayer from "../../../../node_modules/react-leaflet-heatmap-layer/lib/HeatmapLayer";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { Bar } from "react-chartjs-2";

// CALL TO API
import { logout } from "../../../services/auth";

import "leaflet/dist/leaflet.css";
import API from "../../../services/api";
import format from "date-fns/format";
import LanguagesChart from "./components/LanguagesChart";
import CountriesChart from "./components/CountriesChart";
import DailySessionUserChart from "./components/DailySessionUserChart";
import ActiveUserVersionChart from "./components/ActiveUserVersionChart";
import ActiveUsersChart from "./components/ActiveUsersChart";
import CountriesChartMap from "./components/CountriesChartMap";

interface Props extends RouteComponentProps {}

interface State {
  isLoading: boolean;
  toolbarOpen: boolean;
  heatMapData: [number, number, number][];
  initDate: Date;
  finalDate?: Date;
  histogramEventsData?: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string[];
      borderColor: string[];
      borderWidth: number;
    }[];
  };
  selectedSystem: "all" | "android" | "iOS";
}

class EditResource extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    const initialDate = new Date();
    initialDate.setDate(initialDate.getDate() - 90);

    this.state = {
      toolbarOpen:
        localStorage.getItem("toolbarOpen") === "true" ? true : false,
      isLoading: false,
      heatMapData: [],
      initDate: initialDate,
      finalDate: null,
    };
  }

  componentDidMount() {
    this.processHeatMapData();
  }

  private processHeatMapData = async () => {
    const { initDate, finalDate } = this.state;

    this.setState({ isLoading: true });

    const response = await API.post("/events/heatMap", {
      sinceTime: initDate,
      endTime: finalDate,
    });

    const heatMapData = response.data.map((obj) => {
      return [
        obj.latitude,
        obj.longitude,
        obj.type === "redAlert" ? 3000 : 1000,
      ];
    });

    const histogramEventsLabels: string[] = [];

    const currentDate = new Date(initDate);
    currentDate.setHours(0);
    currentDate.setMinutes(0);
    currentDate.setSeconds(0);
    currentDate.setMilliseconds(0);

    const finalDate_ = finalDate ? new Date(finalDate) : new Date();
    finalDate_.setHours(0);
    finalDate_.setMinutes(0);
    finalDate_.setSeconds(0);
    finalDate_.setMilliseconds(0);

    const whiteResults: number[] = [];
    const redResults: number[] = [];

    while (currentDate.getTime() <= finalDate_.getTime()) {
      // Add the date to the label
      histogramEventsLabels.push(format(currentDate, "MM-dd-yyyy"));

      // Process white and red alerts
      let whiteCounter = 0;
      let redCounter = 0;
      response.data.forEach((obj) => {
        const eventDate = new Date(obj.created_at);
        eventDate.setHours(0);
        eventDate.setMinutes(0);
        eventDate.setSeconds(0);
        eventDate.setMilliseconds(0);

        if (eventDate.getTime() === currentDate.getTime()) {
          if (obj.type === "whiteAlert") {
            whiteCounter += 1;
            // break;
          } else if (obj.type === "redAlert") {
            redCounter += 1;
            // break;
          }
        }
      });

      whiteResults.push(whiteCounter);
      redResults.push(redCounter);

      currentDate.setDate(currentDate.getDate() + 1);
    }

    this.setState({
      heatMapData,
      histogramEventsData: {
        labels: histogramEventsLabels,
        datasets: [
          {
            label: "White Alerts",
            data: whiteResults,
            backgroundColor: ["rgba(224, 147, 0, 0.2)"],
            borderColor: ["rgba(224, 147, 0, 1)"],
            borderWidth: 1,
          },
          {
            label: "Red Alerts",
            data: redResults,
            backgroundColor: ["rgba(255, 99, 132, 0.2)"],
            borderColor: ["rgba(255, 99, 132, 1)"],
            borderWidth: 1,
          },
        ],
      },
      isLoading: false,
      selectedSystem: "all",
    });
  };

  private handleDrawerOpen = () => {
    localStorage.setItem("toolbarOpen", "true");
    this.setState({ toolbarOpen: true });
  };

  private handleDrawerClose = () => {
    localStorage.setItem("toolbarOpen", "false");
    this.setState({ toolbarOpen: false });
  };
  private handleSelectSystem = (e) => {
    this.setState({ selectedSystem: e.target.value });
  };

  render() {
    const {
      isLoading,
      heatMapData,
      initDate,
      finalDate,
      histogramEventsData,
      selectedSystem,
    } = this.state;

    return (
      <div className="root">
        <CssBaseline />
        <AppBar
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerOpen={this.handleDrawerOpen}
          breadcrumbs={[]}
        />
        <LocalDrawer
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerClose={this.handleDrawerClose}
          logout={logout}
        />
        <main className="content">
          <div className="toolbarIcon" />
          <Container maxWidth="lg" className="container">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div style={{ float: "left" }}>
                  <Typography variant="h4" display="block" gutterBottom>
                    Data Analysis
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Paper className="paper" style={{ height: "100%" }}>
              <h1>Heat Map (alerts events)</h1>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div style={{ paddingBottom: 10 }}>
                  <DatePicker
                    label="Initial Date"
                    value={initDate}
                    onChange={(date: Date) => {
                      this.setState({ initDate: date }, () => {
                        this.processHeatMapData();
                      });
                    }}
                    animateYearScrolling
                    format="MM/dd/yyyy"
                    style={{ paddingRight: 10 }}
                  />
                  <DatePicker
                    label="Final Date"
                    value={finalDate}
                    onChange={(date: Date) =>
                      this.setState({ finalDate: date }, () => {
                        this.processHeatMapData();
                      })
                    }
                    animateYearScrolling
                    format="MM/dd/yyyy"
                  />
                </div>
              </MuiPickersUtilsProvider>
              <Map center={[0, 0]} zoom={2} scrollWheelZoom={false}>
                <HeatmapLayer
                  fitBoundsOnLoad
                  fitBoundsOnUpdate
                  points={heatMapData}
                  longitudeExtractor={(m) => m[1]}
                  latitudeExtractor={(m) => m[0]}
                  intensityExtractor={(m) => parseFloat(m[2])}
                />
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
              </Map>
              <Bar
                data={histogramEventsData}
                height={100}
                options={{
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                          min: 0,
                        },
                      },
                    ],
                  },
                }}
                style={{
                  paddingTop: 20,
                }}
              />
            </Paper>
            <Paper style={{ padding: 20 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <h1>Apps Analytics</h1>
                  <FormControl fullWidth>
                    <InputLabel id="select-system-label">
                      Select System
                    </InputLabel>
                    <Select
                      labelId="select-system-label"
                      id="system-select"
                      value={selectedSystem}
                      onChange={this.handleSelectSystem}
                    >
                      <MenuItem value="all" selected>
                        All
                      </MenuItem>
                      <MenuItem value="android">Android</MenuItem>
                      <MenuItem value="iOS">IOS</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={7}>
                  <CountriesChartMap
                    dataStart={initDate}
                    dataEnd={finalDate}
                    top={100}
                    system={selectedSystem}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={5}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <CountriesChart
                    dataStart={initDate}
                    dataEnd={finalDate}
                    top={100}
                    system={selectedSystem}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ActiveUserVersionChart
                    dataStart={initDate}
                    dataEnd={finalDate}
                    system={selectedSystem}
                    top={100}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <LanguagesChart
                    dataStart={initDate}
                    dataEnd={finalDate}
                    system={selectedSystem}
                    top={100}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <DailySessionUserChart
                    dataStart={initDate}
                    dataEnd={finalDate}
                    system={selectedSystem}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <ActiveUsersChart
                    dataStart={initDate}
                    dataEnd={finalDate}
                    system={selectedSystem}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </main>
      </div>
    );
  }
}

export default EditResource;
