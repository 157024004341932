import React from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  CssBaseline,
  Container,
  Grid,
  TextField,
  Typography,
  Button,
  CircularProgress,
  ButtonGroup,
  Paper,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import LocalDrawer from "../../../Components/QActualAdminLocalDrawer";
import AppBar from "../../../Components/AppBar";
import { Formik } from "formik";

import * as yup from "yup";
import swal from "sweetalert";
import SaveIcon from "@material-ui/icons/Save";

// CALL TO API
import * as FormApi from '../../../services/mobile/wellnessForm';
import { logout } from "../../../services/auth";


interface Props extends RouteComponentProps {}

interface State {
  isLoading: boolean;
  toolbarOpen: boolean;
  organizationId: string;
  modalRemoveIsOpen: boolean;
  formId: string;
  form: {
    id: string;
    formName: string;
    active: string;
    deletedAt: string;
    createdAt: string;
  }
}

class EditForm extends React.Component<Props, State> {
  private fileSelector: HTMLInputElement;

  constructor(props) {
    super(props);

    this.state = {
      toolbarOpen:
        localStorage.getItem("toolbarOpen") === "true" ? true : false,
      organizationId: props.match.params.organizationId,
      formId: props.match.params.formId,
      isLoading: false,
      modalRemoveIsOpen: false,
      form: {
        id: '',
        formName: '',
        active: '',
        deletedAt: '',
        createdAt: '',      
      },
    };
  }

  componentDidMount() {
    this.getForm();
  }

  private getForm = async () => {
    const { formId } = this.state;

    const response: any = await FormApi.getFormById(formId);
    
    this.setState({ form: response.data })
  };

  private handleDrawerOpen = () => {
    localStorage.setItem("toolbarOpen", "true");
    this.setState({ toolbarOpen: true });
  };

  private handleDrawerClose = () => {
    localStorage.setItem("toolbarOpen", "false");
    this.setState({ toolbarOpen: false });
  };

  handleSubmit = async (params: any) => {
    try {
      this.setState({ isLoading: true });

      const { formId, organizationId } = this.state;

      const payload = {
        formName: params.formName      
      };

      const response = await FormApi.updateForm(formId, payload);

      if (response.status === 200) {
        this.setState({ isLoading: false });
        swal("Success", "Record updated", "success");
        this.props.history.push(
          `/qactual/admin/organizations/${organizationId}/form/${formId}`
        );
      }
    } catch (error) {
      this.setState({ isLoading: false });
      swal("Error", "Something went wrong", "error");
    }
  };

  private handleBackClick = () => {
    const { organizationId, formId } = this.state;

    this.props.history.push(
      `/qactual/admin/organizations/${organizationId}/form/${formId}`
    );
  };

  render() {
    const { isLoading, form } = this.state;

    // console.log('resourceData:', resourceData);

    return (
      <div className="root">
        <CssBaseline />
        <AppBar
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerOpen={this.handleDrawerOpen}
          breadcrumbs={[]}
        />
        <LocalDrawer
          toolbarOpen={this.state.toolbarOpen}
          handleDrawerClose={this.handleDrawerClose}
          logout={logout}
        />
        <main className="content">
          <div className="toolbarIcon" />
          <Container maxWidth="lg" className="container">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div style={{ float: "left" }}>
                  <Typography variant="h4" display="block" gutterBottom>
                    Edit Form
                  </Typography>
                </div>
                <ButtonGroup
                  color="primary"
                  aria-label="outlined primary button group"
                  style={{ float: "right" }}
                >
                  <Button onClick={() => this.handleBackClick()}>
                    <ArrowBack fontSize="small" />
                    &nbsp;Back
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
            <Paper className="paper" style={{ paddingTop: 40 }}>
              <Formik
                enableReinitialize
                initialValues={{ ...form }}
                validationSchema={yup.object().shape({
                  formName: yup
                    .string()
                    .required("You must provide a name to your form"),                          
                })}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  this.handleSubmit({
                    formName: values.formName,                   
                  });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          id="formName"
                          label="Name"
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.formName}
                          fullWidth
                          error={errors.formName && touched.formName}
                          helperText={touched.formName && errors.formName}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          style={{ float: "right" }}
                          disabled={isSubmitting}
                          startIcon={
                            isLoading ? (
                              <CircularProgress size={24} />
                            ) : (
                              <SaveIcon />
                            )
                          }
                          size="large"
                        >
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </Paper>
          </Container>
        </main>
      </div>
    );
  }
}

export default EditForm;
