import React from 'react';
import {
  Breadcrumbs,
  Link,
  Typography,
} from '@material-ui/core';

interface Props {
  itens: {
    name: string;
    href: string;
  }[];
}

class Breadcrumbs_ extends React.Component<Props> {
  render () {
    return (
      <Breadcrumbs
        aria-label="breadcrumb"
      >
        {this.props.itens.map((item, idx): JSX.Element => {
          if ( item.name ) {
            if ( idx === (this.props.itens.length-1) ) { // Is last item
              return (
                <Typography
                  key={idx}
                  color="textPrimary"
                >
                  {item.name}
                </Typography>
              );
            } else {
              return (
                <Link
                  key={idx}
                  color="inherit"
                  href={item.href}
                >
                  {item.name}
                </Link>
              );
            }
          }
          return  <></>
        })}
      </Breadcrumbs>
    );
  }
}

export default Breadcrumbs_;
